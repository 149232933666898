
<button
  mat-icon-button
  matTooltip="App Menu"
  [matMenuTriggerFor]="appMenu">
  <mat-icon>menu</mat-icon>
</button>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>

    <a *ngIf="store" mat-menu-item matTooltip="About DAB Fare, LLC" [routerLink]="(path | async) ? ['/store', (path | async), 'about'] : ['/about']">
      <mat-icon>description</mat-icon>
      About
    </a>
  
    <a *ngIf="store" mat-menu-item matTooltip="Contact Seller" [routerLink]="(path | async) ? ['/store', (path | async), 'contact'] : ['/contact']">
      <mat-icon>message</mat-icon>      
      Contact
    </a>
  
    <a *ngIf="store && store.blog" mat-menu-item matTooltip="Blog" [routerLink]="(path | async) ? ['/store', (path | async), 'blog'] : ['/blog']">
      <mat-icon>speaker_notes</mat-icon>
      Blog
    </a>
    <a *ngIf="store && store.portfolio" mat-menu-item matTooltip="Portfolio" [routerLink]="(path | async) ? ['/store', (path | async), 'portfolio'] : ['/portfolio']">
      <mat-icon>photo_library</mat-icon>
      Portfolio
    </a>
    <a *ngIf="store && store.product" mat-menu-item matTooltip="All Products" [routerLink]="(path | async) ? ['/store', (path | async), 'products'] : ['/products']">
      <mat-icon>store</mat-icon>
      Products
    </a>
    <a *ngIf="store && store.service" mat-menu-item matTooltip="All Services" [routerLink]="(path | async) ? ['/store', (path | async), 'services'] : ['/services']">
      <mat-icon>work</mat-icon>
      Services
    </a>

  </ng-template>
</mat-menu>