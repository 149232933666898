import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { StoreRefundService } from 'shared/services/store-refund.service';
import 'rxjs/add/operator/take';

@Component({
  selector: 'admin-refund',
  templateUrl: './admin-refund.component.html',
  styleUrls: ['./admin-refund.component.css']
})
export class AdminRefundComponent implements OnInit {

  refund: any = {};
  id: string;
  refundAmount: number;

  constructor(
    private route: ActivatedRoute,
    private service: StoreRefundService) { }

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        this.refund = res;
        this.calculate();
      });
  }

  private calculate() {
    let items = this.refund.items;
    this.refundAmount = 0;
    for (let i=0; i<items.length; i++) {
      let item = items[i];
      let amount = item.item.returnQuantity * item.item.product.price;
      this.refundAmount += amount;
      console.log('refundAmount', this.refundAmount);
    }
  }

  issueRefund() {
    let resource = {
//      storeOrderId: this.refund.storeOrderId,
      status: 'Refund',
      amount: this.refundAmount
    }
    this.service.patch(this.id, resource);
  }
}
