import { Component, Input } from '@angular/core';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent {

  @Input('user') user: User;

  constructor(private auth: AuthService) {}

  logout() {
    this.auth.logOut();
    this.user = <User>{};
  }
}
