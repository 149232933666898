import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreRefundService } from 'shared/services/store-refund.service';

@Component({
  selector: 'supreme-store-refund',
  templateUrl: './supreme-store-refund.component.html',
  styleUrls: ['./supreme-store-refund.component.css']
})
export class SupremeStoreRefundComponent implements OnInit {

  refund: any = {};
  id: string;

  constructor(
    private route: ActivatedRoute,
    private service: StoreRefundService) { }

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        this.refund = res;
      });
  }
}
