import { Component, OnInit } from '@angular/core';
import { Theme } from 'shared/models/theme';

@Component({
  selector: 'admin-theme',
  templateUrl: './admin-theme.component.html',
  styleUrls: ['./admin-theme.component.css']
})
export class AdminThemeComponent implements OnInit {

  theme: Theme = <Theme>{};

  constructor() { }

  ngOnInit() {
  }

}
