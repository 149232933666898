
<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="10px">

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="40px"
    fxLayoutGap.xs="20px">

    <div
      fxFlex="40"
      fxLayoutAlign="left center">
    
      <user-form [user]="user"></user-form>
          
    </div>
    <div
      fxFlex="40"
      fxLayoutAlign="right center">

      <user-card [user]="user"></user-card>
    </div>
  </div>


  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="40px"
    fxLayoutGap.xs="20px">


    <div fxFlex="none">
      
    </div>

  </div>
</div>

