
<store-nav></store-nav>

<portfolio-filter></portfolio-filter>

<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  @titleAnimation
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <h1 fxFlex="nogrow">Portfolio</h1>
</div>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">  

  <div
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let portfolio of filteredPortfolios">

    <portfolio-card [portfolio]="portfolio"></portfolio-card>

  </div>
</div>

