import { Component, Input } from '@angular/core';
import { ShoppingCart } from 'shared/models/shopping-cart';
import { select } from '@angular-redux/store';

@Component({
  selector: 'order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent {
  @Input('cart-items-count') cartItemsCount: number;
  @Input('shopping-cart') cart: ShoppingCart;  
  @Input('amount') amount: number;
  
  @select() storeCarts;

}
