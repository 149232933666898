
<a mat-raised-button matTooltip="Return" aria-label="Back arrow icon link" [routerLink]="['/admin/plans']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  
  <div
    fxFlex="40"
    fxLayoutAlign="left center">
    
    <plan-form [plan]="plan"></plan-form>
  </div>

  
  <div
    fxFlex="40"
    fxLayoutAlign="right center">

    <plan-card [plan]="plan" [show-plan-actions]="false"></plan-card>
  </div>
</div>