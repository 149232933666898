
<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async), 'blog'] : ['/blog']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px"
  *ngIf="blog">

  <div @titleAnimation fxFlex="nogrow">
    <h1>Blog Post</h1>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="blog">
  
    <div
      fxFlex.xl="58"
      fxFlex.lg="68"
      fxFlex.md="78"
      fxFlex.sm="88"
      fxFlex.xs="98"
      *ngIf="blog.imageUrl">

      <image-card [image]="blog.imageUrl"></image-card>
    </div>
  </div>

  <div @slide fxFlex="nogrow">

    <h1 *ngIf="blog.title">{{ blog.title }}</h1>
  </div>
  <div @slide fxFlex="nogrow">

    <h3 *ngIf="blog.subtitle">{{ blog.subtitle }}</h3>

  </div>
  <div @slide fxFlex="nogrow">

    <p>{{ blog.abstract }}</p>
  </div>
  <div @slide fxFlex="nogrow">

    <p *ngIf="blog.entry">
      {{ blog.entry }}
    </p>
  </div>


  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="blog.images">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let image of blog.images">

      <image-card [image]="image"></image-card>

    </div>
  </div>

</div>