import 'rxjs/add/operator/take';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Portfolio } from 'shared/models/portfolio';
import { PortfolioService } from 'shared/services/portfolio.service';
import { titleAnimation, slide } from 'app/animations';

@Component({
  selector: 'admin-portfolio',
  templateUrl: './admin-portfolio.component.html',
  styleUrls: ['./admin-portfolio.component.css'],
  animations: [ titleAnimation, slide ]
})

export class AdminPortfolioComponent implements OnInit {
  portfolio: Portfolio = <Portfolio>{};
  id: string;
  
  constructor(
    private route: ActivatedRoute,    
    private service: PortfolioService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( p => this.portfolio = p);
  }
}
