import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';

@Injectable()
export class CategoryService {

  servicePath: string = 'categories';

  constructor(private feathers: Feathers) {}


  private getQuery(storeId) {

    let query = {};
  
    if(storeId == '') {
      query = {
        query: {
          $sort: { createdAt: -1 }
        }
      }
    }
    else {
      query = {
        query: {
          storeId: storeId,
          $sort: { text: 1 }
        }
      }
    }
    return query;
  }

  // find resources for my store
  findByMyStore(storeId: string) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $sort: { text: 1 }
        }
    });
  }

  
  find(storeId: string) {

    let query = this.getQuery(storeId);

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }
  
  create(storeId: string, text: string) {

    let resource = {};
    resource['storeId'] = storeId
    resource['text'] = text;

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
  
  remove(resourceId: string) {
  
    return this.feathers
      .service(this.servicePath)
      .remove({
        _id: resourceId
      });
  }
}
