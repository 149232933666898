<div
  fxFlex="100"
  *ngIf="selectedRefunds">
     

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="40px"
    fxLayoutGap.xs="20px"
    *ngFor="let refundItem of selectedRefunds">

    <div
      fxFlex="40"
      fxLayoutAlign="left center">

      <product-card [product]="refundItem.product" [show-actions]="false"></product-card>
    </div>

    <div
      fxFlex="40"
      fxLayoutAlign="right center">

      <refund-item-form [refund-item]="refundItem"></refund-item-form>
    </div>

  </div>
    <button mat-raised-button color="primary" type="button" (click)="submitRefundRequest()">Submit Refund Request</button>
</div>