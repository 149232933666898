import { Component, Input } from '@angular/core';
import { User } from 'shared/models/user';

@Component({
  selector: 'supreme-menu',
  templateUrl: './supreme-menu.component.html',
  styleUrls: ['./supreme-menu.component.css']
})
export class SupremeMenuComponent {

  @Input('user') user: User;

}
