import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreOrder } from 'shared/models/store-order';
import { StoreOrderService } from 'shared/services/store-order.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'admin-order',
  templateUrl: './admin-order.component.html',
  styleUrls: ['./admin-order.component.css']
})

export class AdminOrderComponent implements OnInit {
  order: StoreOrder = <StoreOrder>{};
  id: string;

  carriers: string[] = [ 'DHL', 'FedEx', 'UPS', 'USPS']

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private service: StoreOrderService) { }

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    this.subscription = (await this.service.get(this.id))
      .subscribe( res => {
        this.order = res;
    });
  }

  orderShipping(resource) {
    let shipment = {
      status: 'Shipped',
      carrier: resource.carrier,
      tracking: resource.tracking
    }
    console.log('shipment details', shipment);
    this.service.patch(this.order._id, shipment);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
