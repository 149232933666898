
<a mat-raised-button matTooltip="Return" [routerLink]="['/my/sources']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px"
  *ngIf="source && id">

  <div fxFlex="40">

    <source-card [source]="source"></source-card>
  </div>
</div>
