import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { Portfolio } from 'shared/models/portfolio';

@Injectable()
export class PortfolioService {
  
  servicePath: string = 'portfolios';

  constructor(private feathers: Feathers) {}

  private getQuery(storeId) {

    let query = {};
  
    if(storeId == '') {
      query = {
        query: {
          $sort: { createdAt: -1 }
        }
      }
    }
    else {
      query = {
        query: {
          storeId: storeId,
          $sort: { createdAt: -1 }
        }
      }
    }
    return query;
  }

  // find resources for my store
  findByMyStore(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
    });
  }


  findNum(storeId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $limit: 0
        }
      });
  }


  find(storeId: string) {

    let query = this.getQuery(storeId);

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  async findFeatured(storeId: string) {

    let query = this.getQuery(storeId);

    if(storeId != '') query['query']['featured'] = true;

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }
  
  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId: string, resource: Portfolio) {

    resource['storeId'] = storeId;
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
