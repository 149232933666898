import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Product } from 'shared/models/product';
import { ShoppingCartItem } from 'shared/models/shopping-cart-item';
import { Subscription } from 'rxjs/Subscription';
import { select } from '@angular-redux/store';
import { fadeImg } from 'app/animations';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css'],
  animations: [ fadeImg ]
})

export class ProductCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('product') product: Product;
  @Input('show-actions') showActions = true;
  @Input('show-purchased') showPurchased = false;

  @ViewChild('img') img: any;

  @select() path;
  @select() showStore;
  cartItem: ShoppingCartItem;

  subscription: Subscription;

  constructor(private cartItemService: ShoppingCartItemService) {}

  async ngOnInit() {

    this.subscription = (await this.cartItemService.findCartItem(this.product._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.cartItem = res[0];
      });
  }
  
  ngAfterViewInit() {

    if(this.img) {
      
      this.img.height = '600px';
      this.img.width = '600px';
      this.img.hidden = true;

      this.img.onload = function() {

        this.img.height='100%'
        this.img.width='100%'
        this.img.hidden = false;
      };
    }

  }
  
  addToCart() {

    this.cartItemService.create(this.product);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}