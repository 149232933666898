
<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async), 'products'] : ['/products']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px"
  *ngIf="product">

  <div @titleAnimation fxFlex="nogrow">
    <h1>Product</h1>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="product">
  
    <div
      fxFlex.xl="40"
      fxFlex.lg="70"
      fxFlex.md="80"
      fxFlex.sm="90"
      fxFlex.xs="100"
      *ngIf="product && product.imageUrl">

      <product-card [product]="product"></product-card>
    </div>



  <div @slide fxFlex="nogrow" *ngIf="product.description">

    <h3>Description: </h3>

    <p *ngIf="product.description">{{ product.description }}</p>
  </div>

  <div @slide fxFlex="nogrow">

    <table *ngIf="product">
      <tr>
        <th>Technical Details</th>
      </tr>
      <tr *ngIf="product.brand">
        <td>Brand Name</td>
        <td *ngIf="product.brand">{{ product.brand }}</td>
      </tr>
      <tr *ngIf="product.series">
        <td>Series</td>
        <td *ngIf="product.series">{{ product.series }}</td>
      </tr>
      <tr *ngIf="product.model">
        <td>Model</td>
        <td *ngIf="product.model">{{ product.model }}</td>
      </tr>
    </table>
  </div>
  
  <div @slide fxFlex="nogrow">
    <table *ngIf="product">
      <tr>
        <th>Item Details</th>
      </tr>
      <tr *ngIf="product.weight">
        <td>Item Weight</td>
        <td *ngIf="product.weight">{{ product.weight }}</td>
      </tr>
      <tr *ngIf="product.length">
        <td *ngIf="product.length">Item Length</td>
        <td *ngIf="product.length">{{ product.length }}</td>
      </tr>
      <tr *ngIf="product.width">
        <td *ngIf="product.width">Item Width</td>
        <td *ngIf="product.width">{{ product.width }}</td>
      </tr>
      <tr *ngIf="product.height">
        <td *ngIf="product.height">Item Height</td>
        <td *ngIf="product.height">{{ product.height }}</td>
      </tr>
      <tr *ngIf="product.dimensional_unit">
        <td *ngIf="product.dimensional_unit">Dimensional unit</td>
        <td *ngIf="product.dimensional_unit">{{ product.dimensional_unit }}</td>
      </tr>
      <tr *ngIf="product.color">
        <td>Item Color</td>
        <td>{{ product.color }}</td>
      </tr>
    </table>
  </div>

  <div @slide fxFlex="nogrow">

    <table>
      <tr>
        <th>Additional Information</th>
      </tr>
      <tr *ngIf="product._id">
        <td>ID</td>
        <td>{{ product._id }}</td>
      </tr>
      <tr>
        <td>Customer Reviews</td>
        <td>Review feature under development</td>
      </tr>
      <tr *ngIf="product.shipping && product.shipping.weight">
        <td>Shipping Weight</td>
        <td>{{ product.shipping.weight }}</td>
      </tr>

      <tr *ngIf="product.shipping && product.shipping.domestic">
        <td>Domestic Shipping</td>
        <td>{{ product.shipping.domestic }}</td>
      </tr>
    </table>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="product.images">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let image of product.images">

      <image-card [image]="image"></image-card>

    </div>
  </div>
</div>