import { tassign } from 'tassign';
import {
  FETCH_CART_ITEMS_SUCCESS,
  FETCH_CART_ITEMS_COUNT_SUCCESS,
  FETCH_CART_ITEMS_AMOUNT_SUCCESS,
  FETCH_STORE_CARTS_SUCCESS,

  FETCH_TODOS_SUCCESS,
  ADD_TODO,
  TOGGLE_TODO,
  REMOVE_TODO,
  CLEAR_TODOS,
  FETCH_SITE_SUCCESS,
  FETCH_STORE_SUCCESS,
  FETCH_MY_STORE_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_UPLOADS_SUCCESS,
  FETCH_PATH_SUCCESS,
  FETCH_STOREID_SUCCESS,
  FETCH_MYSTOREID_SUCCESS,
  FETCH_SHOWSTORE_SUCCESS } from 'app/actions';

export interface IAppState {

  // Important, show store in card header on DAB Fare
  showStore: boolean;

  storeId: any;

  myStoreId: any;

  path: any;

  uploads: any;

  user: any;

  site: any;

  myStore: any;

  store: any;

  // shopping cart items
  cartItems: any[]

  cartItemsCount: any;

  storeCarts: any[];

  amount: any;


  todos: any[];
  lastUpdate: null;

}

export const INITIAL_STATE: IAppState = {

  // Important, show store in card header on DAB Fare
  showStore: false,

  storeId: '1',

  myStoreId: '1',

  path: '',

  uploads: [],

  user: {},

  site: {},

  myStore: {},

  store: {},

  // shopping cart items

  cartItems: [],

  cartItemsCount: null,

  storeCarts: [],

  amount: null,

  todos: [],
  lastUpdate: null  
}


export function rootReducer(state: IAppState, action): IAppState {

  switch (action.type) {

    case FETCH_SHOWSTORE_SUCCESS:

    console.log('FETCH_SHOWSTORE_SUCCESS');

    state.showStore = action.showStore;

    return tassign (state, {
      showStore: state.showStore
    });


    case FETCH_STOREID_SUCCESS:

    console.log('FETCH_STOREID_SUCCESS');

    state.storeId = action.storeId;

    return tassign (state, {
      storeId: state.storeId
    });

    case FETCH_MYSTOREID_SUCCESS:

    console.log('FETCH_MYSTOREID_SUCCESS');

    state.myStoreId = action.myStoreId;

    return tassign (state, {
      storeId: state.storeId
    });

    case FETCH_PATH_SUCCESS:

    console.log('FETCH_PATH_SUCCESS');

    state.path = action.path;

    return tassign (state, {
      path: state.path
    });


    case FETCH_UPLOADS_SUCCESS:

    console.log('FETCH_UPLOADS_SUCCESS');

    state.uploads = action.uploads;

    return tassign (state, {
      uploads: state.uploads
    });

    case FETCH_USER_SUCCESS:

    console.log('Store FETCH_USER_SUCCESS');

    state.user = action.user;

    return tassign (state, {
      user: state.user
    });

    case FETCH_SITE_SUCCESS:

    console.log('Site FETCH_SITE_SUCCESS');

    state.site = action.site;

    return tassign (state, {
      site: state.site
    });

    case FETCH_MY_STORE_SUCCESS:

      console.log('Store FETCH_MY_STORE_SUCCESS');

      state.myStore = action.myStore;

      return tassign (state, {
        myStore: state.myStore,
      });

    case FETCH_STORE_SUCCESS:

      console.log('Store FETCH_STORE_SUCCESS');

      state.store = action.store;

      return tassign (state, {
        store: state.store
      });

  // Shopping cart items switch

    case FETCH_CART_ITEMS_SUCCESS:

      console.log('Store FETCH_CART_ITEMS_SUCCESS', action.cartItems);

      state.cartItems = action.cartItems;

      return tassign (state, {
        cartItems: state.cartItems
      });

    case FETCH_CART_ITEMS_COUNT_SUCCESS:

      console.log('Store FETCH_CART_ITEMS_COUNT_SUCCESS', action.cartItemsCount);

      state.cartItemsCount = action.cartItemsCount;

      return tassign (state, {
        cartItemsCount: state.cartItemsCount
      });

    case FETCH_CART_ITEMS_AMOUNT_SUCCESS:

      console.log('Store FETCH_CART_ITEMS_AMOUNT_SUCCESS', action.amount);

      state.amount = action.amount;

      return tassign (state, {
        amount: state.amount
      });
    
    case FETCH_STORE_CARTS_SUCCESS:

      console.log('Store FETCH_STORE_CARTS_SUCCESS', action.storeCarts);

      state.storeCarts = action.storeCarts;

      return tassign (state, {
        storeCarts: state.storeCarts
      });

  // todos switch

    case FETCH_TODOS_SUCCESS:

      console.log('store FETCH_TODOS_SUCCESS', action.todos);

      state.todos = action.todos;

      return tassign (state, {
        todos: state.todos,
        lastUpdate: null
      });

    case ADD_TODO:


      return tassign (state, {
        todos: state.todos,
        lastUpdate: new Date()
      });

    case TOGGLE_TODO:

      return tassign (state, {
        todos: state.todos,
        lastUpdate: new Date()
      });
    
    case REMOVE_TODO:

      return tassign (state, {
        todos: state.todos.filter(t => t._id !==action._id),
        lastUpdate: new Date()
      });

    case CLEAR_TODOS:

      return tassign (state, {
        todos: [],
        lastUpdate: new Date()
      });
  }
  return state;
}