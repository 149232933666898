import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import 'rxjs/add/operator/take';

@Injectable()
export class AdminAuthGuard implements CanActivate {

  myStore: any = {};
  user: any = {};

  constructor(
    private auth: AuthService,
    private router: Router,
    private ngRedux: NgRedux<IAppState>) { }

  async canActivate() {

    let result = await this.auth.authenticateJwt();
    if (result) return result;
    this.router.navigate(['/no-access']);
    return result;

  }

}