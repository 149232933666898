<ng-template #anonymousUser>
  <a mat-button matTooltip="Login/Signup" routerLink="/login">
    <mat-icon>account_circle</mat-icon>
  </a>
</ng-template>

<button
  mat-button
  *ngIf="user && user.username; else anonymousUser"
  matTooltip="User Menu"
  [matMenuTriggerFor]="userMenu">
  {{ user.username }}
</button>

<mat-menu aria-label="User Menu Dropdown Panel" #userMenu="matMenu">

  <ng-template matMenuContent>

    <a mat-menu-item routerLink="/my/profile">
      <mat-icon>dashboard</mat-icon>
      My Profile
    </a>
    
    <a mat-menu-item routerLink="/my/messages">
      <mat-icon>message</mat-icon>      
      My Messages
    </a>
    <a mat-menu-item routerLink="/my/orders">
      <mat-icon>receipt</mat-icon>
      My Orders
    </a>
    <a mat-menu-item routerLink="/my/todos">
      <mat-icon>view_list</mat-icon>      
      My Todos
    </a>
    <a mat-menu-item routerLink="/my/sources">
      <mat-icon>credit_card</mat-icon>      
      My Sources
    </a>
    <a mat-menu-item routerLink="/my/account">
      <mat-icon>account_circle</mat-icon>      
      My Account
    </a>
    <a mat-menu-item [routerLink]="['/my/uploads']">
      <mat-icon>cloud_upload</mat-icon>
      My Uploads
    </a>
    
    <a
      mat-menu-item
      *ngIf="user && !user.admin"
      routerLink="/my/store/create">
      <mat-icon>store</mat-icon>
      Create My Free Store
    </a>

    <hr>
    <button mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      Log Out
    </button>
  </ng-template>
</mat-menu>