import { NgRedux } from '@angular-redux/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { Plan } from 'shared/models/plan';
import { Product } from 'shared/models/product';
import { Store } from 'shared/models/store';
import { PlanService } from 'shared/services/plan.service';
import { ProductService } from 'shared/services/product.service';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.css']
})
export class PlanFormComponent implements OnInit, OnDestroy {

  @Input('plan') plan: Plan;
  id: string;

  myStore: any;
  
  products: Product[];
  subscription: Subscription;
  myStoreSubscription: Subscription;

  currencyTypes = [
    {text: 'USD'}
  ];

  intervals = [
    {text: 'day'},
    {text: 'week'},
    {text: 'month'},
    {text: 'year'}
  ];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private planService: PlanService,
    private productService: ProductService,
    private ngRedux: NgRedux<IAppState>) {}


  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  async findResources() {
    this.subscription = (await this.productService.findService(this.myStore._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.products = res;
      });
  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.planService.remove(this.id);
        this.router.navigate(['/supreme/subscription/plans']);
      });
  }

  save(resource) {
    if (this.id) this.planService.update(this.id, resource);
    else this.planService.create(this.myStore._id, resource);

    this.router.navigate(['/admin/plans']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}
