import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';
import { Product } from 'shared/models/product';
import { CategoryService } from 'shared/services/category.service';
import { ProductService } from 'shared/services/product.service';
import { UploadService } from 'shared/services/upload.service';
import { Subscription } from 'rxjs/Subscription';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Dimensions } from 'shared/models/dimensions';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})

export class ProductFormComponent implements OnInit, OnDestroy {
  
  @Input('product') product: Product;

 
  categories$: Observable<any>;
  uploads$: Observable<any>;

  myStore: any = {};
  myStoreSubscription: Subscription;
  
  id: string;

  types = [
    { text: 'good' },
    { text: 'service' }
  ];

  weight_units = [ 'grams', 'ounces', 'lbs', 'kilograms' ];
  dimensional_units = [ 'inches', 'feet', 'millimeters', 'centimeters', 'meters' ];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private uploadService: UploadService,
    private service: ProductService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {

    // get categories from category service
    this.categories$ = (await this.categoryService.findByMyStore(this.myStore._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )

    this.uploads$ = (await this.uploadService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )

  }

  pushImage(image) {

    if (!this.product.images) this.product.images = [];
    this.product.images.push(image);
  }

  spliceImage(image) {

    let index: number = this.product.images.indexOf(image);
    this.product.images.splice(index, 1);
  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.service.remove(this.id);
        this.router.navigate(['/admin/products']);
      });
  }

  save(resource) {

    if (this.product.images) resource['images'] = this.product.images;
    if (this.id) this.service.patch(this.id, resource);
    else this.service.create(this.myStore._id, resource);

    this.router.navigate(['/admin/products']);
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
