
import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'admin-store',
  templateUrl: './admin-store.component.html',
  styleUrls: ['./admin-store.component.css']
})

export class AdminStoreComponent implements OnInit, OnDestroy {

  myStore: any;

  myStoreSubscription: Subscription;

  totalPrice;
  servicePrice: Number = 10;
  productPrice: Number = 10;
  portfolioPrice: Number = 5;
  blogPrice: Number = 5;
  
  constructor(private ngRedux: NgRedux<IAppState>) { }

  async ngOnInit() {
    await this.populateMyStore();
  }

  private async populateMyStore() {
    this.myStoreSubscription = (await this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }

  save(formData) {
    // do something
  }


  getTotal() {
    this.totalPrice = 0;
    if (this.myStore.service) this.totalPrice += this.servicePrice;
    if (this.myStore.product) this.totalPrice += this.productPrice;
    if (this.myStore.portfolio) this.totalPrice += this.portfolioPrice;
    if (this.myStore.blog) this.totalPrice += this.blogPrice;
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
