import { Product } from '../models/product';
import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';

import { ShoppingCart } from '../models/shopping-cart';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'shared/services/auth.service';

@Injectable()
export class ShoppingCartService {
  servicePath: string = 'shopping-carts';
  items: any[] = [];
  index: number;
  quantity: number;
  howMany: number;
  userId: string;
  cartId: string;

  constructor(
    private auth: AuthService,
    private feathers: Feathers) { }

  public async getOrCreateCartId(): Promise<string> {
    let cartId = localStorage.getItem('cartId');
    if (cartId) return cartId;

    let userId = localStorage.getItem('feathers-jwt');
    if (userId) {
      cartId = (await this.findOneByUserId(userId))._id;
    }
    if (cartId) {
      console.log('found cartId by user hooray', cartId);
      localStorage.setItem('cartId', cartId);
      return cartId;
    }

    let result = await this.create();
    localStorage.setItem('cartId', result._id);
    return result._id;   
  }

  findOneByUserId(userId: string) {

    return this.feathers
      .service(this.servicePath)
      .find({
        query: {
          $limit: 1,
          userId: userId
        }
      })
  }


  async getCart(): Promise<Observable<ShoppingCart>> {
    let cartId = await this.getOrCreateCartId();
    
    return this.feathers
      .service('shopping-cart-items')
      .watch()
      .find({
        query: {
          cartId: cartId
        }
      });
  }

  private async create() {

    let user = (await this.auth.authUser)
    this.userId = user['_id'];
    if (!this.userId) this.userId = 'anonymous';

    return this.feathers
      .service(this.servicePath)
      .create({
        userId: this.userId
      });
  }

  private async get() {
    let cartId = await this.getOrCreateCartId();
    return this.feathers
      .service(this.servicePath)
      .get(cartId);
  }

  private update(cartId) {
    return this.feathers
      .service(this.servicePath)
      .update(cartId, {
        items: this.items
      });
  }

}