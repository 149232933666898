import { Component, Input } from '@angular/core';
import { Source } from 'shared/models/source';
import { SourceService } from 'shared/services/source.service';

@Component({
  selector: 'source-card',
  templateUrl: './source-card.component.html',
  styleUrls: ['./source-card.component.css']
})
export class SourceCardComponent {

  @Input('source') source: Source;

  constructor(private service: SourceService) {}

  async remove(){
    await this.service.remove(this.source._id);
  }

}
