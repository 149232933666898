
<button mat-raised-button matTooltip="Back" aria-label="Return to shopping cart arrow icon link" [routerLink]="['/shopping-cart']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column"
  fxLayout.sm="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="storeCarts | async as carts">

  <div fxFlex>
    <h1>Check-out</h1>
  </div>

  <div fxFlex="0 1 auto">

    <shopping-cart-summary></shopping-cart-summary>
  </div>

  <div
    fxFlex
    *ngFor="let message of messages">
      {{ message }}

  </div>

  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Store Order Summary
        </mat-panel-title>
        <mat-panel-description>
          Please verify your store order details.
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="98"
        *ngFor="let cart of carts">

        <store-cart-summary [store-shopping-cart]="cart"></store-cart-summary>
      </div>



    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Shopping Cart Items
        </mat-panel-title>
        <mat-panel-description>
          Please verify your items before checkout.
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="98"
        *ngFor="let item of cartItems | async">

        <product-card [product]="item" [show-actions]="false"></product-card>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Shipping Details
        </mat-panel-title>
        <mat-panel-description>
          Please provide your shipping address.
        </mat-panel-description>
      </mat-expansion-panel-header>


      <h2 fxFlex="0 1 auto">Select Shipping Addresses</h2>

      <mat-radio-group [(ngModel)]="shippingAddress">
        <mat-radio-button [value]="">
          Create New Address Form
        </mat-radio-button>
        <mat-radio-button 
          *ngFor="let address of addresses$ | async"
          [value]="address">
          <address-card [address]="address"></address-card>
        </mat-radio-button>
      </mat-radio-group>
    
      <div
        fxFlex.xl="60"
        fxFlex.lg="70"
        fxFlex.md="80"
        fxFlex.sm="90"
        fxFlex.xs="100"
        *ngIf="!shippingAddress">
      
        <shipping-form></shipping-form>
      </div>
    </mat-expansion-panel>



    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Payment Details
        </mat-panel-title>
        <mat-panel-description>
          Please provide your billing information.
        </mat-panel-description>
      </mat-expansion-panel-header>



      <mat-radio-group [(ngModel)]="paySource">
        <mat-radio-button 
          *ngFor="let source of sources$ | async"
          [value]="source">
          <source-card [source]="source"></source-card>
  
        </mat-radio-button>
      </mat-radio-group>
  
  
      <div
        fxFlex.xl="60"
        fxFlex.lg="70"
        fxFlex.md="80"
        fxFlex.sm="90"
        fxFlex.xs="100"
        *ngIf="source">
    
        <source-card [source]="source"></source-card>
      </div>
  
    <div
      fxFlex.xl="60"
      fxFlex.lg="70"
      fxFlex.md="80"
      fxFlex.sm="90"
      fxFlex.xs="100"
      *ngIf="!paySource">
    
      <p>
        Please select a payment method. To add a payment source, please click the link below. Thank you.
      </p>
      <p>
        <button mat-raised-button color="primary" type="button" [routerLink]="['/my/sources/new']">New Card Source</button>
      </p>
    </div>

    </mat-expansion-panel>
  </mat-accordion>



  <div fxFlex="100" *ngIf="amount | async as payAmount">
  
    <button
      mat-raised-button
      color="primary"
      type="button"
      [disabled]="!userId || !carts || !carts.length || !shippingAddress || !payAmount || !paySource"
      (click)="placeOrder(payAmount, shippingAddress, carts)">
      Pay {{ payAmount | currency:'USD':symbol }}
    </button>
  </div>
</div>