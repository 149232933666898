import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogModule } from 'store/blog/blog.module';
import { PortfolioModule } from 'store/portfolio/portfolio.module';
import { ProductModule } from 'store/product/product.module';
import { ServiceModule } from 'store/service/service.module';

@NgModule({
  imports: [
    CommonModule,
    BlogModule,
    PortfolioModule,
    ProductModule,
    ServiceModule
  ],
  declarations: [

  ]
})
export class StoreModule { }
