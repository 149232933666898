import { NgRedux, select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { Product } from 'shared/models/product';
import { ProductService } from 'shared/services/product.service';
import { titleAnimation } from 'app/animations';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  animations: [ titleAnimation ]
})

export class ProductsComponent implements OnInit, OnDestroy {

  @select() path;

  store: any = {}
  storeId: any = '1';

  products: Product[] = [];
  filteredProducts: Product[] = [];
  category: string;

  subscription: Subscription;
  storeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private service: ProductService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {

    await this.populateStore();
  }

  private async populateStore() {
    this.storeSubscription = (await this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
        if(this.storeId != '1'){
          this.findResources();
        }
      });
  }

  private async findResources() {

    this.subscription = (await this.service).find(this.storeId).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe(res => {
        this.products = res;

      this.route.queryParamMap
        .subscribe(params => {
          this.category = params.get('category');
          this.applyFilter();
        });
      });
  }

  private applyFilter() {
    this.filteredProducts = (this.category) ? 
    this.products.filter(p => p.category === this.category) :
    this.products;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.storeSubscription.unsubscribe();
  }
}