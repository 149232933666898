<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">

  <mat-form-field>
    <input 
      matInput
      [(ngModel)]="address.name"
      #name="ngModel"
      class="form-control"
      name="name"
      id="name"
      placeholder="Full Name" 
      required
      autofocus>
    <span matPrefix>
      <mat-icon>perm_identity</mat-icon>      
    </span>
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="name.invalid && name.errors.required">
      Name of address recipient is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      [(ngModel)]="address.phone"
      #phone="ngModel"
      class="form-control"
      name="phone"
      id="phone"
      placeholder="Phone number with area code.">
    <span matPrefix>
      <mat-icon>perm_identity</mat-icon>      
    </span>
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="phone.invalid && phone.errors.required">
      phone of address recipient is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [(ngModel)]="address.line1"
      #line1="ngModel"
      class="form-control"
      name="line1"
      id="line1"
      placeholder="Line one of street address" 
      required>

    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="line1.invalid && line1.errors.required">
      Line one is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [(ngModel)]="address.line2"
      #line2="ngModel"
      class="form-control"
      name="line2"
      id="line2"
      placeholder="Line two of street address">
    <mat-hint>This website is still under development.</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [(ngModel)]="address.city"
      #city="ngModel"
      class="form-control"
      name="city"
      id="city"
      placeholder="City"
      required>
  
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="city.invalid && city.errors.required">
      City of address address is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [(ngModel)]="address.state"
      #state="ngModel"
      class="form-control"
      name="state"
      id="state"
      placeholder="State" 
      required>
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="state.invalid && state.errors.required">
      State of address address is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      [(ngModel)]="address.postal_code"
      #postal_code="ngModel"
      class="form-control"
      name="postal_code"
      id="postal_code"
      type="number"
      placeholder="Postal Code" 
      required>
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="postal_code.invalid && postal_code.errors.required">
      Postal code of address address is required.
    </mat-error>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!f.valid">Save Shipping Address</button>

</form>

