<mat-card *ngIf="portfolio">

  <card-store-header *ngIf="portfolio.store && (showStore | async)" [store]="portfolio.store"></card-store-header>

  <img
    #img
    mat-card-image
    *ngIf="portfolio.imageUrl"
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [routerLink]="(path | async) ? ['/store', (path | async), 'portfolio', portfolio._id] : ['/portfolio', portfolio._id]"
    [src]="'https://dabfare.com/images/'+portfolio.imageUrl"
    alt="{{ portfolio.title }}">

  <mat-card-content>
    <mat-card-title
      *ngIf="portfolio.title"
      [routerLink]="(path | async) ? ['/store', (path | async), 'portfolio', portfolio._id] : ['/portfolio', portfolio._id]">
      {{ portfolio.title }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="portfolio.subtitle">
      {{ portfolio.subtitle }}
    </mat-card-subtitle>

    <p>{{ portfolio.abstract }}</p>
  </mat-card-content>
</mat-card>

