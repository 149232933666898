import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { RoutesRecognized, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { NgRedux } from '@angular-redux/store';
import { FETCH_STORE_SUCCESS, FETCH_STORE_REQUEST, FETCH_MY_STORE_REQUEST, FETCH_MY_STORE_SUCCESS, FETCH_PATH_REQUEST, FETCH_PATH_SUCCESS, FETCH_STOREID_REQUEST, FETCH_STOREID_SUCCESS, FETCH_MYSTOREID_REQUEST, FETCH_MYSTOREID_SUCCESS, FETCH_SHOWSTORE_REQUEST, FETCH_SHOWSTORE_SUCCESS } from 'app/actions';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Injectable()
export class StoreService {
  servicePath: string = 'stores';

  site: any = '';

  storePath: string = '';
  store: any = {};
  storeId: string = '';

  constructor(
    private feathers: Feathers,
    private router: Router,
    private titleService: Title,
    private ngRedux: NgRedux<IAppState>) {}

  // Public Load Store Resource
  loadResource(site: string) {

    this.ngRedux.dispatch({ type: FETCH_STORE_REQUEST });
    this.ngRedux.dispatch({ type: FETCH_PATH_REQUEST });
    this.ngRedux.dispatch({ type: FETCH_STOREID_REQUEST });
    this.ngRedux.dispatch({ type: FETCH_SHOWSTORE_REQUEST });


    this.site = site;

    console.log('store service site', site);

    // If site == dabfare.com, get storePath
    if (this.site == 'dabfare.com') {

      console.log('store service getting store path');
      this.getStorePath();
    }

      // Else get storePath by sitePath
    else {

      this.storePath = this.site.toString().toLowerCase().trim().slice(0, -4);

      // Not on dabfare community page, set path to empty
      this.ngRedux.dispatch({ type: FETCH_PATH_SUCCESS,
        path: ''
      });

      console.log('storePath from site', this.storePath);
      this.findStoreByPath();
    }
  }

  // Public Load MyStore Resource
  async loadMyResource(userId) {
    this.ngRedux.dispatch({ type: FETCH_MY_STORE_REQUEST });
    
    this.ngRedux.dispatch({ type: FETCH_MY_STORE_REQUEST });

    (await this.findByUserId(userId)).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        
        let myStore = res[0];

        this.ngRedux.dispatch({ type: FETCH_MYSTOREID_SUCCESS,
          myStoreId: myStore._id
        });

        this.ngRedux.dispatch({ type: FETCH_MY_STORE_SUCCESS,
          myStore: myStore
        });
    });
  }


  private getStorePath () {
    this.router.events
      .subscribe( val => {
        if (val instanceof RoutesRecognized) {
          this.storePath = val.state.root.firstChild.params['store'];

          if(!this.storePath) this.storePath = '';

          this.ngRedux.dispatch({ type: FETCH_PATH_SUCCESS,
            path: this.storePath
          });

          this.findStoreByPath();
        }
    });
  }


  async findStoreByPath() {

    if(!this.storePath) {
      this.storePath = 'dabfare';

      this.ngRedux.dispatch({ type: FETCH_PATH_SUCCESS,
        path: ''
      });

      this.ngRedux.dispatch({ type: FETCH_STOREID_SUCCESS,
        storeId: ''
      });

      this.ngRedux.dispatch({ type: FETCH_SHOWSTORE_SUCCESS,
        showStore: true
      });

      (await this.findByPath(this.storePath)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {

          this.store = res[0];
        
          this.titleService.setTitle(this.store.title);

          this.ngRedux.dispatch({ type: FETCH_STORE_SUCCESS,
            store: this.store
        });
      });
    }
    else {

      (await this.findByPath(this.storePath)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {

          this.store = res[0];
        
          this.titleService.setTitle(this.store.title);

          this.ngRedux.dispatch({ type: FETCH_STORE_SUCCESS,
            store: this.store
          });

          this.ngRedux.dispatch({ type: FETCH_STOREID_SUCCESS,
            storeId: this.store._id
          });

          this.ngRedux.dispatch({ type: FETCH_SHOWSTORE_SUCCESS,
            showStore: false
          });
        });
    }
  }


  private findByUserId(userId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 1,
          userId: userId
        }
      })
  }

  private findByPath(storePath: string) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 1,
          path: storePath
        }
      });
  }

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  get(resourceId) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: any): any {

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId: string, resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {

    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {

    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }

}
