
<h1>Log In</h1>

<form class="standard-form" #f="ngForm" (ngSubmit)="login(f.value)">

  <mat-form-field>
    <input 
      matInput
      ngModel
      #username="ngModel"
      class="form-control"
      name="username"
      id="username"
      placeholder="Username" 
      required
      autofocus
    >
    <span matPrefix>
      <mat-icon>perm_identity</mat-icon>      
    </span>
    <mat-hint>This website is still under development.</mat-hint>
    <mat-error *ngIf="username.invalid && username.errors.required">
      The username field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
    matInput
    ngModel
    #password="ngModel"
    class="form-control"
    name="password"
    id="password"
    type="password"
    placeholder="Password"
    required>
    <span matPrefix>
      <mat-icon>lock_open</mat-icon>      
    </span>
    <mat-hint>Log in to try out more features.</mat-hint>
  </mat-form-field>

  <button mat-raised-button color="primary">Log In</button>
      
</form>

<p>Don't have an account? Please 
  <a routerLink="/signup">Sign Up!</a>
</p>

<mat-list role="list">
  <mat-list-item *ngFor="let m of messages">{{m}}</mat-list-item>
</mat-list>