import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Order } from 'shared/models/order';
import { AuthService } from 'shared/services/auth.service';
import { OrderService } from 'shared/services/order.service';
import { Subscription } from 'rxjs/Subscription';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})

export class MyOrdersComponent implements OnInit, OnDestroy, AfterViewInit {

  orders: any[];

  subscription: Subscription;

  displayedColumns = [ 'date', 'amount', 'view'];
  dataSource = new MatTableDataSource<Order>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private authService: AuthService,
    private orderService: OrderService) {}


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    let user = await this.authService.authUser();
    
    this.subscription = await this.orderService.findByUserId(user._id).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( orders => {

        this.dataSource = new MatTableDataSource<Order>(orders);        
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
