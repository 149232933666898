import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'shared/services/order.service';
import 'rxjs/add/operator/take';
import { Order } from 'shared/models/order';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})

export class OrderSuccessComponent implements OnInit {

  id: string;
  order: Order;

  constructor(
    private route: ActivatedRoute,
    private service: OrderService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    this.service.get(this.id)
      .take(1)
      .subscribe( res => {
        this.order = res;
      });

  }

}
