import { animate, state, style, transition, trigger, keyframes, useAnimation, query, animateChild, group, stagger } from '@angular/animations';
import { fade, slide, bounceOutLeftAnimation, fadeInAnimation } from 'app/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export const todosAnimation = trigger('todosAnimation', [
  transition(':enter', [
    group([
      query('h1', [
        style({ transform: 'translateY(-20px)' }),
        animate(1000)
      ])
    ])
  ])
])

export const todoAnimation = trigger('todoAnimation', [
  transition(':enter', [
    useAnimation(fadeInAnimation, {
      params: {
        duration: '5000ms'
      }
    })
  ]),
  transition(':leave', [
    style({ backgroundColor: 'red' }),
    animate(1000),
    useAnimation(bounceOutLeftAnimation)        
  ])
])