import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Blog } from 'shared/models/blog';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'shared/services/blog.service';
import { select } from '@angular-redux/store';
import { titleAnimation, slide } from 'app/animations';
import 'rxjs/add/operator/take';

@Component({
  selector: 'blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css'],
  animations: [ titleAnimation, slide ]
})

export class BlogPostComponent implements OnInit {

  @select() path;

  id: string;
  blog: Blog = <Blog>{};

  constructor(
    private route: ActivatedRoute,
    private service: BlogService) {}

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        if(res){
          this.blog = res;
        }
      });
  }


}
