
<div>
  <p>Already have a Stripe Account?</p>
  <p>Log into Stripe and copy your Client ID</p>
  <p>Paste your Client ID below and submit</p>
</div>

<a
  mat-raised-button
  color="primary"
  type="submit"
  [disabled]="!clientId || !( myStore | async) || !(user | async)"
  href="https://connect.stripe.com/oauth/authorize?response_type=code&redirect_uri={{ redirectUrl }}&client_id={{ clientId }}&state={{ ( myStore | async)._id }}&stripe_user[email]={{ (user | async).email }}&scope=read_write">
  Connect Stripe Account
</a>
