
<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px"
  *ngFor="let item of refund.items">

  <div
    fxFlex="40"
    fxLayoutAlign="left center">

    <product-card [product]="item.item.product" [show-actions]="fase"></product-card>

  </div>


  <div
    fxFlex="40"
    fxLayoutAlign="right center">


    <mat-card>
      <img mat-card-image *ngIf="item.imageUrl" [src]="item.imageUrl" alt="{{ item.item.product.title }}">

      <mat-card-content>

        <mat-card-title>Refund Details</mat-card-title>

        <p>
          Reason: {{ item.reason }}
        </p>

        <p>
          Statement: {{ item.statement }}
        </p>
        <p>
          Return Quantity: {{ item.quantity }}
        </p>

      </mat-card-content>
    </mat-card>
    
  </div>
</div>

<p>Refund Status: {{ refund.status }}</p>

<div *ngIf="refund && refund.status == 'Pending'">
  Refund Amount: {{ refundAmount | currency:'USD':symbol }}
  <button
    mat-raised-button
    color="primary"
    (click)="issueRefund()">
    Issue Refund of {{ refundAmount | currency:'USD':symbol }}
  </button>
</div>
