import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  
  servicePath = 'addresses';

  constructor(private feathers: Feathers) { }

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }
  
  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: object) {
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
    
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
