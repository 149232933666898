import { Component } from '@angular/core';
import { AddressService } from 'shared/services/address.service';
import { Address } from 'shared/models/address';

@Component({
  selector: 'shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.css']
})

export class ShippingFormComponent {

  address: Address = <Address>{};

  constructor(private service: AddressService) { }

  async save(address) {
    this.service.create(address);
  }
}
