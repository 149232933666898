
<div
  fxLayout="column"
  fxLayout.sm="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="order && order.cart">


  <div fxFlex="nogrow">

    <p>You placed this order on {{ order.createdAt | date:medium:UTC:en-US }}</p>
    <p>Refunds on this order are eligible through {{ refundByDate | date:medium:UTC:en-US }}</p>
    <p>You have {{ refundDaysRemaining }} days remaining in the refund window</p>
  </div> 

  <div fxFlex="nogrow">
    <order-summary [cart-items-count]="cartItemsCount" [shopping-cart]="order.cart" [amount]="order.amount"></order-summary>
  </div>

  <div fxFlex="nogrow">

    <mat-slide-toggle
      [(ngModel)]="showItems"
      name="showItems"
      id="showItems">
        Show Items
    </mat-slide-toggle>

  </div>
</div>

<div
  fxLayout="column"
  fxLayout.sm="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="showItems">

  <div
    fxFlex="100"
    *ngFor="let storeCart of order.cart">

    <p>Items purchased from {{ storeCart.store.title }}</p>

    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="5px"
      fxLayoutGap.xs="2px">

      <div
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="98"
        *ngFor="let item of storeCart.items">

        <product-card [product]="item.product" [show-actions]="false"></product-card>
      </div>
    </div>
  </div>
</div>


<div *ngIf="storeOrders">

  <div
    fxLayout="column"
    fxLayout.sm="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="5px"
    *ngFor="let storeOrder of storeOrders">

    <div fxFlex="nogrow">
      <store-order-summary [store-shopping-cart]="storeOrder.cart"></store-order-summary>
    </div>

    <div fxFlex="nogrow">

      <p>Status: {{ storeOrder.status }}</p>

      <p *ngIf="storeOrder && storeOrder.status == 'Shipped'">
        Tracking Number: {{ storeOrder.tracking }}
      </p>

      <button mat-raised-button color="primary" [routerLink]="['/my/orders/store', storeOrder._id]">View Store Order</button>
    </div>
  </div>
</div>
