import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'shared/services/auth-guard.service';
import { SharedModule } from 'shared/shared.module';

import { CheckOutComponent } from './components/check-out/check-out.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { ShippingFormComponent } from './components/shipping-form/shipping-form.component';
import { ShoppingCartSummaryComponent } from './components/shopping-cart-summary/shopping-cart-summary.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { StoreCartSummaryComponent } from 'shopping/components/store-cart-summary/store-cart-summary.component';
import { RefundComponent } from './components/refund/refund.component';
import { RefundItemFormComponent } from './components/refund-item-form/refund-item-form.component';
import { CheckoutSummaryComponent } from './components/checkout-summary/checkout-summary.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // shopping cart
      { path: 'shopping-cart', component: ShoppingCartComponent },
      { path: ':store/shopping-cart', component: ShoppingCartComponent },
      
      // authenticated user routes
      { 
        path: 'check-out',
        component: CheckOutComponent,
        canActivate: [ AuthGuard ]
      },
      { 
        path: 'order-success/:id',
        component: OrderSuccessComponent,
        canActivate: [ AuthGuard ]
      },

      // user refund order
      { 
        path: 'my/orders/refund/:id',
        component: RefundComponent,
        canActivate: [ AuthGuard ]
      }

    ])
  ],
  declarations: [
    ShoppingCartComponent,
    CheckOutComponent,
    OrderSuccessComponent,
    ShoppingCartSummaryComponent,
    ShippingFormComponent,
    StoreCartSummaryComponent,
    RefundComponent,
    RefundItemFormComponent,
    CheckoutSummaryComponent
  ]
})
export class ShoppingModule { }
