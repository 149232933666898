import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AddressService } from 'shared/services/address.service';
import { Address } from 'shared/models/address';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';



@Component({
  selector: 'my-addresses',
  templateUrl: './my-addresses.component.html',
  styleUrls: ['./my-addresses.component.css']
})
export class MyAddressesComponent implements OnInit {

  subscription: Subscription;

  displayedColumns = [ 'name', 'street', 'city', 'state', 'zip', 'edit'];
  dataSource = new MatTableDataSource<Address>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: AddressService) {}


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    
    this.subscription = await this.service.find().pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {

        this.dataSource = new MatTableDataSource<Address>(res);        
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
