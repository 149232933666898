import { NgRedux, select } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { BlogService } from 'shared/services/blog.service';
import { PortfolioService } from 'shared/services/portfolio.service';
import { ProductService } from 'shared/services/product.service';
import { ServiceService } from 'shared/services/service.service';
import { slide, titleAnimation } from 'app/animations';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [ slide, titleAnimation ]
})

export class HomeComponent implements OnInit {

  @select() site;
  @select() path;

  @select() cartItemsCount;
  @select() amount;


  services: any = {};
  products: any = {};  
  portfolios: any = {};
  blogs: any = {};
  
  store: any = {};
  storeId: any = '1';

  storeSubscription: Subscription;
  serviceSubscription: Subscription;;
  productSubscription: Subscription;
  portfolioSubscription: Subscription;
  blogSubscription: Subscription;

  constructor(
    private blogService: BlogService,
    private productService: ProductService,
    private serviceService: ServiceService,
    private portfolioService: PortfolioService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {

    await this.populateStore();
  }

  private async populateStore() {
    this.storeSubscription = (await this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;

        if(this.store && this.store._id) {
          (this.ngRedux.select('storeId'))
          .subscribe( res => {
            this.storeId = res;
            if (this.storeId != '1') {
              console.log('home component storeId', this.storeId);
              this.findResources();

            }
          });
        }
      }); 
  }


  private async findResources() {
    


    if (this.store && this.store.service) {
      this.serviceSubscription = (await this.serviceService.findFeatured(this.storeId)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {
          this.services = res;
        });
    }
    if (this.store && this.store.product) {
      this.productSubscription = (await this.productService.findFeatured(this.storeId)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {
          this.products = res;
        });
    }
    if (this.store && this.store.portfolio) {
      this.portfolioSubscription = (await this.portfolioService.findFeatured(this.storeId)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {
          this.portfolios = res;
        });
    }

    if (this.store && this.store.blog) {
      this.blogSubscription = (await this.blogService.findFeatured(this.storeId)).pipe(
        map( (res: Paginated<any>) => res.data ) )
        .subscribe( res => {
          this.blogs = res;
        });
    }
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    if (this.store && this.store.service) this.serviceSubscription.unsubscribe();
    if (this.store && this.store.product) this.productSubscription.unsubscribe();
    if (this.store && this.store.portfolio) this.portfolioSubscription.unsubscribe();
    if (this.store && this.store.blog) this.blogSubscription.unsubscribe();
  }

}
