

<div *ngIf="isConnected; else notConnected;">

  <p *ngFor="let message of messages">
    {{ message }}
  </p>
    
</div>

<ng-template #notConnected>

  <div *ngIf="myStore">

    <p *ngIf="myStore.product">
      Your products won't be made public until you connect a payment platform
    </p>
  
    <p>
      DAB Fare, LLC uses Stripe to get you paid quickly and keep your personal and payment information secure.
      Thousands of companies around the world trust Stripe to process payments for their users.
      Set up an Account with Stripe Express to get paid for your sales from {{ myStore.title }}.
    </p>

    <stripe-connect-express [clientId]="clientId" [redirectUrl]="redirectUrl+'express'"></stripe-connect-express>


    <stripe-connect-standard [clientId]="clientId" [redirectUrl]="redirectUrl+'standard'"></stripe-connect-standard>

  </div>
</ng-template>



