import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-store-unsubscribe',
  templateUrl: './confirm-store-unsubscribe.component.html',
  styleUrls: ['./confirm-store-unsubscribe.component.css']
})

export class ConfirmStoreUnsubscribeComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
