<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">

  <mat-form-field>
    <input 
      matInput
      #email="ngModel"
      [(ngModel)]="user.email"
      name="email"
      id="email"
      placeholder="Email Address"
      required
      autofocus>
      <span matPrefix>
        <mat-icon>email</mat-icon>      
      </span>
      <mat-hint>Type a fake email address for testing. This site is still under development.</mat-hint>
      <mat-error *ngIf="email.ingvalid && email.errors.required">
        Your email address is required.
      </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      #username="ngModel"
      [(ngModel)]="user.username"
      name="username"
      id="username"
      placeholder="Username" 
      required>
      <span matPrefix>
        <mat-icon>perm_identity</mat-icon>      
      </span>
      <mat-hint>Type a fake username for testing. A clever username helps to protect your online privacy.</mat-hint>      
      <mat-error *ngIf="username.ingvalid && username.errors.required">
        The username field is required.
      </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
    matInput
    #password="ngModel"
    [(ngModel)]="user.password"
    name="password"
    id="password"
    type="password"    
    placeholder="Password"
    required>
    <span matPrefix>
      <mat-icon>lock_outline</mat-icon>      
    </span>
    <mat-hint>Type a password for testing. A strong password helps to protect your online privacy.</mat-hint>    
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      #birthday="ngModel"
      [(ngModel)]="user.birthday"
      name="birthday"
      id="birthday"
      [matDatepicker]="birthday"
      placeholder="Your birthday"
      required>
    <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
    <mat-datepicker touchUi="true" #birthday></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      #imageUrl="ngModel"
      [(ngModel)]="user.imageUrl"
      name="imageUrl"
      id="imageUrl"
      type="text"
      placeholder="Image Profile URL"       
      url>
    <mat-error class="alert alert-danger" *ngIf="imageUrl.touched && imageUrl.invalid">
      <mat-error *ngIf="imageUrl.errors.url">Please enter a valid URL.</mat-error>
    </mat-error>
  </mat-form-field>

  <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid">Save</button>
</form>