import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Service } from 'shared/models/service';
import { select } from '@angular-redux/store';
import { fadeImg } from 'app/animations';

@Component({
  selector: 'service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.css'],
  animations: [ fadeImg ]
})

export class ServiceCardComponent implements AfterViewInit {
  @Input('service') service: Service;
  @select() path;
  @select() showStore;

  @ViewChild('img') img: any;

  ngAfterViewInit() {

    console.log('IMAGE URL: ', this.service.imageUrl);
    if(this.img) {

      this.img.height = '600px';
      this.img.width = '600px';
      this.img.hidden = true;

      this.img.onload = function() {

        this.img.height='100%'
        this.img.width='100%'
        this.img.hidden = false;
      };
    }

  }
}
