
import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

import { Store } from 'shared/models/store';


@Component({
  selector: 'admin-account',
  templateUrl: './admin-account.component.html',
  styleUrls: ['./admin-account.component.css']
})

export class AdminAccountComponent implements OnInit, OnDestroy {

  editStore: boolean = false;
  myStore: any;

  subscription: Subscription;

  constructor(private ngRedux: NgRedux<IAppState>) { }

  async ngOnInit() {
    
    await this.populateMyStore();
  }

  private async populateMyStore() {
    this.subscription = (await this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
