import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { RefundItem } from 'shared/models/refund-item';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable()
export class StoreRefundService {
  servicePath: string = 'store-refunds';
  refundItems: any[] = [];
  selectedItems: any[] = [];

  storeId: any = '';
  myStore: any = {};

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });

    (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
      });
    }

  public selectedRefund(selectedItem) {
    this.selectedItems.push(selectedItem);
  }
  
  public deselectedRefund(deselectedItem) {
    this.selectedItems.splice(this.selectedItems.indexOf(deselectedItem.value), 1);
  }

  public getSelectedItems() {
    return this.selectedItems;
  }

  public addRefundItem(refundItem) {

    console.log('store-refund.service refund item', refundItem);
    this.refundItems.push(refundItem);
    console.log('store-refund.service refundItems', this.refundItems);
  }



  public getRefundItems() {

    console.log('get refund items', this.refundItems);
    
    return this.refundItems;
  }

  async placeRefundOrder(
    userId: string,
    storeId: string,
    storeOrderId: string) {
  
    this.create(userId, storeId, storeOrderId)
      .then( result => {
        console.log('order result: ', result);
          
        return result;
      });
  
  }
  
  findByMyStore(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }

  findByUserId(userId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          userId: userId
        }
      });
  }
  
  find(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }

  findAll() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }
  
  create(
    userId: string,
    storeId: string,
    storeOrderId: string) {

    let resource = {};
    resource['userId'] = userId;
    resource['storeId'] = storeId;
    resource['storeOrderId'] = storeOrderId;
    resource['items'] = this.refundItems;
     
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
    
  patch(resourceId, resource) {
      
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
      
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {
     
    return this.feathers
      .service(this.servicePath)
      .remove({
        _id: resourceId
      });
  }
}
