import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'shared/models/product';
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { ShoppingCartItem } from 'shared/models/shopping-cart-item';
import { ShoppingCartService } from 'shared/services/shopping-cart.service';

@Component({
  selector: 'product-quantity',
  templateUrl: './product-quantity.component.html',
  styleUrls: ['./product-quantity.component.css']
})

export class ProductQuantityComponent implements OnInit {
  @Input('shopping-cart-item') cartItem: ShoppingCartItem;
  cartId: string;
  quantity: number = 0;

  constructor(private cartItemService: ShoppingCartItemService) {}

  async ngOnInit() {

  }

  addToCart() {

    this.cartItemService.addToCart(this.cartItem);
  }

  removeFromCart() {
    this.cartItemService.removeFromCart(this.cartItem);
  }

}
