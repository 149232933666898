
<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- View Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Date Placed </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.createdAt }}
      </mat-cell>
    </ng-container>
   
    <!-- Title Column -->
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Shipping Address </mat-header-cell>
      <mat-cell matSort *matCellDef="let row">
        <address-card [address]="row.address"></address-card>
      </mat-cell>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.amount | currency:'USD':symbol }} </mat-cell>
    </ng-container>


    <!-- View Column -->
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View Order </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a [routerLink]="['/supreme/orders', row._id]">View</a>
      </mat-cell>
    </ng-container>

    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let order; columns: displayedColumns;"></mat-row>
  </mat-table>
  
  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>