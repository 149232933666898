import 'rxjs/add/operator/take';

import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { StoreMessage } from 'shared/models/store-message';
import { User } from 'shared/models/user';
import { MessageService } from 'shared/services/message.service';

@Component({
  selector: 'my-messages',
  templateUrl: './my-messages.component.html',
  styleUrls: ['./my-messages.component.css']
})

export class MyMessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  messages: StoreMessage[];
  threads: User[];

  user: any;

  subscription: Subscription;
  userSubscription: Subscription;

  displayedColumns = ['read', 'user', 'view'];
  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: MessageService,
    private ngRedux: NgRedux<IAppState>) { }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    await this.populateUser();
  }

  private async populateUser() {

    this.userSubscription = (await this.ngRedux.select('user'))
      .subscribe( res => {
        this.user = res;
        this.findResources();
      });
  }

  findResources() {
    this.subscription = this.service.findByUserId(this.user._id)
      .map( res => res.data)
      .subscribe( res => {
        this.messages = res;
        this.populateThreads();
      });
  }

  private populateThreads() {
    
    this.threads = [];
    
    for (let i in this.messages) {
    
      let index: number = this.threadFilter(this.messages[i].userId);
      console.log('indexAfter', index);
      if (index < 0) {

        this.threads.push(this.messages[i].user);
      };
    }
    this.dataSource = new MatTableDataSource<User>(this.threads);    
  }


  private threadFilter(query: string) {
        
    let filteredItem = (query) ?
      this.threads.filter(res => res._id.includes(query)) : 
      null;
    console.log('index', this.threads.indexOf(filteredItem[0]));
      
    return filteredItem ? this.threads.indexOf(filteredItem[0]) : -1;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
