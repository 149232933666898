import { Injectable } from '@angular/core';

import { CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService, 
    private router: Router) { }
  
  async canActivate(route, state: RouterStateSnapshot) {
  
    let result = await this.auth.authenticateJwt();
    if (result) return result;
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return result;
  }
}
