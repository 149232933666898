import { Component, Input } from '@angular/core';
import { StripeCheckoutLoader, StripeCheckoutHandler } from 'ng-stripe-checkout';
import { OrderService } from 'shared/services/order.service';
import { ShoppingCart } from 'shared/models/shopping-cart';
import { Address } from 'shared/models/address';

@Component({
  selector: 'stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})

export class StripePaymentComponent {
  @Input('userId') userId: string;
  @Input('shopping-cart') storeCarts: ShoppingCart[];
  @Input('amount') amount: number;
  @Input('shippingAddress') shippingAddress: Address;
  private stripeCheckoutHandler: StripeCheckoutHandler;

  constructor(
    private orderService: OrderService,
    private stripeCheckoutLoader: StripeCheckoutLoader) { }
  

  public ngAfterViewInit() {
    this.stripeCheckoutLoader.createHandler({
      key: 'pk_test_YOA8mxiUH6de07rB4H8lGH9W',
      name: 'DAB LAB LLC',
      description: 'Widget',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto',
      token: (token) => {
        console.log('Payment successful!', token);
        console.log('stripe requesting order');
        this.placeOrder(token);
      }
    }).then((handler: StripeCheckoutHandler) => {
      console.log('activating stripe checkout handler');
      
      this.stripeCheckoutHandler = handler;
    });
  }

  public onClickBuy() {
    let stripeAmount = this.amount*100;
    

    this.stripeCheckoutHandler.open({
      amount: stripeAmount,
      currency: 'USD',      
      name: 'DAB LAB LLC',
      description: 'Widget',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto'
    });
  }

  public onClickCancel() {
    this.stripeCheckoutHandler.close();
  }

  async placeOrder(token) {
    
    await this.orderService.placeOrder(this.amount, this.shippingAddress, this.storeCarts);
  }

}
