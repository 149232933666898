import { Component, Input } from '@angular/core';
import { ShoppingCart } from 'shared/models/shopping-cart';

import { select } from '@angular-redux/store';

@Component({
  selector: 'shopping-cart-summary',
  templateUrl: './shopping-cart-summary.component.html',
  styleUrls: ['./shopping-cart-summary.component.css']
})
export class ShoppingCartSummaryComponent {
  
  @select() storeCarts;
  @select() amount;
  @select() cartItemsCount;

  
}
