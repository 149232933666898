import { select, NgRedux } from '@angular-redux/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ServiceService } from 'shared/services/service.service';
import { Subscription } from 'rxjs/Subscription';
import { IAppState } from 'app/store';
import { slide } from 'app/animations';
import { titleAnimation } from 'store/service/components/services/services.component.animations';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  animations: [ titleAnimation, slide ]
})

export class ServicesComponent implements OnInit, OnDestroy {

  @select() path;

  storeId: any = '';
  
  services$: Observable<any>;

  storeSubscription: Subscription;

  constructor(
    private service: ServiceService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
        this.findResources();
      });
  }

  private async findResources() {

    this.services$ = this.service.find(this.storeId)
      .map( s => s.data );
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
