import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoryService } from 'shared/services/category.service';
import { Observable } from 'rxjs/Observable';
import { select, NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'blog-filter',
  templateUrl: './blog-filter.component.html',
  styleUrls: ['./blog-filter.component.css']
})
export class BlogFilterComponent implements OnInit, OnDestroy {

  @select() path;

  storeId: any = '';
  storeSubscription: Subscription;
  
  categories$: Observable<any>;

  constructor(
    private service: CategoryService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (await this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
        this.findResources();
      });
  }

  private async findResources() {

    this.categories$ = (await this.service.find(this.storeId)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
