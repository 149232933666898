<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">

  <mat-form-field>
    <mat-select
      #type="ngModel"
      [(ngModel)]="link.type"
      name="type"
      id="type"
      placeholder="type"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let type of types" [value]="type.value">
        {{ type.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="type.touched && type.invalid">
      Please select your link type.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      #site="ngModel"
      [(ngModel)]="link.site"
      name="site"
      id="site"
      placeholder="site"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let site of sites" [value]="site">
        {{ site.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="site.touched && site.invalid">
      Please select your link site.
    </mat-error>
  </mat-form-field>
  
  <mat-form-field *ngIf="link.site">
    <span matPrefix>{{ link.site.baseUrl }}</span>
    <input 
      matInput
      #endpoint="ngModel"
      [(ngModel)]="link.endpoint"
      name="endpoint"
      id="endpoint"
      type="text"
      placeholder="Your endpoint"
      required>
    <mat-error *ngIf="endpoint.touched && endpoint.invalid">
      Please complete your link URL.
    </mat-error>
  </mat-form-field>


  <button mat-raised-button color="primary" type="submit">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="delete()">Delete</button>    
</form>