import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Upload } from 'shared/models/upload';
import { UploadService } from 'shared/services/upload.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';
import { fadeImg } from 'app/animations';

@Component({
  selector: 'upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.css'],
  animations: [ fadeImg ]
})

export class UploadCardComponent implements AfterViewInit {

  @Input('upload') upload: Upload;
  @ViewChild('img') img: any;

  constructor(
    private dialog: MatDialog,
    private service: UploadService) {}

    ngAfterViewInit() {

      console.log('IMAGE URL: ', this.upload.imageUrl);
      
      if(this.img) {
        
        this.img.height = '600px';
        this.img.width = '600px';
        this.img.hidden = true;
  
        this.img.onload = function() {
  
          console.log('upload-card onload img triggered');
          this.img.height='100%'
          this.img.width='100%'
          this.img.hidden = false;
        };
      }
  
    }

  loadedImage() {
    console.log('upload-card fade loadedImage (load) triggered');
    this.img.height='100%'
    this.img.width='100%'
    this.img.hidden = false;
  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.upload._id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.service.remove(this.upload._id);
      });
  }
}
