import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FETCH_SITE_REQUEST, FETCH_SITE_SUCCESS } from 'app/actions';
import { IAppState } from 'app/store';
import { Feathers } from 'shared/services/feathers.service';
import { StoreService } from 'shared/services/store.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  servicePath: string = 'sites';

  constructor(
    private feathers: Feathers,
    private storeService: StoreService,
    private ngRedux: NgRedux<IAppState>) { }


  loadResource() {

    this.ngRedux.dispatch({ type: FETCH_SITE_REQUEST });

    let site =  window.location.hostname;

    site = site.toString().toLowerCase().trim();

    this.ngRedux.dispatch({ type: FETCH_SITE_SUCCESS,
      site: site
    });

    this.storeService.loadResource(site);
  }

  findByUserId(userId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 1,
          userId: userId
        }
      })
  }

  findByPath(storePath: string) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 1,
          path: storePath
        }
      });
  }

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  get(resourceId) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: any): any {

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId: string, resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {

    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {

    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
