
<h1>My Todos Dashboard</h1>>

<p>
  Last Update: {{ (lastUpdate | async) | date:'mediumTime' }}
</p>

<p>
  Total items: {{ (todos | async).length }}
</p>
<p>
  <button
    mat-raised-button
    color="accent"
    type="button"
    (click)="clearTodos()">
    Delete All
  </button>
</p>
