  
<mat-card *ngIf="user">
  <img
    mat-card-image
    id="mat-card-image"
    *ngIf="user.imageUrl"
    alt="{{ user.username }}"
    [src]="'https://dabfare.com/images/'+user.imageUrl">

  <mat-card-header>
    <mat-card-title *ngIf="user.title">{{ user.username }}</mat-card-title>
    <mat-card-subtitle *ngIf="user.subtitle">{{ user.email }}</mat-card-subtitle>
  </mat-card-header>


  <mat-card-content>
    <p>Birthday: {{ user.birthday }}</p>
    <p>Admin Status: {{ user.admin }}</p>
  </mat-card-content>

  <mat-card-footer *ngIf="user && user.storeId">
    Store ID: {{ user.storeId }}
  </mat-card-footer>
</mat-card>