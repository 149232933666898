
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StripeCheckoutHandler, StripeCheckoutLoader } from 'ng-stripe-checkout';
import { SourceService } from 'shared/services/source.service';


@Component({
  selector: 'stripe-source',
  templateUrl: './stripe-source.component.html',
  styleUrls: ['./stripe-source.component.css']
})
export class StripeSourceComponent {

  stripeCheckoutHandler: StripeCheckoutHandler;

  constructor(
    private router: Router,
    private service: SourceService,
    private stripeCheckoutLoader: StripeCheckoutLoader) { }


  public ngAfterViewInit() {
    this.stripeCheckoutLoader.createHandler({
      key: 'pk_test_YOA8mxiUH6de07rB4H8lGH9W',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto',
      token: (token) => {
        console.log('Tokenization successful!', token);

        this.placeOrder(token);
      }
    }).then((handler: StripeCheckoutHandler) => {
      console.log('activating stripe checkout handler');
      
      this.stripeCheckoutHandler = handler;
    });
  }

  public onClickBuy() {

    this.stripeCheckoutHandler.open({

      currency: 'USD',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto'

    });
  }

  public onClickCancel() {
    this.stripeCheckoutHandler.close();
  }

  async placeOrder(token) {
    
    await this.service.create({ token: token });
    await this.router.navigate(['/my/sources']);
  }
}
