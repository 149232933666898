import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css']
})
export class ImageGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  yourNotificationFunction(event) {
    // something changed
    // do something about it
  }

}
