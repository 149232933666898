
<mat-toolbar color="primary">

    <mat-toolbar-row *ngIf="store && store.title">

      <span class="fill-remaining-space"></span>

      <h6>{{ store.title }}<mat-icon>copyright</mat-icon></h6>

      <span class="fill-remaining-space"></span>

    </mat-toolbar-row>

  <mat-toolbar-row *ngIf="links">

    <span class="fill-remaining-space"></span>

    <a
      mat-icon-button
      *ngFor="let link of links"
      matTooltip="{{ link.site.name }}"
      href="{{ link.site.baseUrl + link.endpoint }}"
      target="_blank">
      <fa name="{{ link.site.name }}"></fa>
    </a>

    <span class="fill-remaining-space"></span>

  </mat-toolbar-row>

  <mat-toolbar-row>

    <p>Powered by DAB LAB LLC<mat-icon>copyright</mat-icon>2016</p>

    <span class="fill-remaining-space"></span>

    <a mat-icon-button href="/">
      <img src="favicon.ico" alt="DAB LAB LLC Logo">
    </a>
  </mat-toolbar-row>

</mat-toolbar>
