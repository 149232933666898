<div
  fxLayout="row"
  fxLayout.xs="row"
  fxLayoutGap="0px"
  fxLayoutGap.xs="0px"
  fxLayoutAlign="space-between center">
  
  <div fxFlex="none">
    <button
      mat-raised-button
      color="accent"
      [disabled]="cartItem.quantity <= 0"
      (click)="removeFromCart()">-</button>
  </div>

  <div
    fxFlex
    fxLayoutAlign="space-around center">
    {{ cartItem.quantity }} in cart
  </div>

  <div fxFlex="nogrow">
    <button
      mat-raised-button
      color="accent"
      [disabled]="cartItem.quantity >= cartItem.product.stock"
      (click)="addToCart()">+</button>
  </div>
</div>