import { Component, Input } from '@angular/core';
import { StoreRefundService } from 'shared/services/store-refund.service';

@Component({
  selector: 'refund-item-form',
  templateUrl: './refund-item-form.component.html',
  styleUrls: ['./refund-item-form.component.css']
})
export class RefundItemFormComponent {

  @Input('refund-item') refundItem: any;

  storeRefundItem: any;
  submitted: boolean = false;

  reasons: string[] = ['Defective', 'Damaged on arrival', 'Not as described'];

  constructor(private service: StoreRefundService) { }


  refundItemReady(input) {

    this.submitted = true;
    
    this.storeRefundItem = {
      item: this.refundItem,
      reason: input.reason,
      statement: input.statement,
      imageUrl: input.imageUrl,
      quantity: input.returnQuantity

    }
    this.service.addRefundItem(this.storeRefundItem);
  }
}
