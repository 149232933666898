import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';
import { Blog } from 'shared/models/blog';
import { CategoryService } from 'shared/services/category.service';
import { BlogService } from 'shared/services/blog.service';
import { UploadService } from 'shared/services/upload.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.css']
})

export class BlogFormComponent implements OnInit, OnDestroy {
  @Input('blog') blog: Blog;
  categories$: Observable<any>;
  uploads$: Observable<any>;

  myStore: any;
  id: string;

  myStoreSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private uploadService: UploadService,
    private service: BlogService,
    private ngRedux: NgRedux<IAppState>) {}


  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    console.log('blog images oninit', this.blog.images);

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {
    
    // get categories from category service
    this.categories$ = (await this.categoryService.find(this.myStore._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )

    this.uploads$ = (await this.uploadService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  pushImage(image) {

    if (!this.blog.images) this.blog.images = [];
    this.blog.images.push(image);
  }

  spliceImage(image) {

    let index: number = this.blog.images.indexOf(image);
    this.blog.images.splice(index, 1);
  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.service.remove(this.id);
        this.router.navigate(['/admin/blog']);
      });
  }

  save(resource) {

    if (this.blog.images) resource['images'] = this.blog.images;

    if (this.id) this.service.patch(this.id, resource);
    else this.service.create(this.myStore._id, resource);

    this.router.navigate(['/admin/blog']);
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
