import 'rxjs/add/operator/take';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SourceService } from 'shared/services/source.service';
import { Source } from 'shared/models/source';

@Component({
  selector: 'my-source',
  templateUrl: './my-source.component.html',
  styleUrls: ['./my-source.component.css']
})
export class MySourceComponent implements OnInit {

  id: string;
  source: Source = <Source>{};

  constructor(
    private route: ActivatedRoute,
    private service: SourceService) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => this.source = res);
  }

}
