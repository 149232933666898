
<store-nav></store-nav>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="store">

  <div fxFlex="nogrow" *ngIf="store.title">
    <h1>{{ store.title }}</h1>
  </div>

  <div fxFlex="nogrow" *ngIf="store.subtitle">
    <h2>{{ store.subtitle }}</h2>
  </div>
</div>

  <!-- Services Section -->
  <div 
    fxLayout="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="5px"
    *ngIf="store.service && services && services.length">

    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="5px"
      fxLayoutGap.xs="2px">

      <div
        
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="100"
        *ngFor="let service of services">

        <service-card *ngIf="service && service.store && service.store.service" [service]="service"></service-card>

      </div>
    </div>

    <div @titleAnimation fxFlex="nogrow">
      <a
        mat-button
        [routerLink]="(path | async) ? ['/store', (path | async), 'services'] : ['/services']">

        <h1> Services </h1>
      </a>
    </div>
  </div>


  <!-- Products Section -->
  <div
    fxLayout="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="5px"
    *ngIf="store.product && products && products.length">
    
    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="5px"
      fxLayoutGap.xs="2px">

      <div
        
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="100"
        *ngFor="let product of products">

        <product-card *ngIf="product && product.store && product.store.product && product.store.accountId" [product]="product"></product-card>
      </div>
    </div>

    <div @titleAnimation fxFlex="nogrow">
      <a
        mat-button
        [routerLink]="(path | async) ? ['/store', (path | async), 'products'] : ['/products']">

        <h1> Products </h1>
      </a>
    </div>
  </div>


  <!-- Portfolio Section -->
  <div
    fxLayout="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="5px"
    *ngIf="store.portfolio && portfolios && portfolios.length">

    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="5px"
      fxLayoutGap.xs="2px">

      <div
        
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="100"
        *ngFor="let portfolio of portfolios">

        <portfolio-card *ngIf="portfolio && portfolio.store && portfolio.store.portfolio" [portfolio]="portfolio"></portfolio-card>

      </div>
    </div>

    <div @titleAnimation fxFlex="nogrow">
      <a
        mat-button
        [routerLink]="(path | async) ? ['/store', (path | async), 'portfolio'] : ['/portfolio']">

        <h1> Portfolio </h1>
      </a>
    </div>
  </div>


  <!-- Blog Section -->
  <div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="store.blog && blogs && blogs.length">
  
    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="5px"
      fxLayoutGap.xs="2px">

      <div
        
        fxFlex.xl="18"
        fxFlex.lg="23"
        fxFlex.md="31"
        fxFlex.sm="48"
        fxFlex.xs="100"
        *ngFor="let blog of blogs">

        <blog-card *ngIf="blog && blog.store && blog.store.blog" [blog]="blog"></blog-card>

      </div>
    </div>

    <div @titleAnimation fxFlex="nogrow">
      <a
        mat-button
        [routerLink]="(path | async) ? ['/store', (path | async), 'blog'] : ['/blog']">

        <h1> Blog </h1> 
      </a>
    </div>
  </div>
