import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';
import { Product } from 'shared/models/product';

@Injectable()
export class ProductService {
  servicePath: string = 'products';

  constructor(private feathers: Feathers) {}


    private getQuery(storeId) {

      let query = {};
  
      if(storeId == '') {
        query = {
          query: {
            $sort: { createdAt: -1 }
          }
        }
      }
      else {
        query = {
          query: {
            storeId: storeId,
            $sort: { createdAt: -1 }
          }
        }
      }
      return query;
    }



  // find resources for my store
  findByMyStoreId(myStoreId: string) {

    let query = this.getQuery(myStoreId);


    console.log('Find by myStoreId', myStoreId);
    console.log('Find by myStoreId query', query);


    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  findNum(storeId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $limit: 0
        }
      });
  }

  find(storeId: string) {

    let query = this.getQuery(storeId);
    console.log('Find products query', query);
    console.log('Find products storeId', storeId);


    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  findService(storeId: string) {

    let query = this.getQuery(storeId);
    query['query']['type'] = 'service';

    console.log('Find service products query');
    console.log('Find service products storeId', storeId);


    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  findFeatured(storeId: string) {

    let query = this.getQuery(storeId);
    query['query']['featured'] = true;

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }



  get(resourceId: string) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId: string, resource: Product) {

    resource['storeId'] = storeId;

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId: string, resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId: string, resource: any) {

    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {

    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}