  
<mat-card *ngIf="source">

  <mat-card-content>
    <p *ngIf="source.funding">Funding Type: {{ source.funding }}</p>
    <p *ngIf="source.brand">Brand: {{ source.brand }}</p>
    <p *ngIf="source.last4">Last 4 Digits:{{ source.last4 }}</p>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="warn" (click)="remove()">Delete</button>
  </mat-card-actions>
</mat-card>