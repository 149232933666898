import { select, NgRedux } from '@angular-redux/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from 'shared/models/service';
import { ServiceService } from 'shared/services/service.service';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { titleAnimation, slide } from 'app/animations';
import 'rxjs/add/operator/take';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  animations: [ titleAnimation, slide ]
})

export class ServiceComponent implements OnInit, OnDestroy {

  @select() path;

  service: Service = <Service>{};

  title: string = '';

  store: any = {};
  storeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,    
    private serviceService: ServiceService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    if (this.route.snapshot.paramMap.get('title')) {
      this.title = await this.route.snapshot.paramMap.get('title');

    }
    else this.title = 'DAB Fare, LLC';
    
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;
        this.findResource();
      });
  }

  private async findResource() {
    (await this.serviceService.findByTitle(this.store._id, this.title)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .take(1)
      .subscribe( res => {
        if (res) {
          this.service = res[0];
        }
      });
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
