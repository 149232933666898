import { Component, Input } from '@angular/core';
import { Address } from 'shared/models/address';

@Component({
  selector: 'address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.css']
})
export class AddressCardComponent {

  @Input('address') address: Address;
}
