<p>
  <a mat-raised-button color="primary" [routerLink]="['/admin/plans/new']">New Plan</a>
</p>

<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
      
  <mat-table [dataSource]="dataSource" matSort>
  
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.name }} </mat-cell>
    </ng-container>

    <!-- Currency Column -->
    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Currency </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.currency }} </mat-cell>
    </ng-container>
  
    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.amount | currency:'USD':symbol }} </mat-cell>
    </ng-container>

    <!-- Interval Column -->
    <ng-container matColumnDef="interval">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Interval </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.interval }} </mat-cell>
    </ng-container>

    <!-- Trial Period Days Column -->
    <ng-container matColumnDef="trial_period_days">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Trial Period (Days) </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.trial_period_days }} </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <a [routerLink]="['/admin/products', row._id]">Edit</a>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>