
<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Read Column -->
    <ng-container matColumnDef="read">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Read </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div *ngIf="row.read">
          <mat-icon>done</mat-icon>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Customer Username </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.username }} </mat-cell>
    </ng-container>

    <!-- View Thread Column -->
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef mat-sort-header> View Thread </mat-header-cell>
      <mat-cell *matCellDef="let row">
          <a [routerLink]="storePath ? ['/', storePath, 'admin', 'messages', row._id] : ['/admin/messages', row._id]">View</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>
