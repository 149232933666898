import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { UploadService } from 'shared/services/upload.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'my-uploads',
  templateUrl: './my-uploads.component.html',
  styleUrls: ['./my-uploads.component.css']
})

export class MyUploadsComponent implements OnInit {

  uploads: any[] = [];
  subscription: Subscription;

  constructor(private service: UploadService) { }

  ngOnInit() {

    this.subscription = this.service.find().pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.uploads = res;
        console.log('uploads arrived', this.uploads);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
