
<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div fxFlex="nogrow">

    <h1>Product Categories</h1>

    <input
      matInput
      #input
      (keyup.enter)="create(input)">
  </div>

  <div fxFlex="nogrow">
    <button 
      mat-raised-button
      color="accent"
      type="button"
      *ngFor="let category of categories$ | async"  
      (click)="delete(category)">
      {{ category.text }}
    </button>
  </div>
</div>
