
<upload-button></upload-button>

<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">


  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Portfolio Card Data
        </mat-panel-title>
        <mat-panel-description>
          Basic information about your portfolio.
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field>
        <input 
          matInput
          #title="ngModel"
          [(ngModel)]="portfolio.title"
          name="title"
          id="title"
          type="text"
          placeholder="Title"
          required
          autofocus>
        <mat-error *ngIf="title.touched && title.invalid">
          Title is required.
        </mat-error>
      </mat-form-field>
      
      
      <mat-form-field>
        <input 
          matInput
          #subtitle="ngModel"
          [(ngModel)]="portfolio.subtitle"
          name="subtitle"
          id="subtitle"
          type="text"
          placeholder="Subtitle">
      </mat-form-field>
          
      <mat-form-field>
        <textarea 
          matInput
          matTextareaAutosize
          matAutosizeMinRows="2"
          matAutosizeMaxRows="20"
          #abstract="ngModel"
          [(ngModel)]="portfolio.abstract"
          name="abstract"
          id="abstract"
          type="text"
          placeholder="Abstract"
          required></textarea>
        <mat-error *ngIf="abstract.touched && abstract.invalid">
          Abstract is required.
        </mat-error>
      </mat-form-field>
      
      <mat-form-field>
        <mat-select
          #category="ngModel"
          [(ngModel)]="portfolio.category"
          name="category"
          id="category"
          placeholder="Category"
          required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let c of categories$ | async" [value]="c.text">
            {{ c.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="category.touched && category.invalid">
          Category is required.
        </mat-error>
      </mat-form-field>
            
      <mat-form-field>
        <mat-select
          #imageUrl="ngModel"
          [(ngModel)]="portfolio.imageUrl"
          name="imageUrl"
          id="imageUrl"
          placeholder="ImageUrl"
          required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl">
            {{ upload.originalname }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
          ImageUrl is required.
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>



    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Entry
        </mat-panel-title>
        <mat-panel-description>
          What would you like your portfolio to say?
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field>
          <textarea 
            matInput
            matTextareaAutosize
            matAutosizeMinRows="4"
            matAutosizeMaxRows="20"
            #entry="ngModel"
            [(ngModel)]="portfolio.entry"
            name="entry"
            id="entry"
            type="text"
            placeholder="Entry"></textarea>
          <mat-error *ngIf="entry.touched && entry.invalid">
            Entry is required.
          </mat-error>
        </mat-form-field>
      </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Additional Images
        </mat-panel-title>
        <mat-panel-description>
          Add or subtract Images
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field>
          <mat-select
            name="image"
            id="image"
            placeholder="Add additional images">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl" (click)="pushImage(upload.imageUrl)">
              {{ upload.originalname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field>
          <mat-select
            name="images"
            id="images"
            placeholder="Click to remove image">
            <mat-option *ngFor="let image of portfolio.images" [value]="image" (click)="spliceImage(image)">
              {{ image }}
            </mat-option>
          </mat-select>
        </mat-form-field>

    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Display Preferences
        </mat-panel-title>
        <mat-panel-description>
          Choose how to display your portfolio
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-checkbox
        #featured="ngModel"
        [(ngModel)]="portfolio.featured"
        name="featured"
        id="featured"
        placeholder="Featured">Featured
      </mat-checkbox>

      <mat-checkbox
        #live="ngModel"
        [(ngModel)]="portfolio.live"
        name="live"
        id="live"
        placeholder="Publish Live">Live
      </mat-checkbox>
    </mat-expansion-panel>
  </mat-accordion>


  <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="delete()">Delete</button>    
</form>
