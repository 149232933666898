import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminModule } from 'admin/admin.module';
import { AuthModule } from 'auth/auth.module';
import { CoreModule } from 'core/core.module';
import { MyModule } from 'my/my.module';
import { SharedModule } from 'shared/shared.module';
import { ShoppingModule } from 'shopping/shopping.module';
import { StoreModule } from 'store/store.module';
import { StripeModule } from 'stripe/stripe.module';
import { SupremeModule } from 'supreme/supreme.module';
import { AppComponent } from 'app/app.component';

import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { IAppState, rootReducer, INITIAL_STATE } from 'app/store';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angular2ImageGalleryModule } from 'angular2-image-gallery';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AdminModule,
    AuthModule,
    ShoppingModule,
    CoreModule,
    StripeModule,
    MyModule,
    SharedModule,
    StoreModule,
    SupremeModule,
    NgReduxModule,
    BrowserAnimationsModule,
    Angular2ImageGalleryModule,
    RouterModule.forRoot([])
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.configureStore(rootReducer, INITIAL_STATE);

  }
}
