import 'rxjs/add/operator/take';

import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { StoreRefund } from 'shared/models/store-refund';
import { StoreRefundService } from 'shared/services/store-refund.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'admin-refunds',
  templateUrl: './admin-refunds.component.html',
  styleUrls: ['./admin-refunds.component.css']
})

export class AdminRefundsComponent implements OnInit, OnDestroy, AfterViewInit {

  refunds: any[] = [];

  myStore: any;

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = ['date', 'status', 'user', 'order', 'view'];
  dataSource = new MatTableDataSource<StoreRefund>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private service: StoreRefundService,
    private ngRedux: NgRedux<IAppState>) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    await this.populateMyStore();
  }

  private async populateMyStore() {
    this.myStoreSubscription = (await this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();        

      });
  }

  private async findResources() {
    this.subscription = (await this.service.find(this.myStore._id)).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( refunds => {
        this.refunds = refunds;
        this.dataSource = new MatTableDataSource<StoreRefund>(refunds);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}
