import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'shared/services/auth.service';
import { StoreService } from 'shared/services/store.service';
import { Store } from 'shared/models/store';
import { UploadService } from 'shared/services/upload.service';
import { Observable } from 'rxjs/Observable';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.css']
})
export class StoreFormComponent {

  @Input('store') store: Store;

  uploads$: Observable<any>;

  allowDisplayContent: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private service: StoreService,
    private uploadService: UploadService) { }

  async ngOnInit() {

    this.uploads$ = (await this.uploadService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  async save(resource) {

    if (this.store && this.store._id) {
      await this.service.patch(this.store._id, resource);
    }
    else {
      await this.service.create(resource);

      // re-authenticate user to enable admin status
      await this.auth.authUser();
    }

    this.router.navigate(['/store', resource.path]);
  }
}
