
<mat-card-header *ngIf="store">
  <img
    mat-card-avatar
    *ngIf="store.favicon && store.path && store.title; else useStoreImage"
    [routerLink]="['/store', store.path]"
    [src]="'https://dabfare.com/images/'+store.favicon" alt="{{ store.title }}">

  <ng-template #useStoreImage>
    <img
      mat-card-avatar
      *ngIf="store.imageUrl && store.path && store.title"
      [routerLink]="['/store', store.path]"
      [src]="'https://dabfare.com/images/'+store.imageUrl" alt="{{ store.title }}">
  </ng-template>

  <mat-card-title
    *ngIf="store.path && store.title"
    [routerLink]="['/store', store.path]">
    {{ store.title }}
  </mat-card-title>

  <mat-card-subtitle *ngIf="store.subtitle">{{ store.subtitle }}</mat-card-subtitle>
</mat-card-header>