import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'shared/services/service.service';
import { ProductService } from 'shared/services/product.service';
import { PortfolioService } from 'shared/services/portfolio.service';
import { BlogService } from 'shared/services/blog.service';
import { StoreOrderService } from 'shared/services/store-order.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { UploadService } from 'shared/services/upload.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})

export class AdminDashboardComponent implements OnInit {
  
  numUploads: number;

  numProducts: number;
  numServices: number;
  numPortfolios: number;
  numBlogs: number;

  myStore: any;
  myStoreSubscription: Subscription;
  uploadSubscription: Subscription;
  productSubscription: Subscription;
  serviceSubscription: Subscription;
  portfolioSubscription: Subscription;
  blogSubscription: Subscription;

  constructor(
    private uploadService: UploadService,
    private serviceService: ServiceService,
    private productService: ProductService,
    private portfolioService: PortfolioService,
    private blogService: BlogService,
    private orderService: StoreOrderService,
    private ngRedux: NgRedux<IAppState>) { }

  async ngOnInit() {

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {

    this.uploadSubscription = (await this.uploadService.findNum())
      .map(res => res)
      .subscribe( res => {
        this.numUploads = res.total;
        console.log('res Uploads', res);
        console.log('numUploads', this.numUploads);
      });
    
    this.productSubscription = (await this.productService.findNum(this.myStore._id))
      .map(res => res)
      .subscribe( res => {
        this.numProducts = res.total;
        console.log('res Products', res);
        console.log('numProducts', this.numProducts);

      });
    
    this.serviceSubscription = (await this.serviceService.findNum(this.myStore._id))
      .map(res => res)
      .subscribe( res => {
        this.numServices = res.total;
      });
    
    this.portfolioSubscription = (await this.portfolioService.findNum(this.myStore._id))
      .map(res => res)
      .subscribe( res => {
        this.numPortfolios = res.total;
      });

    this.blogSubscription = (await this.blogService.findNum(this.myStore._id))
      .map(res => res)
      .subscribe( res => {
        this.numBlogs = res.total;
      });
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();

    this.uploadSubscription.unsubscribe();
    this.productSubscription.unsubscribe();
    this.serviceSubscription.unsubscribe();
    this.portfolioSubscription.unsubscribe();
    this.blogSubscription.unsubscribe();
  }
}
