
<h1>Sorcerer Supreme Dashboard</h1>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">


  <div fxFlex="nogrow">
    <mat-list role="list">

      <mat-list-item role="listitem">
        {{ stripeAccountEvents.length }} Account Events
      </mat-list-item>

      <mat-list-item role="listitem" *ngFor="let accountEvent of stripeAccountEvents">
        {{ accountEvent.event.type }}
      </mat-list-item>
    </mat-list>
  </div>

  <div fxFlex="nogrow">
    <mat-list role="list">

      <mat-list-item role="listitem">
        {{ stripeConnectEvents.length }} Connect Events
      </mat-list-item>
     
      <mat-list-item role="listitem" *ngFor="let connectEvent of stripeConnectEvents">
        {{ connectEvent.event.type }}
      </mat-list-item>
    </mat-list>
  </div>
</div>


<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">


  <div 
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98">

    <mat-card>
      {{ users.length }} Users
    </mat-card>
  </div>

  <div 
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98">

    <mat-card>
      {{ stores.length }} Stores
    </mat-card>
  </div>

  <div 
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98">

    <mat-card>
      {{ orders.length }} Orders
    </mat-card>
  </div>

  <div 
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98">

    <mat-card>
      {{ storeOrders.length }} Store Orders
    </mat-card>
  </div>
</div>
