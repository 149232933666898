
<a mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="['/admin/products']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  
  <div fxFlex="40">
    
    <product-form [product]="product"></product-form>
  </div>

  
  <div fxFlex="40">

    <product-card [product]="product" [show-actions]="false"></product-card>
  </div>

  <div
    @slide
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let image of product.images">

    <image-card [image]="image"></image-card>
      
  </div>
</div>