import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';

import { NgRedux } from '@angular-redux/store';
import { FETCH_UPLOADS_SUCCESS, FETCH_UPLOADS_REQUEST } from 'app/actions';
import { IAppState } from 'app/store';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  uploads: any = [];

  servicePath: string = 'uploads';

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {}

  
  // Public Load MyStore Resource
  async loadMyResources(userId) {
    this.ngRedux.dispatch({ type: FETCH_UPLOADS_REQUEST });
    
    (await this.findByUserId(userId)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        
        let uploads = res;

        this.ngRedux.dispatch({ type: FETCH_UPLOADS_SUCCESS,
          uploads: uploads
        });
    });
  }

  findByUserId(userId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          userId: userId,
          $limit: 50,
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  findNum() {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 0
        }
      });
  }

  find() {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 100,
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  patch(resourceId, resource) {

    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string): Promise<any> {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
