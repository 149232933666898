
<div
  fxLayout="row"
  fxLayout.xs="row"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <div
    fxFlex.xl="30"
    fxFlex.lg="45"
    fxFlex.md="65"
    fxFlex.sm="85"
    fxFlex.xs="100">
  
    <signup-form></signup-form>
  </div>
</div>