<button
  mat-icon-button
  *ngIf="user && user.admin && user.supreme"
  matTooltip="Sorcerer Supreme God View"
  [matMenuTriggerFor]="sorcererSupremeMenu">
    
  <mat-icon>gavel</mat-icon>
</button>

<mat-menu #sorcererSupremeMenu="matMenu">
  <ng-template matMenuContent>

    <a mat-menu-item routerLink="/supreme/dashboard">
      <mat-icon>dashboard</mat-icon>
      Supreme Dashboard
    </a>
    
    <a mat-menu-item routerLink="/supreme/orders">
      <mat-icon>receipt</mat-icon>      
      Supreme Orders
    </a>

    <a mat-menu-item routerLink="/supreme/returns">
      <mat-icon>receipt</mat-icon>      
      Supreme Returns
    </a>

    <a mat-menu-item routerLink="/supreme/users">
      <mat-icon>account_box</mat-icon>      
      Supreme Users
    </a>

    <a mat-menu-item routerLink="/supreme/subscription/plans">
      <mat-icon>subscriptions</mat-icon>      
      Supreme Subscription Plans
    </a>

    <a mat-menu-item routerLink="/supreme/subscription/products">
      <mat-icon>subscriptions</mat-icon>
      Supreme Subscription Products
    </a>

  </ng-template>
</mat-menu>