import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable()
export class StripeGuard {
  store: any = {};
  myStore: any = {};
  user: any = {};

  STATE_VALUE: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngRedux: NgRedux<IAppState>) { }

  async canActivate() {

    await this.getStoreId();

    
    if (!this.STATE_VALUE || this.STATE_VALUE == '') {
      return true;
    }

    await this.populateMyStore();



    // check if myStoreId equals STATE_VALUE returned from Stripe
    // else deny access
    if (this.myStore._id == this.STATE_VALUE) {
      return true;
    }
    else {
      this.router.navigate(['/no-access']);
      return false;
    }
  }

  populateMyStore() {

    this.ngRedux.select('myStore')
      .take(1)
      .subscribe( res => {
        this.myStore = res;
      });
  }

  getStoreId() {
    this.route.queryParamMap
      .subscribe(params => {
        this.STATE_VALUE = params.get('state');
        console.log('stripe guard state value', this.STATE_VALUE);
      });
  }
}
