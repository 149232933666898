import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  servicePath: string = 'accounts';

  storeId: any = '1';

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        let myStore = res;
        if (myStore && myStore['_id']) this.storeId = myStore['_id'];
      });
    }

  async connectStripe(STATE_VALUE: string, code: string, type: string): Promise<any> {

    let resource = {};
    resource['storeId'] = STATE_VALUE;
    resource['stripeId'] = code;
    resource['code'] = code;
    resource['type'] = type;

    return await this.create(STATE_VALUE, code, type);
  }


  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: this.storeId
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(STATE_VALUE: string, code: string, type: string): Promise<any> {

    let resource = {};
    resource['storeId'] = STATE_VALUE;
    resource['stripeId'] = code;
    resource['code'] = code;
    resource['type'] = type;

    console.log('create account request type', type);

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
