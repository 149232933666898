import { Component, OnInit } from '@angular/core';
import { StoreService } from 'shared/services/store.service';
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { AuthService } from 'shared/services/auth.service';
import { SiteService } from 'shared/services/site.service';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from 'app/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})

export class AppComponent implements OnInit {
  
  constructor(
    private auth: AuthService,
    private siteService: SiteService,
    private storeService: StoreService,
    private shoppingCartItemService: ShoppingCartItemService) {}

  async ngOnInit () {


    await this.siteService.loadResource();

    //this.storeService.loadResource();

    await this.auth.loadResource();

    await this.shoppingCartItemService.loadResources();

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}