import { NgRedux, select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'ng-navbar',
  templateUrl: './ng-navbar.component.html',
  styleUrls: ['./ng-navbar.component.scss']
})

export class NgNavbarComponent implements OnInit, OnDestroy {
  title: string = 'DAB LAB LLC';

  cartItems$: Observable<any>;
  user: any = {};

  store: any = {};
  storePath: string = '';

  myStore: any = {};
  myStorePath: string = '';

  userSubscription: Subscription;  
  storeSubscription: Subscription;
  myStoreSubscription: Subscription;

  @select() path;
  @select() cartItemsCount;

  noFavicon: any;
  
  constructor(private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateUser();

    await this.populateStore();

    await this.populateMyStore();
  }

  private async populateUser() {

    this.userSubscription = (await this.ngRedux.select('user'))
      .subscribe( res => {
        this.user = res;
      });
  }

  private async populateStore() {
    this.storeSubscription = (await this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;
      });
  }

  private async populateMyStore() {
    this.myStoreSubscription = (await this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;

      });
  }


  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
