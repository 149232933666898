
// TODOS
export const ADD_TODO = 'ADD_TODO';
export const TOGGLE_TODO = 'TOGGLE_TODO';
export const REMOVE_TODO = 'REMOVE_TODO';
export const CLEAR_TODOS = 'CLEAR_TODOS';

export const FETCH_TODOS_REQUEST = 'FETCH_TODOS_REQUEST';
export const FETCH_TODOS_SUCCESS = 'FETCH_TODOS_SUCCESS';

// SHOPPING CART ITEMS
export const FETCH_CART_ITEMS_REQUEST = 'FETCH_CART_ITEMS_REQUEST';
export const FETCH_CART_ITEMS_SUCCESS = 'FETCH_CART_ITEMS_SUCCESS';

export const FETCH_CART_ITEMS_COUNT_SUCCESS = 'FETCH_CART_ITEMS_COUNT_SUCCESS';
export const FETCH_CART_ITEMS_AMOUNT_SUCCESS = 'FETCH_CART_ITEMS_AMOUNT_SUCCESS';
export const FETCH_STORE_CARTS_SUCCESS = 'FETCH_STORE_CARTS_SUCCESS';


//CURRENT SITE
export const FETCH_SITE_REQUEST = 'FETCH_SITE_REQUEST';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';

// CURRENT STORE
export const FETCH_STORE_REQUEST = 'FETCH_STORE_REQUEST';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';

// MY STORE
export const FETCH_MY_STORE_REQUEST = 'FETCH_MY_STORE_REQUEST';
export const FETCH_MY_STORE_SUCCESS = 'FETCH_MY_STORE_SUCCESS';

// USER
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

// UPLOADS
export const FETCH_UPLOADS_REQUEST = 'FETCH_UPLOADS_REQUEST';
export const FETCH_UPLOADS_SUCCESS = 'FETCH_UPLOADS_SUCCESS';

// PATH
export const FETCH_PATH_REQUEST = 'FETCH_PATH_REQUEST';
export const FETCH_PATH_SUCCESS = 'FETCH_PATH_SUCCESS';

// STOREID
export const FETCH_STOREID_REQUEST = 'FETCH_STOREID_REQUEST';
export const FETCH_STOREID_SUCCESS = 'FETCH_STOREID_SUCCESS';

// MYSTOREID
export const FETCH_MYSTOREID_SUCCESS = 'FETCH_MYSTOREID_SUCCESS';
export const FETCH_MYSTOREID_REQUEST = 'FETCH_MYSTOREID_REQUEST';

// STOREID
export const FETCH_SHOWSTORE_REQUEST = 'FETCH_SHOWSTORE_REQUEST';
export const FETCH_SHOWSTORE_SUCCESS = 'FETCH_SHOWSTORE_SUCCESS';