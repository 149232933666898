
import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { Product } from 'shared/models/product';
import { ProductService } from 'shared/services/product.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.css']
})

export class AdminProductsComponent implements OnInit, OnDestroy, AfterViewInit {

  products: Product[] = [];
  myStoreId: any = '1';

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = [ 'type', 'title', 'price', 'stock', 'edit'];
  dataSource = new MatTableDataSource<Product>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private service: ProductService,
    private ngRedux: NgRedux<IAppState>) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStoreId'))
      .subscribe( res => {
        this.myStoreId = res;

        if (this.myStoreId != '1') {
          
          console.log('admin-products found myStore', this.myStoreId)
          this.findResources();
        }
      });
  }

  private async findResources() {

    this.subscription = (await this.service.findByMyStoreId(this.myStoreId)).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        console.log('found products', this.products)
        this.products = res
        this.dataSource = new MatTableDataSource<Product>(this.products);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}