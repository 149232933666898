import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { AccountService } from 'shared/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.css']
})

export class StripeConnectComponent implements OnInit, OnDestroy {

  @select() user;

  isConnected: boolean = false;

  clientId: string = 'ca_DcPQdnK3bkzAhQERPD3XBrdDxV7AWdjy';
  redirectUrl: string = 'https://dabfare.com/admin/account/connect/';

  messages: string[] = [];

  subscription: Subscription;

  myStore: any = {};


  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private service: AccountService,
    private ngRedux: NgRedux<IAppState>) { }

  async ngOnInit() {

    await this.populateMyStore();

    this.route.queryParamMap
      .subscribe(params => {

        let code = params.get('code');
        let STATE_VALUE = params.get('state');

          if(code && STATE_VALUE) {
    
            this.isConnected = true;
            this.messages.push('Welcome back!');

            let type = this.route.snapshot.paramMap.get('type');

            this.service.create(STATE_VALUE, code, type)
            .then( res => {
              this.messages.push('Congratulations! Stripe '+type+' account successfully connected. You are now ready to earn money on this platform. Good luck!');
            })
            .catch( err => {
              this.messages.push('Oops! It appears something has gone wrong. Please contact the site administrator.');
            });
            
          }
      });
  }

  private async populateMyStore() {
    this.subscription = (await this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
