import { Component, OnInit, Input } from '@angular/core';
import { Theme } from 'shared/models/theme';

@Component({
  selector: 'theme-form',
  templateUrl: './theme-form.component.html',
  styleUrls: ['./theme-form.component.css']
})
export class ThemeFormComponent implements OnInit {

  @Input('theme') theme: Theme;

  constructor() { }

  ngOnInit() {
  }

}
