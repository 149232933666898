
import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'shared/services/auth-guard.service';
import { MyMessagesComponent } from 'my/components/my-messages/my-messages.component';
import { MyMessageComponent } from 'my/components/my-message/my-message.component';
import { MyAccountComponent } from 'my/components/my-account/my-account.component';
import { MyProfileComponent } from 'my/components/my-profile/my-profile.component';
import { MyTodoListComponent } from 'my/components/my-todo-list/my-todo-list.component';
import { MyTodoDashboardComponent } from 'my/components/my-todo-dashboard/my-todo-dashboard.component';
import { MyTodosComponent } from 'my/components/my-todos/my-todos.component';

import { MyStoreOrderComponent } from 'my/components/my-store-order/my-store-order.component';
import { MyOrdersComponent } from 'my/components/my-orders/my-orders.component';
import { MyOrderComponent } from 'my/components/my-order/my-order.component';
import { MyRefundComponent } from './components/my-refund/my-refund.component';
import { MyRefundsComponent } from './components/my-refunds/my-refunds.component';
import { MyStoreComponent } from './components/my-store/my-store.component';
import { MySourcesComponent } from './components/my-sources/my-sources.component';
import { MySourceComponent } from './components/my-source/my-source.component';
import { MyAddressesComponent } from './components/my-addresses/my-addresses.component';
import { MyAddressComponent } from './components/my-address/my-address.component';
import { MyUploadsComponent } from './components/my-uploads/my-uploads.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // my user section
      { 
        path: 'my/profile',
        component: MyProfileComponent,
        canActivate: [ AuthGuard ]
      },
      { 
        path: 'my/messages',
        component: MyMessagesComponent,
        canActivate: [ AuthGuard ]
      },
      { 
        path: 'my/todos',
        component: MyTodosComponent,
        canActivate: [ AuthGuard ]        
      },
      {
        path: 'my/account',
        component: MyAccountComponent,
        canActivate: [ AuthGuard ]        
      },

      // My Addresses
      {
        path: 'my/addresses',
        component: MyAddressesComponent,
        canActivate: [ AuthGuard ]        
      },
      {
        path: 'my/address/new',
        component: MyAddressComponent,
        canActivate: [ AuthGuard ]        
      },
      {
        path: 'my/address/:id',
        component: MyAddressesComponent,
        canActivate: [ AuthGuard ]        
      },
      // my orders
      { 
        path: 'my/orders',
        component: MyOrdersComponent,
        canActivate: [ AuthGuard ]        
      },
      {
        path: 'my/orders/:id',
        component: MyOrderComponent,
        canActivate: [ AuthGuard ]
      },

      // my store orders
      { 
        path: 'my/orders/store/:id',
        component: MyStoreOrderComponent,
        canActivate: [ AuthGuard ]        
      },

      // user my refunds
      {
        path: 'my/refunds',
        component: MyRefundsComponent,
        canActivate: [ AuthGuard ]        
      },
      {
        path: 'my/refunds/:id',
        component: MyRefundComponent,
        canActivate: [ AuthGuard ]
      },

      // my payment sources
      {
        path: 'my/sources',
        component: MySourcesComponent,
        canActivate: [ AuthGuard ]
      },

      {
        path: 'my/sources/:id',
        component: MySourceComponent,
        canActivate: [ AuthGuard ]
      },

      // My Uploads

      {
        path: 'my/uploads',
        component: MyUploadsComponent,
        canActivate: [ AuthGuard ]        
      },

      // Create Store Page
      { 
        path: 'my/store/create',
        component: MyStoreComponent,
        canActivate: [ AuthGuard ]
      },
    ])
  ],
  declarations: [
    MyMessagesComponent,
    MyMessageComponent,
    MyAccountComponent,
    MyTodosComponent,
    MyTodoListComponent,
    MyTodoDashboardComponent,
    MyProfileComponent,

    MyOrdersComponent,
    MyOrderComponent,
    MyStoreOrderComponent,
    MyRefundComponent,
    MyRefundsComponent,
    MyStoreComponent,
    MySourcesComponent,
    MySourceComponent,
    MyAddressesComponent,
    MyAddressComponent,
    MyUploadsComponent
  ]
})
export class MyModule { }
