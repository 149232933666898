import { Component, Input } from '@angular/core';
import { Store } from 'shared/models/store';
import { select } from '@angular-redux/store';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent {

  @Input('store') store: Store;
  @select() path;

}
