import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared/shared.module';

import { StripeCheckoutModule, StripeCheckoutLoader } from 'ng-stripe-checkout';

import { AdminAuthGuard } from 'admin/services/admin-auth-guard.service';
import { StripeGuard } from 'stripe/services/stripe-guard.service';

import { StripeConnectExpressComponent } from 'stripe/components/stripe-connect-express/stripe-connect-express.component';
import { StripePaymentComponent } from 'stripe/components/stripe-payment/stripe-payment.component';
import { StripeConnectComponent } from './components/stripe-connect/stripe-connect.component';
import { StripeConnectStandardComponent } from './components/stripe-connect-standard/stripe-connect-standard.component';
import { StripeSourceComponent } from './components/stripe-source/stripe-source.component';
import { AuthGuard } from 'shared/services/auth-guard.service';


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // Stripe Connect Express/Connect
      {
        path: 'admin/account/connect/:type',
        component: StripeConnectComponent,
        canActivate: [ AdminAuthGuard, StripeGuard ]
      },

      // Stripe Connect Account
      {
        path: 'admin/account/connect',
        component: StripeConnectComponent,
        canActivate: [ AdminAuthGuard, StripeGuard ]
      },

      // Stripe New Payment Source
      {
        path: 'my/sources/new',
        component: StripeSourceComponent,
        canActivate: [ AuthGuard ]
      },
    ])
  ],

  declarations: [

    StripeConnectExpressComponent,
    StripePaymentComponent,
    StripeConnectComponent,
    StripeConnectStandardComponent,
    StripeSourceComponent
  ],
  exports: [
    StripeSourceComponent
  ],
  providers: [
    AuthGuard,
    StripeGuard,
    StripeCheckoutModule,
    StripeCheckoutLoader
  ]
})
export class StripeModule { }
