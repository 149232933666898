import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PortfolioService } from 'shared/services/portfolio.service';
import 'rxjs/add/operator/take';
import { Portfolio } from 'shared/models/portfolio';
import { select } from '@angular-redux/store';
import { titleAnimation, slide } from 'app/animations';

@Component({
  selector: 'portfolio-item',
  templateUrl: './portfolio-item.component.html',
  styleUrls: ['./portfolio-item.component.css'],
  animations: [ titleAnimation, slide ]
})
export class PortfolioItemComponent implements OnInit {

  @select() path;
  
  portfolio: Portfolio = <Portfolio>{};
  id: string;
  
  constructor(
    private route: ActivatedRoute,    
    private service: PortfolioService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        if (res){
          this.portfolio = res;
        }
    });
  }
}
