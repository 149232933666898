
<!-- Store HomePage Navigation -->

<nav mat-tab-nav-bar *ngIf="(site | async) == 'dabfare.com'">

  <a
    mat-tab-link
    [routerLink]="['/stores']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    All Stores
  </a>
  <a
    mat-tab-link
    [routerLink]="['/services']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    Services
  </a>
    <a
    mat-tab-link
    [routerLink]="['/products']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    Products
  </a>
  <a
    mat-tab-link
    [routerLink]="['/portfolio']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    Portfolios
  </a>
  <a
    mat-tab-link
    [routerLink]="['/blog']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    Blogs
  </a>
</nav>

<nav mat-tab-nav-bar *ngIf="(site | async) == 'dabfare.com'">
  <a
    mat-tab-link
    [routerLink]="['/store/dabfare']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    DAB Fare Store
  </a>
</nav>