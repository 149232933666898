import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-remove-resource',
  templateUrl: './confirm-remove-resource.component.html',
  styleUrls: ['./confirm-remove-resource.component.css']
})
export class ConfirmRemoveResourceComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
