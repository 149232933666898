
<a mat-raised-button matTooltip="Return" aria-label="Back arrow icon link" [routerLink]="['/admin/links']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div
    fxFlex="40"
    fxLayoutAlign="left center">
    
    <link-form [link]="link"></link-form>

  </div>

  <div
    fxFlex="40"
    fxLayoutAlign="right center">

    <mat-card>
      <mat-card-content *ngIf="link && link.site">
        <a
          mat-icon-button
          matTooltip="{{ link.site.name }}"
          href="{{ link.site.baseUrl + link.endpoint }}" target="_blank">
          <fa name="{{ link.site.name }}"></fa>
        </a>
        <p>{{ link.site.name }}</p>
        <p>{{ link.site.baseUrl + link.endpoint }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
