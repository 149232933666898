import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.css']
})
export class CartItemCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
