
<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">

  <mat-table [dataSource]="dataSource" matSort>

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.createdAt }}</mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.status }} </mat-cell>
    </ng-container>

    <!-- User Email Column -->
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header> User ID</mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.userId }} </mat-cell>
    </ng-container>

    <!-- Order ID Column -->
    <ng-container matColumnDef="order">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Order ID </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.storeOrderId }} </mat-cell>
    </ng-container>

    <!-- View Column -->
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a [routerLink]="['/admin/refunds', row._id]">View</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let order; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>