<p>
  <a mat-raised-button color="primary" [routerLink]="['/admin/links/new']">Create New Link</a>
</p>

<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">

  <mat-table [dataSource]="dataSource" matSort>
      
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef mat-sort-header> icon </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a
          mat-icon-button
          matTooltip="{{ row.site.name }}"
          href="{{ row.site.baseUrl + row.endpoint }}" target="_blank">
          <fa name="{{ row.site.name }}"></fa>
        </a>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.site.name }} </mat-cell>
    </ng-container>
    
    <!-- Link Column -->
    <ng-container matColumnDef="link">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Link </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.site.baseUrl + row.endpoint }} </mat-cell>
    </ng-container>
 
    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
        <a [routerLink]="['/admin/links', row._id]">Edit</a>
      </mat-cell>
    </ng-container>
      
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>