import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared/shared.module';
import { ServiceComponent } from 'store/service/components/service/service.component';
import { ServicesComponent } from 'store/service/components/services/services.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      
      // store services pages
      { path: 'store/:store/services/:title', component: ServiceComponent, data: { animation: 'ServicePage' } },
      { path: 'services/:title', component: ServiceComponent, data: { animation: 'ServicePage' } },


      { path: 'store/:store/services', component: ServicesComponent, data: { animation: 'ServicesPage' } },
      { path: 'services', component: ServicesComponent, data: { animation: 'ServicesPage' } }
    ])
  ],
  declarations: [
    ServiceComponent,
    ServicesComponent
  ]
})
export class ServiceModule { }
