

<div *ngIf="showEdit">
      
  <mat-slide-toggle 
    color="accent"
    [(ngModel)]="edit"
    name="edit"
    id="edit">
      Edit Store
  </mat-slide-toggle>
</div>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div fxFlex *ngIf="store && store.imageUrl">
    <mat-card>
      <mat-card-header></mat-card-header>
      <mat-card-content>
        <img mat-card-image *ngIf="store.imageUrl" [src]="'https://uploads.dablabllc.com/'+store.imageUrl" alt="About {{ store.title }}">
      </mat-card-content>
      <mat-card-actions></mat-card-actions>
    </mat-card>
  </div>

  <div fxFlex="nogrow" *ngIf="store && edit">

    <mat-slide-toggle 
      color="accent"
      [(ngModel)]="editImageUrl"
      name="editImageUrl"
      id="editImageUrl">
        Edit
    </mat-slide-toggle>
  </div>

  <div fxFlex *ngIf="store && edit && editImageUrl">
    <form class="store-imageUrl-form" #imageUrlForm="ngForm" (ngSubmit)="patch(imageUrlForm.value); imageUrl.value=''">
      <mat-form-field>
        <input 
          matInput
          #imageUrl="ngModel"
          [(ngModel)]="store.imageUrl"
          name="imageUrl"
          id="imageUrl"
          type="text"
          placeholder="Image URL"
          required
          url>
        <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
          Image URL is required.
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">Save</button>
      <button mat-raised-button color="warn" type="button" (click)="imageUrl.value=''">Clear Form</button>      

    </form>
  </div>
</div>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div fxFlex *ngIf="store">
    <h1>About
      <small>{{ store.title }}</small>
    </h1>
    <h3 *ngIf="store && store.subtitle">{{ store.subtitle }}</h3>
  </div>
  
  <div fxFlex="nogrow" *ngIf="edit">
      
    <mat-slide-toggle 
      color="accent"
      [(ngModel)]="editMission"
      name="editMission"
      id="editMission">
        Edit
    </mat-slide-toggle>
  </div>
</div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="40px"
    fxLayoutGap.xs="20px">

    <div fxFlex="nogrow" *ngIf="store && store.mission">

      <mat-card>
        <mat-card-content>
          <p>{{ store.mission }}</p>  
        </mat-card-content>
      </mat-card>
    </div>
  
    <div fxFlex="nogrow" *ngIf="store && edit && editMission">

      <form class="store-mission-form" #missionForm="ngForm" (ngSubmit)="patch(missionForm.value); mission.value=''">
        <mat-form-field>
          <textarea 
            matInput
            matTextareaAutosize
            matAutosizeMinRows="2"
            matAutosizeMaxRows="20"
            #mission="ngModel"
            [(ngModel)]="store.mission"
            name="mission"
            id="mission"
            type="text"
            placeholder="Mission"
            required></textarea>
          <mat-error *ngIf="mission.touched && mission.invalid">
            Mission statement is required.
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit">Save</button>
        <button mat-raised-button color="warn" type="button" (click)="mission.value=''">Clear Form</button>      
      </form>
    </div>
  </div>
