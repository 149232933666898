import { Component, Input } from '@angular/core';
import { Theme } from 'shared/models/theme';

@Component({
  selector: 'theme-card',
  templateUrl: './theme-card.component.html',
  styleUrls: ['./theme-card.component.css']
})
export class ThemeCardComponent {

  @Input('theme') theme: Theme;

}
