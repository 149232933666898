
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs/Subscription';
import { Message } from 'shared/models/message';
import { User } from 'shared/models/user';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { MessageService } from 'shared/services/message.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';



@Component({
  selector: 'admin-messages',
  templateUrl: './admin-messages.component.html',
  styleUrls: ['./admin-messages.component.css']
})

export class AdminMessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  threads: User[];
  messages: Message[];
  myStore: any = {};

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = ['read', 'user', 'view'];
  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private service: MessageService,
    private ngRedux: NgRedux<IAppState>) { }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    
    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        if(this.myStore) {
          this.findResources();
        }
      });
  }

  findResources() {
    this.subscription = this.service.findByStoreId(this.myStore._id).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.messages = res;
        console.log('messages', this.messages);
        this.populateThreads();
        
      });
  }

  private populateThreads() {
    
    this.threads = [];
    
    for (let i in this.messages) {
    
      let index: number = this.threadFilter(this.messages[i].fromId);
      console.log('indexAfter', index);
      if (index < 0) {

        this.threads.push(this.messages[i].user);
      };
    }
    this.dataSource = new MatTableDataSource<User>(this.threads);    
  }

  private threadFilter(query: string) {
        
    let filteredItem = (query) ?
      this.threads.filter(res => res._id.includes(query)) : 
      null;
    console.log('index', this.threads.indexOf(filteredItem[0]));

    return filteredItem ? this.threads.indexOf(filteredItem[0]) : -1;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}
