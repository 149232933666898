
import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { FETCH_TODOS_REQUEST, FETCH_TODOS_SUCCESS } from 'app/actions';

@Injectable()
export class TodoService {
  servicePath: string = 'todos';
  
  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) { }

  
  async loadResources() {
    this.ngRedux.dispatch({ type: FETCH_TODOS_REQUEST});

    console.log('TodoService loading resources');
    
    await this.find()
      .map( res => res.data )
      .subscribe( res => {
        console.log('TodoService found todos', res);        
        this.ngRedux.dispatch({ type: FETCH_TODOS_SUCCESS, todos: res });
      }, err => {
        console.log('todo service error', err);
      });
  }

  findByUserId(userId: string) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          userId: userId
        }
      });
  }

  find() {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }
  
  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: string): Promise<any> {

    return this.feathers
      .service(this.servicePath)
      .create({ text: resource });
  }

  patch(resourceId, resource) {

    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string): Promise<any> {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
