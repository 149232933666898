import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';
import { Blog } from 'shared/models/blog';

@Injectable()
export class BlogService {
  servicePath: string = 'blogs';

  storeId: any;
  myStore: any = {};

  constructor(private feathers: Feathers) {}

  private getQuery(storeId) {

    let query = {};

    query = {
      query: {
        $sort: { createdAt: -1 }
      }
    }

    if(storeId != '') {
      query = {
        query: {
          storeId: storeId,
          $sort: { createdAt: -1 }
        }
      }
    }
    return query;
  }

  findDataTable(storeId: string, sort: string, order: string, page: number) {

    let sorter = {};
    sorter[sort] = order;

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $sort: sort,
          $skip: page
        }
      })
  }

  // find resources for my store
  findByMyStore() {
    console.log('blog service myStore._id', this.myStore._id);

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: this.myStore._id
        }
    });
  }


  findNum(storeId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $limit: 0
        }
      });
  }

  find(storeId?: string) {

    let query = this.getQuery(storeId);

      return this.feathers
        .service(this.servicePath)
        .watch()
        .find(query);
  }

  findFeatured(storeId) {

    let query = this.getQuery(storeId);
    if(storeId != '') query['query']['featured'] = true;

      return this.feathers
        .service(this.servicePath)
        .watch()
        .find(query);
  }
  
  get(resourceId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId, resource: Blog) {

    resource['storeId'] = storeId;

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
    
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
