import { Component, Input } from '@angular/core';
import { SubscriptionProduct } from 'shared/models/subscription-product';

@Component({
  selector: 'subscription-product-card',
  templateUrl: './subscription-product-card.component.html',
  styleUrls: ['./subscription-product-card.component.css']
})

export class SubscriptionProductCardComponent {
  @Input('product') product: SubscriptionProduct;

}
