import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable()
export class StoreOrderService {
  servicePath: string = 'store-orders';

  storeId: any = '';
  myStore: any = {};

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  
    (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
      });
    }

  findByMyStore() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: this.myStore._id,
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  find(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  findByOrderId(orderId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          orderId: orderId,
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId: string, resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .create({});
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
