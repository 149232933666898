import 'rxjs/add/operator/take';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Order } from 'shared/models/order';
import { RefundItem } from 'shared/models/refund-item';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';
import { OrderService } from 'shared/services/order.service';
import { StoreOrderService } from 'shared/services/store-order.service';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';



@Component({
  selector: 'my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})

export class MyOrderComponent implements OnInit, OnDestroy {

  id: string;
  user: User;
  order: Order;
  cartItemsCount: number;

  refundDaysRemaining: number;
  refundByDate: Date;
  selectedItems: any[] = [];
  refundItem: RefundItem = <RefundItem>{};

  storeOrders: any[] = [];
  storeOrderSubscription: Subscription;

  showItems: boolean = false;
  selectItems: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private orderService: OrderService,
    private storeOrderService: StoreOrderService) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    this.user = await this.authService.authUser();
    
    await this.populateResources();

    await this.populateStoreOrders();

  }


  async populateStoreOrders() {
    
    this.storeOrderSubscription = (await this.storeOrderService.findByOrderId(this.id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.storeOrders = res;
      });
  }

  async populateResources() {

    (await this.orderService.get(this.id))
      .take(1)
      .subscribe( res => {
        this.order = res;
        this.getItemsCount();
        this.calculateRefundByDate();        
      });
  }

  private getItemsCount() {

    this.cartItemsCount = 0;

    for (let i in this.order.cart) {

      for (let j in this.order.cart[i].items) {
        this.cartItemsCount += this.order.cart[i].items[j].quantity;        

      }
    }
  }

  private calculateRefundByDate() {
    this.refundByDate = new Date(this.order.createdAt);
    this.refundByDate.setDate(this.refundByDate.getDate() + 30);

    
    this.calculateRefundDaysRemaining();
  }

  private calculateRefundDaysRemaining() {
    let refundByTime = this.refundByDate.getTime();
    let currentTime = Date.now();
    let refundTimeRemaining = refundByTime - currentTime;

    this.refundDaysRemaining = refundTimeRemaining / ( 24 * 60 * 60 * 1000 );
  }

  ngOnDestroy() {
    this.storeOrderSubscription.unsubscribe();
  }
}
