import { Component, Input } from '@angular/core';
import { Store } from 'shared/models/store';
import { select } from '@angular-redux/store';

@Component({
  selector: 'card-store-header',
  templateUrl: './card-store-header.component.html',
  styleUrls: ['./card-store-header.component.css']
})
export class CardStoreHeaderComponent {

  @Input('store') store: Store;
}
