
<h1>My Addresses</h1>


<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
    

  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
    </ng-container>

    <!-- Street Column -->
    <ng-container matColumnDef="street">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Street </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
        <p>{{ row.line1 }}</p>
        <p>{{ row.line2 }}</p> 
      </mat-cell>
    </ng-container>

    <!-- City Column -->
    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.city }} </mat-cell>
    </ng-container>

    <!-- State Column -->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.state }} </mat-cell>
    </ng-container>

    <!-- Zip Column -->
    <ng-container matColumnDef="zip">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Zip </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.zip}} </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row">
          <a [routerLink]="['/my/address', row._id]">Edit</a>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  
  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>