
<div
  fxLayout="row wrap"
  fxLayoutAlign="space-around left"
  fxLayout.xs="column"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px">

  <div
    fxFlex="nogrow">

      <store-card [store]="store"></store-card>
  
  </div>
</div>


<div
  fxLayout="row wrap"
  fxLayout.xs="row wrap"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  fxLayoutAlign="space-around center"
  *ngFor="let message of messages$ | async">

  <div         
    fxFlex
    fxFlex.xl="50"
    fxFlex.lg="60"
    fxFlex.md="70"
    fxFlex.sm="80"
    fxFlex.xs="90"
    *ngIf="message">

    <div *ngIf="message.userId == message.fromId; else response">
      
      <div fxFlex="grow"></div>

      <div fxFlex="nogrow">

        <message-card [message]="message" [readerId]="message.userId"></message-card>
         
      </div>
    </div>

    <ng-template #response>
      <div fxFlex="nogrow">

        <message-card [message]="message" [readerId]="message.userId"></message-card>
      </div>

      <div fxFlex="grow"></div>
    </ng-template>
  </div>
</div>
  

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  fxLayoutAlign="space-around center">

  <div
    fxFlex
    fxFlex.xl="30"
    fxFlex.lg="40"
    fxFlex.md="50"
    fxFlex.sm="60"
    fxFlex.xs="70">

    <form class="message-form">

      <mat-form-field>
        <textarea
          matInput
          #message
          name="message"
          id="message"
          type="message"
          placeholder="Type a message"
          matTextareaAutosize
          matAutosizeMinRows="3"
          required>
        </textarea>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" (click)="send(message.value); message.value = ''">Send Message</button>    
    </form>
  </div>
</div>