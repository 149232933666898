
<store-nav></store-nav>

<product-filter></product-filter>

<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  @titleAnimation
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <h1 fxFlex="nogrow">Products</h1>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let product of filteredProducts">

      <product-card *ngIf="product && product.store && product.store.product && product.store.accountId" [product]="product"></product-card>
    
    </div>
  </div>
</div>
