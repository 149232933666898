import { Component, Input, OnInit } from '@angular/core';
import { User } from 'shared/models/user';
import { UserService } from 'shared/services/user.service';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})

export class UserFormComponent {

  @Input('user') user: User;

  constructor(private userService: UserService) { }

  save(resource) {
    this.userService.patch(this.user._id, resource);
  }
}
