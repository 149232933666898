import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'supreme-order',
  templateUrl: './supreme-order.component.html',
  styleUrls: ['./supreme-order.component.css']
})
export class SupremeOrderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
