import { select, NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Portfolio } from 'shared/models/portfolio';
import { PortfolioService } from 'shared/services/portfolio.service';
import { IAppState } from 'app/store';
import { titleAnimation, slide } from 'app/animations';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css'],
  animations: [ titleAnimation, slide ]
})

export class PortfolioComponent implements OnInit, OnDestroy {

  @select() path;
 
  storeId: any = '';

  portfolios: Portfolio[] = [];
  filteredPortfolios: Portfolio[] = [];
  
  category: string;

  subscription: Subscription;
  storeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private service: PortfolioService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
        this.findResources();
      });
  }

  private async findResources() {
    this.subscription = (await this.service.find(this.storeId).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe(items => {
        this.portfolios = items;
    
      this.route.queryParamMap
        .subscribe(params => {
          this.category = params.get('category');
          this.applyFilter();
        });
      }));
  }

  private applyFilter() {
    this.filteredPortfolios = (this.category) ? 
    this.portfolios.filter(p => p.category === this.category) :
    this.portfolios;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.storeSubscription.unsubscribe();
  }
}
