import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared/shared.module';
import { HomeComponent } from 'core/components/home/home.component';
import { NgFooterComponent } from 'core/components/ng-footer/ng-footer.component';
import { NgNavbarComponent } from 'core/components/ng-navbar/ng-navbar.component';
import { StoresComponent } from 'core/components/stores/stores.component';

import { AboutComponent } from 'core/components/about/about.component';
import { ContactComponent } from 'core/components/contact/contact.component';
import { AuthGuard } from 'shared/services/auth-guard.service';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { SupremeMenuComponent } from './components/supreme-menu/supreme-menu.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // Store Home Page
      { path: 'store/:store', component: HomeComponent, data: { animation: 'HomePage' } },


      // home page
      { path: '', component: HomeComponent, data: { animation: 'HomePage' } },


      // About Page
      { path: 'store/:store/about', component: AboutComponent, data: { animation: 'ChangePage' } },
      { path: 'about', component: AboutComponent, data: { animation: 'ChangePage' } },


      // Contact Seller
      { 
        path: 'store/:store/contact',
        component: ContactComponent,
        canActivate: [ AuthGuard ],
        data: { animation: 'ChangePage' }
      },

      { 
        path: 'contact',
        component: ContactComponent,
        canActivate: [ AuthGuard ],
        data: { animation: 'ChangePage' }
      },

      // All Stores page
      { 
        path: 'stores',
        component: StoresComponent,
        data: { animation: 'ChangePage' }
      },

    ])
  ],
  declarations: [
    HomeComponent,
    AboutComponent,
    ContactComponent,
    NgFooterComponent,
    NgNavbarComponent,
    StoresComponent,
    AdminMenuComponent,
    AppMenuComponent,
    UserMenuComponent,
    SupremeMenuComponent
  ],
  exports: [
    NgFooterComponent,
    NgNavbarComponent
  ]
})
export class CoreModule { }
