
<a mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="['/admin/blog']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px"
  *ngIf="blog">

  <div fxFlex="40">
    
    <blog-form [blog]="blog"></blog-form>
          
  </div>
  <div fxFlex="40">

    <blog-card [blog]="blog"></blog-card>
  </div>
</div>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="blog">

  <div
    @slide
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let image of blog.images">

    <image-card [image]="image"></image-card>
      
  </div>
</div>