import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-refund',
  templateUrl: './my-refund.component.html',
  styleUrls: ['./my-refund.component.css']
})
export class MyRefundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
