import { NgModule } from '@angular/core';
import { SupremeOrdersComponent } from './components/supreme-orders/supreme-orders.component';
import { SupremeUsersComponent } from './components/supreme-users/supreme-users.component';
import { SupremeDasboardComponent } from './components/supreme-dasboard/supreme-dasboard.component';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { SupremeAuthGuardService } from 'supreme/services/supreme-auth-guard.service';

import { SupremeOrderComponent } from './components/supreme-order/supreme-order.component';
import { SupremeUserComponent } from './components/supreme-user/supreme-user.component';
import { StripeConnectEventService } from 'supreme/services/stripe-connect-event.service';
import { StripeAccountEventService } from 'supreme/services/stripe-account-event.service';
import { SupremeStoreRefundComponent } from './components/supreme-store-refund/supreme-store-refund.component';
import { SupremeStoreRefundsComponent } from './components/supreme-store-refunds/supreme-store-refunds.component';



@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // Supreme Dashboard
      {
        path: 'supreme/dashboard',
        component: SupremeDasboardComponent,
        canActivate: [ SupremeAuthGuardService ]
      },

      // Supreme Orders
      {
        path: 'supreme/orders/:id',
        component: SupremeOrderComponent,
        canActivate: [ SupremeAuthGuardService ]
      },
      {
        path: 'supreme/orders',
        component: SupremeOrdersComponent,
        canActivate: [ SupremeAuthGuardService ]
      },

      // Supreme Refunds
      {
        path: 'supreme/refunds/:id',
        component: SupremeStoreRefundComponent,
        canActivate: [ SupremeAuthGuardService ]
      },
      {
        path: 'supreme/refunds',
        component: SupremeStoreRefundsComponent,
        canActivate: [ SupremeAuthGuardService ]
      },



      // Supreme users
      {
        path: 'supreme/users/new',
        component: SupremeUserComponent,
        canActivate: [ SupremeAuthGuardService ]
      },
      {
        path: 'supreme/users/:id',
        component: SupremeUserComponent,
        canActivate: [ SupremeAuthGuardService ]
      },
      {
        path: 'supreme/users',
        component: SupremeUsersComponent,
        canActivate: [ SupremeAuthGuardService ]
      }

    ])
  ],

  declarations: [
    SupremeDasboardComponent,    
    SupremeOrdersComponent,
    SupremeUsersComponent,   
    SupremeOrderComponent,
    SupremeUserComponent,
    SupremeStoreRefundComponent,
    SupremeStoreRefundsComponent,

  ],

  providers: [
    SupremeAuthGuardService,
    StripeAccountEventService,
    StripeConnectEventService
  ]
})
export class SupremeModule { }
