
<mat-card>

  <img
    #img
    mat-card-image
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [src]="'https://dabfare.com/images/'+image"
    alt="Supporting image">
</mat-card>
