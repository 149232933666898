import 'rxjs/add/operator/take';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';
import { Service } from 'shared/models/service';
import { ServiceService } from 'shared/services/service.service';
import { UploadService } from 'shared/services/upload.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';



@Component({
  selector: 'service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.css']
})

export class ServiceFormComponent implements OnInit {

  @Input('service') service: Service;
  uploads$: Observable<any>;

  id: string;

  myStore: any = {};
  myStoreSubscription: Subscription;
  
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private serviceService: ServiceService,
    private uploadService: UploadService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {
          
    this.uploads$ = (await this.uploadService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )

  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.serviceService.remove(this.id);
        this.router.navigate(['/admin/services']);
      });
  }

  pushImage(image) {

    if (!this.service.images) this.service.images = [];
    this.service.images.push(image);
  }

  spliceImage(image) {

    let index: number = this.service.images.indexOf(image);
    this.service.images.splice(index, 1);
  }

  save(resource) {
    if (this.service.images) resource['images'] = this.service.images;
    if (this.id) this.serviceService.patch(this.id, resource);
    else this.serviceService.create(this.myStore._id, resource);

    this.router.navigate(['/admin/services']);
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
