import { Component, Input, OnInit } from '@angular/core';
import { User } from 'shared/models/user';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})

export class UserCardComponent implements OnInit {

  @Input('user') user: User;

  ngOnInit() {
    let img = document.getElementById("mat-card-image");
    img.hidden = true;

    img.onload = function() {

      img.hidden = false;

    };
  }
}
