
<mat-card *ngIf="theme">

  <mat-card-content>

    <p *ngIf="theme.primary">
      Primary: {{ theme.primary }}
    </p>
    <p *ngIf="theme.accent">
      Accent: {{ theme.accent }}
    </p>
    <p *ngIf="theme.warn">
      Warn: {{ theme.warn }}
    </p>
  </mat-card-content>
</mat-card>
