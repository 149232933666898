  
<div
  @todosAnimation
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">
    
  <h1>Todos</h1>
  
  <mat-form-field>
    <input
      matInput
      #itemInput
      placeholder="Things I need to do."
      (keyup.enter)="addTodo(itemInput)">
  </mat-form-field>

  <mat-list>
    <mat-list-item
      @todoAnimation
      *ngFor="let todo of todos | async">
      
      <span (click)="toggleTodo(todo)" [class.completed]="todo.isCompleted">
        {{ todo.text }}
      </span>
      
      <button 
        mat-raised-button
        color="accent"
        type="button"
        (click)="removeTodo(todo)">
        Remove
      </button>
    </mat-list-item>
  </mat-list>


</div>