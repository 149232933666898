
<nav mat-tab-nav-bar>

  <a
    mat-tab-link
    [queryParams]=""         
    [routerLink]="(path | async) ? ['/', (path | async), 'portfolio'] : ['/portfolio']" 
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    All Categories
  </a>

  <a
    mat-tab-link
    *ngFor="let c of categories$ | async"
    [queryParams]="{ category: c.text }"     
    [routerLink]="(path | async) ? ['/', (path | async), 'portfolio'] : ['/portfolio']" 
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    {{ c.text }}
  </a>

</nav>