<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">

  <mat-form-field>
  <input
    matInput
    #title="ngModel"
    [(ngModel)]="store.title"
    name="title"
    id="title"
    type="text"
    placeholder="Title"
    required    
    autofocus>
    <mat-error *ngIf="title.touched && title.invalid">
      Title is required.
    </mat-error>
  </mat-form-field>
  
  <mat-form-field>
    <input
      matInput
      #subtitle="ngModel"
      [(ngModel)]="store.subtitle"
      name="subtitle"
      id="subtitle"
      type="text"
      placeholder="Subtitle">
    <mat-error *ngIf="subtitle.touched && subtitle.invalid">
      Subtitle is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      #path="ngModel"
      [(ngModel)]="store.path"
      name="path"
      id="path"
      type="text"
      placeholder="Path"
      required>
    <mat-error *ngIf="path.touched && path.invalid">
      Path is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <textarea 
      matInput
      #mission="ngModel"
      [(ngModel)]="store.mission"
      name="mission"
      id="mission"
      type="text"
      placeholder="mission">
    </textarea>
  </mat-form-field>
  
  <mat-form-field>
    <mat-select
      #favicon="ngModel"
      [(ngModel)]="store.favicon"
      name="favicon"
      id="favicon"
      placeholder="Favicon">
      <mat-option>None</mat-option>
      <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl">
        {{ upload.originalname }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="favicon.touched && favicon.invalid">
      Favicon is required.
    </mat-error>
  </mat-form-field>
  
  <mat-form-field>
    <mat-select
      #imageUrl="ngModel"
      [(ngModel)]="store.imageUrl"
      name="imageUrl"
      id="imageUrl"
      placeholder="Store Image">
      <mat-option>None</mat-option>
      <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl">
        {{ upload.originalname }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
      Image is required.
    </mat-error>
  </mat-form-field>

  <p>Select content ready to display.</p>
  <p>Checked content will be made public.</p>

    <mat-checkbox
    [(ngModel)]="store.service"
    #service="ngModel"
    class="form-control"
    name="service"
    id="service"
    [disabled]=allowDisplayContent>
    Service
  </mat-checkbox>

  <mat-checkbox
    [(ngModel)]="store.product"
    #product="ngModel"
    class="form-control"
    name="product"
    id="product"
    [disabled]=allowDisplayContent>
    Product
  </mat-checkbox>

  <mat-checkbox
    [(ngModel)]="store.portfolio"
    #portfolio="ngModel"
    class="form-control"
    name="portfolio"
    id="portfolio"
    [disabled]=allowDisplayContent>
    Portfolio
  </mat-checkbox>

  <mat-checkbox
    [(ngModel)]="store.blog"
    #blog="ngModel"
    class="form-control"
    name="blog"
    id="blog"
    [disabled]=allowDisplayContent>
    Blog
  </mat-checkbox>


  <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="f.value=''">Clear</button>    

</form>