import { Component, OnInit } from '@angular/core';
import { StoreService } from 'shared/services/store.service';
import { Observable } from 'rxjs/Observable';
import { slide, titleAnimation } from 'app/animations';



import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css'],
  animations: [ slide, titleAnimation ]
})
export class StoresComponent implements OnInit {

  stores$: Observable<any>;

  constructor(private storeService: StoreService) { }

  async ngOnInit() {

    this.stores$ = (await this.storeService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

}
