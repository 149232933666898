import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'shared/services/user.service';
import 'rxjs/add/operator/take';

@Component({
  selector: 'supreme-user',
  templateUrl: './supreme-user.component.html',
  styleUrls: ['./supreme-user.component.css']
})

export class SupremeUserComponent implements OnInit {

  user: any = {};
  id: string;

  constructor(
    private route: ActivatedRoute,
    private service: UserService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => this.user = res);
  }

}
