
<store-nav></store-nav>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">

  <div @titleAnimation fxFlex="nogrow">
    <h1>All Stores</h1>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px">

    <div
      @slide
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let store of stores$ | async">

      <store-card [store]="store"></store-card>    
    </div>
  </div>
</div>

