import { Component, OnInit } from '@angular/core';
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { select } from '@angular-redux/store';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})

export class ShoppingCartComponent implements OnInit {

  @select() cartItemsCount;
  @select() amount;  
  @select() storeCarts;
  

  constructor(private cartItemService: ShoppingCartItemService) { }

  async ngOnInit() {}

  clearCart() {

    this.cartItemService.clearCart();
  }

}
