import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs/Subscription';
import { Plan } from 'shared/models/plan';
import { PlanService } from 'shared/services/plan.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';




@Component({
  selector: 'admin-plans',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.css']
})

export class AdminPlansComponent implements OnInit, OnDestroy {
  plans: Plan[] = [];
  myStore: any = {};

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = ['name', 'currency', 'amount', 'interval', 'trial_period_days'];
  dataSource = new MatTableDataSource<Plan>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private service: PlanService,
    private ngRedux: NgRedux<IAppState>) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {

    console.log('adminProducts FindResources')
    this.subscription = (await this.service.find(this.myStore._id)).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.plans = res;
        this.dataSource = new MatTableDataSource<Plan>(this.plans);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }

}
