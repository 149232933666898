<p>
  <a mat-raised-button color="primary" [routerLink]="['/admin/portfolio/new']">New Portfolio</a>
  <a mat-raised-button color="primary" [routerLink]="['/admin/categories']">Manage Categories</a>
</p>

<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.title }} </mat-cell>
    </ng-container>

    <!-- Abstract Column -->
    <ng-container matColumnDef="abstract">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Abstract </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.abstract }} </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <a [routerLink]="['/admin/portfolio', row._id]">Edit</a>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  
  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>