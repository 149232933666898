import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreOrderService } from 'shared/services/store-order.service';
import { StoreOrder } from 'shared/models/store-order';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/take';
import { StoreRefundService } from 'shared/services/store-refund.service';

@Component({
  selector: 'my-store-order',
  templateUrl: './my-store-order.component.html',
  styleUrls: ['./my-store-order.component.css']
})

export class MyStoreOrderComponent implements OnInit {

  id: string;
  storeOrder: StoreOrder = <StoreOrder>{};
  
  showStoreItems: boolean = false;
  selectRefundItems: boolean = false;

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private storeOrderService: StoreOrderService,
    private storeRefundService: StoreRefundService) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    await this.populateResources();
  }

  async populateResources() {
    this.subscription = (await this.storeOrderService.get(this.id))
      .subscribe( res => {
        this.storeOrder = res;
      })
  }

  changeSelectedItems(refundItems) {
    // logic here is reversed, otherwise it will not work.
    console.log('refundItems', refundItems);
    if(!refundItems.checked) this.storeRefundService.selectedRefund(refundItems.value);

    else this.storeRefundService.deselectedRefund(refundItems.value);
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
