import { Component, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs/Subscription';

import { Blog } from 'shared/models/blog';
import { BlogService } from 'shared/services/blog.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'admin-blogs',
  templateUrl: './admin-blogs.component.html',
  styleUrls: ['./admin-blogs.component.css']
})

export class AdminBlogsComponent implements AfterViewInit, OnDestroy {
  blogs: Blog[];

  myStore: any = {};

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns: string[] = ['title', 'abstract', 'edit'];
  dataSource: MatTableDataSource<Blog>;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;

  
  constructor(
    private service: BlogService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngAfterViewInit() {


    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        console.log('Found My Store: ', this.myStore);
        this.findResources();
      });
  }

  private async findResources() {

    this.subscription = (await this.service.findDataTable(
      this.myStore._id, this.sort.active, this.sort.direction, this.paginator.pageIndex
      ))
        .map( (res: Paginated<any>) => {
  
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = res['total'];

          console.log('Found Resources: ', res);

          console.log('raw data', res);
          return res['data'];
        })
        .subscribe( res => {
          
          // Assign the data to the data source for the table to render

          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}
