<form class="standard-form" #f="ngForm" (ngSubmit)="refundItemReady(f.value)" disabled="submitted">

  <mat-form-field>
    <input 
      matInput
      #imageUrl="ngModel"
      [(ngModel)]="refundItem.imageUrl"
      name="imageUrl"
      id="imageUrl"
      type="text"
      placeholder="Image URL"
      autofocus
      required
      url>
    <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
      Image URL is required.
    </mat-error>
  </mat-form-field>


  <mat-form-field>
    <mat-select
      #reason="ngModel"
      [(ngModel)]="refundItem.reason"
      name="reason"
      id="reason"
      placeholder="Reason"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let reason of reasons" [value]="reason">
        {{ reason }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="reason.touched && reason.invalid">
      Reason is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <textarea 
      matInput
      matTextareaAutosize
      matAutosizeMinRows="2"
      matAutosizeMaxRows="20"
      #statement="ngModel"
      [(ngModel)]="refundItem.statement"
      name="statement"
      id="statement"
      type="text"
      placeholder="Statement"
      required></textarea>
    <mat-error *ngIf="statement.touched && statement.invalid">
      Statement is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      #returnQuantity="ngModel"
      [(ngModel)]="refundItem.returnQuantity"
      name="returnQuantity"
      id="returnQuantity"
      type="number"
      [min]="1"
      [max]="refundItem.quantity"
      placeholder="Return Quantity"
      required>
    <mat-error *ngIf="returnQuantity.touched && returnQuantity.invalid">
      <mat-error *ngIf="returnQuantity.errors.required">Return quantity is required.</mat-error>
      <mat-error *ngIf="returnQuantity.errors.min">Cannot return 0 items.</mat-error>
      <mat-error *ngIf="returnQuantity.errors.max">Cannot exceed purchase quantity of {{ returnItem.quantity }}.</mat-error>      
    </mat-error>
  </mat-form-field>


  <button mat-raised-button color="primary" type="submit" [disabled]="submitted">Done</button>
  
</form>