import { Component, OnInit } from '@angular/core';
import { StoreService } from 'shared/services/store.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from 'shared/models/store';

@Component({
  selector: 'my-store',
  templateUrl: './my-store.component.html',
  styleUrls: ['./my-store.component.css']
})

export class MyStoreComponent implements OnInit {
  store: Store = <Store>{};
  id: string;

  constructor(
    private route: ActivatedRoute,
    private service: StoreService) {}

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        this.store = res;
      });
  }
}

