
<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px"
  *ngIf="order">

  <div fxFlex="nogrow" *ngIf="order.address">

    <address-card [address]="order.address"></address-card>

  </div>

  <div fxFlex="nogrow">

    <mat-card *ngIf="order.amount && order.cart">
      <mat-card-content>

        <mat-card-title>Payment Details</mat-card-title>
       
        <p>
          Total Quantity: {{ order.cart.totalQuantity }}
        </p>
        <p>
          Total Price: {{ order.cart.totalPrice }}
        </p>
        <p>
          Transfer Amount: {{ order.amount }}
        </p>
        <p>
          Date Placed: {{ order.createdAt }}
        </p>
        <p>
          Charge ID: {{ order.chargeId }}
        </p>
        <p>
          transfer ID: {{ order.transferId }}
        </p>
        <p>
          Order Status: {{ order.status }}
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  
  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="40px"
    fxLayoutGap.xs="20px"
    *ngIf="order.cart && order.cart.items">

    <div 
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let item of order.cart.items">

      <p>
        Purchase Quantity: {{ item.totalQuantity }}
      </p>
    
      <product-card [product]="item.product" [show-actions]="fase"></product-card>
    </div>
  </div>


  <div fxFlex="nogrow">
    <p>Status: {{ order.status }}</p>
  </div>

  <div 
    fxFlex.xl="58"
    fxFlex.lg="68"
    fxFlex.md="78"
    fxFlex.sm="88"
    fxFlex.xs="98"
    *ngIf="order && order.status">
  
    <form
      class="order-shipment-form"
      #f="ngForm"
      (ngSubmit)="orderShipping(f.value)">

      <mat-form-field>
        <mat-select
          #carrier="ngModel"
          [(ngModel)]="order.carrier"
          name="carrier"
          id="carrier"
          placeholder="Carrier"
          required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let carrier of carriers" [value]="carrier">
            {{ carrier }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="carrier.touched && carrier.invalid">
            Carrier is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput
          #tracking="ngModel"
          [(ngModel)]="order.tracking"
          name="tracking"
          id="tracking"
          type="text"
          placeholder="Tracking"
          required
          autofocus>
        <mat-error *ngIf="tracking.touched && tracking.invalid">
          Tracking is required.
        </mat-error>
      </mat-form-field>  

      <button mat-raised-button color="primary" type="submit">Submit Shipping Details</button>
    </form>
  </div>
</div>