
import { select } from '@angular-redux/store';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stripe-connect-express',
  templateUrl: './stripe-connect-express.component.html',
  styleUrls: ['./stripe-connect-express.component.css']
})

export class StripeConnectExpressComponent {

  @Input('clientId') clientId: string;
  @Input('redirectUrl') redirectUrl: string;

  @select() user;
  @select() myStore;

}
