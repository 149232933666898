
<mat-card *ngIf="address">

  <mat-card-header>
    <mat-card-title *ngIf="address.name">{{ address.name }}</mat-card-title>
    <mat-card-subtitle *ngIf="address.phone">{{ address.phone }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <p *ngIf="address.line1">{{ address.line1 }}</p>
    <p *ngIf="address.line2">{{ address.line2 }}</p>
    <p>{{ address.city }}, {{ address.state }} {{ address.postal_code }}</p>
  </mat-card-content>
</mat-card>