<a mat-raised-button matTooltip="Return" aria-label="Back arrow icon link" [routerLink]="['/admin/dashboard']">
  <mat-icon color="accent">arrow_back</mat-icon>
</a>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">
  
  <div fxFlex="40">
    
    <theme-form [theme]="theme"></theme-form>
  </div>

  
</div>