import { Injectable } from '@angular/core';
import { AuthService } from 'shared/services/auth.service';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class SupremeAuthGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router) { }

  async canActivate() {

    let user = await this.auth.authUser();

    if (user && user.admin && user.supreme) return true;

    else {
      this.router.navigate(['/no-access']);
      return false;
    }
  }
}
