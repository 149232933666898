import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.css']
})
export class MyAddressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
