
<mat-toolbar color="primary">
  
  <div *ngIf="store && store.favicon; else noFavicon">
    <a
      mat-icon-button
      matTooltip="Navigate Home"
      aria-label="Navigate Home Icon"
      [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
      <img [src]="'https://dabfare.com/images/'+store.favicon" alt="{{ store.title }}">
    </a>
  </div>

  <ng-template #noFavicon>
    <a
      mat-icon-button
      matTooltip="Navigate Home"
      aria-label="Navigate Home Icon"
      [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
      <img src="/favicon.ico" alt="{{ store.title }}">
    </a>
  </ng-template>

  <a 
    mat-button
    matTooltip="Shopping Cart"
    aria-label="Shopping Cart Icon"
    [routerLink]="(path | async) ? ['/store', (path | async), 'shopping-cart'] : ['/shopping-cart']">
    <mat-icon color="accent">shopping_cart</mat-icon>
    <span>
      {{ cartItemsCount | async }}
    </span>
  </a>

  <div class="fill-remaining-space"></div>

  <supreme-menu [user]="user"></supreme-menu>

  <admin-menu [user]="user" [myStore]="myStore"></admin-menu>

  <user-menu [user]="user"></user-menu>

  <app-menu [store]="store"></app-menu>

</mat-toolbar>
