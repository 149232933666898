
<mat-card *ngIf="upload">
  <img
    #img
    mat-card-image
    *ngIf="upload && upload.imageUrl"
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [src]="'https://dabfare.com/images/'+upload.imageUrl"
    alt="{{ upload.originalname }}">

  <mat-card-content>

    <mat-card-title *ngIf="upload.originalname">{{ upload.originalname }}</mat-card-title>
    <mat-card-subtitle *ngIf="upload.type">{{ upload.type }}</mat-card-subtitle>

    <p *ngIf="upload.fieldname">
      Fieldname: {{ upload.fieldname }}
    </p>
    <p *ngIf="upload.mimetype">
      Mimetype: {{ upload.mimetype }}
    </p>
    <p *ngIf="upload.size">
      Size: {{ upload.size }}
    </p>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="warn" type="button" type="button" (click)="delete()">Delete</button>
  </mat-card-actions>
</mat-card>
