import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  servicePath: string = 'pages';

  storeId: any = '';
  myStore: any = {};

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {
      
    (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.myStore = res;
      });

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }

  find(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId, resource: any) {

    resource['storeId'] = this.storeId;
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove(resourceId);
  }
}
