import { Component } from '@angular/core';
import { select } from '@angular-redux/store';

@Component({
  selector: 'store-nav',
  templateUrl: './store-nav.component.html',
  styleUrls: ['./store-nav.component.css']
})
export class StoreNavComponent {

  @select() site;

}
