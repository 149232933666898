
<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div fxFlex="40">
  
    <store-form></store-form>
  </div>

  <div fxFlex="40">

    <store-card></store-card>
  </div>
</div>


