import { Component, OnInit } from '@angular/core';
import { TodoService } from 'shared/services/todo.service';

import { todosAnimation, todoAnimation } from '../my-todos/my-todos.component.animations';

import { NgRedux, select } from '@angular-redux/store';
import { ADD_TODO, TOGGLE_TODO, REMOVE_TODO } from 'app/actions';
import { IAppState } from 'app/store';

@Component({
  selector: 'my-todo-list',
  templateUrl: './my-todo-list.component.html',
  styleUrls: ['./my-todo-list.component.css'],
  animations: [ todosAnimation, todoAnimation ]
})

export class MyTodoListComponent implements OnInit {
  @select() todos;

  constructor(
    private service: TodoService,
    private ngRedux: NgRedux<IAppState>) {}

  ngOnInit() {
    this.service.loadResources();
  }

  addTodo(input) {
    if (!input.value) return;

    let todo = { title: input.value };

    this.service.create(input.value)
      .then( res => {
        this.ngRedux.dispatch({ type: ADD_TODO });        
      })
      .catch( err => {
        console.log('add todo error', err);
      });

    input.value = '';
  }

  toggleTodo(todo) {

    let resource = { isCompleted: !todo.isCompleted };

    this.service.patch(todo._id, resource)
      .then( res => {
        this.ngRedux.dispatch({ type: TOGGLE_TODO, _id: todo._id });        
      })
      .catch( err => {
        console.log('Toggle todo error', err);
      });
  }

  removeTodo(todo) {

    this.service.remove(todo._id)
      .then( () => {
        this.ngRedux.dispatch({ type: REMOVE_TODO, _id: todo._id });
      })
      .catch( err => {
        console.log('Remove todo error', err);
      });
  }
}
