import { Injectable } from '@angular/core';

import * as feathersRx from 'feathers-reactive';
import * as io from 'socket.io-client';


import feathers from '@feathersjs/feathers';
import feathersSocketIOClient from '@feathersjs/socketio-client';
import feathersAuthClient from '@feathersjs/authentication-client';


@Injectable()
export class Feathers {
  public client = feathers();                         // init socket.io
  private _socket = io('https://dabfare.com');      // init feathers


  constructor() {
    this.client
      .configure(feathersSocketIOClient(this._socket))  // add socket.io plugin
      .configure(feathersAuthClient({                   // add authentication plugin
        storage: window.localStorage
      }))
      .configure(feathersRx({                           // add feathers-reactive plugin
        idField: '_id'
      }));
  }

  // expose services
  public service(name: string): any {
    return this.client.service(name);
  }

  // expose authentication
  public authenticate(credentials?): Promise<any> {
    return this.client.authenticate(credentials);
  }

  // expose logout
  public logout() {
    return this.client.logout();
  }
}
