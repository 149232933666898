<mat-card *ngIf="plan" class="subscription-plan-card">
  
  <mat-card-title>Subscription Plan</mat-card-title>

  <mat-card-content>
    
    <p>ID: {{ plan._id }}</p>
    
    <p>Name: {{ plan.name }}</p>

    <p>Currency: {{ plan.currency }}</p>

    <p>Amount: {{ plan.amount | currency:'USD':symbol }}</p>

    <p>Interval: {{ plan.interval }}</p>

    <p>Trial Period Days: {{ plan.trialPeriodDays }}</p>

    <p>Statement desc: {{ plan.statementDesc }}</p>

  </mat-card-content>
</mat-card>