
import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';
import { MessageService } from 'shared/services/message.service';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';



@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  messages$: Observable<any>;
  store: any;
  user: User;

  storeSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private service: MessageService,
    private ngRedux: NgRedux<IAppState>) { }

  async ngOnInit() {
    this.user = await this.authService.authUser();

    await this.populateStore();
  }

  private async populateStore() {
    this.storeSubscription = (await this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;
        if (this.store) {
          this.findResources();
        }
      });
  }

  findResources() {
    this.messages$ = this.service.findByStoreThread(this.store._id, this.user._id).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  send(text) {

    let message = {
      storeId: this.store._id,
      userId: this.user._id,
      toId: this.store._id,
      fromId: this.user._id,
      text: text
    }
    this.service.create(message);
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
