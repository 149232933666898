import { Component } from '@angular/core';

@Component({
  selector: 'my-todos',
  templateUrl: './my-todos.component.html',
  styleUrls: ['./my-todos.component.css'],
})

export class MyTodosComponent {

  constructor() {}


}
