
<store-nav></store-nav>

<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
  <mat-icon color="accent" matTooltip="Back">arrow_back</mat-icon>
</button>

<div
  @titleAnimation
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <h1 fxFlex="nogrow">Services</h1>
</div>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px"
  *ngIf="services$ | async as services">  

  <div
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let service of services">
  
  <service-card [service]="service"></service-card>    
  
  </div>
</div>