import { animate, animation, state, style, transition, trigger, keyframes, useAnimation, group, query, stagger, animateChild } from '@angular/animations';


export let bounceOutLeftAnimation = animation(
  animate('0.5s ease-out', keyframes([
    style({ 
      offset: 0.2, 
      opacity: 1,
      transform: 'translateX(20px)'
    }),
    style({ 
      offset: 1, 
      opacity: 0,
      transform: 'translateX(-100%)'
    })
  ]))
);


export const titleAnimation = trigger('titleAnimation', [
  transition(':enter', [
    group([
      query('h1', [
        style({ transform: 'translateY(-100vh )' }),
        animate(1000)
      ], 
        { optional: true }
      )
    ])
  ])
])

export let slide = trigger('slide', [
  transition(':enter', [
    style({ transform: 'translateX(100vw)' }),
    animate(333)
  ]),
  transition(':leave',
    animate(333, style({ transform: 'translateX(-100vw)' }))
  )
])

export let fadeInAnimation = animation([
  style({ opacity: 0 }),
  animate('{{ duration }} {{ easing }}')
], {
  params: {
    duration: '10s',
    easing: 'ease-out'
  }
});

export let fadeOutAnimation = animation([
  animate(2000, style({ opacity: 0 }))  
])


export let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
    
  transition(':enter',
    useAnimation(fadeInAnimation)
  ),
  transition(':leave', 
    useAnimation(fadeOutAnimation)
  )
]);


// Images fade in after loading
export const fadeImg = trigger('fadeImg', [
  state('void', style({ opacity: 0 })),
    
  transition(':enter',
  animate('5s ease-out', style({ opacity: 1 })),
  ),
  transition(':leave',
    animate('5s ease-out', style({ opacity: 0 }))
  )
]);


// Router Animations
export const slideInAnimation =
  trigger('routeAnimations', [

    

    // changing from home page
    transition('HomePage => *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ], 
        { optional: true }
      ),
      query(':enter', [
        style({ right: '-100%'})
      ], 
        { optional: true }
      ),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ right: '100%'}))
        ], 
        { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ right: '0%'}))
        ], 
        { optional: true })
      ]),
      query(':enter', animateChild(), 
      { optional: true })
    ]),

    // changing to home page
    transition('* => HomePage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }),
      query(':enter', [
        style({ left: '-100%'})
      ],
      { optional: true }),
      query(':leave', animateChild(),
      { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%'}))
        ],
        { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ],
        { optional: true })
      ]),
      query(':enter', animateChild()),
    ]),

    // changing pages
    transition('BlogsPaqe <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ], 
      { optional: true }),
      query(':leave', animateChild(),
      { optional: true }),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%'}))
        ], 
        { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ], 
        { optional: true })
      ]),
      query(':enter', animateChild()),
    ])
  ]);