
<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async), 'portfolio'] : ['/portfolio']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px"
  *ngIf="portfolio">

  <div @titleAnimation fxFlex="nogrow">
    <h1>Portfolio Item</h1>
  </div>


  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="porftolio">

    <div
      fxFlex.xl="58"
      fxFlex.lg="68"
      fxFlex.md="78"
      fxFlex.sm="88"
      fxFlex.xs="98"
      *ngIf="porftolio.imageUrl">

      <image-card [image]="portfolio.imageUrl"></image-card>
    </div>
  </div>

  <div @slide fxFlex="nogrow">

    <h1 *ngIf="portfolio.title">{{ portfolio.title }}</h1>
  </div>
  <div @slide fxFlex="nogrow">

    <h3 *ngIf="portfolio.subtitle">{{ portfolio.subtitle }}</h3>

  </div>
  <div @slide fxFlex="nogrow">

    <p *ngIf="portfolio.category">
      {{ portfolio.category }}
    </p>
  </div>
  <div @slide fxFlex="nogrow">

    <p>{{ portfolio.abstract }}</p>
  </div>
  <div @slide fxFlex="nogrow">

    <p *ngIf="portfolio.entry">
      {{ portfolio.entry }}
    </p>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="portfolio.images">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let image of portfolio.images">

      <image-card [image]="image"></image-card>

    </div>
  </div>
</div>