import 'rxjs/add/operator/take';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from 'shared/models/service';
import { ServiceService } from 'shared/services/service.service';
import { titleAnimation, slide } from 'app/animations';

@Component({
  selector: 'admin-service',
  templateUrl: './admin-service.component.html',
  styleUrls: ['./admin-service.component.css'],
  animations: [ titleAnimation, slide ]
})

export class AdminServiceComponent implements OnInit {
  service: Service = <Service>{};
  id: string;
  
  constructor(
    private route: ActivatedRoute,    
    private serviceService: ServiceService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.serviceService.get(this.id))
      .take(1)
      .subscribe( s => this.service = s);
  }
}
