import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { ProductComponent } from './components/product/product.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductFilterComponent } from 'store/product/components/product-filter/product-filter.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // store product pages
      { path: 'store/:store/products/:id', component: ProductComponent, data: { animation: 'ProductPage' } },
      { path: 'products/:id', component: ProductComponent, data: { animation: 'ProductPage' } },

      { path: 'store/:store/products', component: ProductsComponent, data: { animation: 'ProductsPage' } },
      { path: 'products', component: ProductsComponent, data: { animation: 'ProductsPage' } }

    ])
  ],
  declarations: [
    ProductComponent,
    ProductsComponent,
    ProductFilterComponent
  ]
})
export class ProductModule { }
