import 'rxjs/add/operator/take';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';
import { StoreOrderService } from 'shared/services/store-order.service';
import { StoreRefundService } from 'shared/services/store-refund.service';

@Component({
  selector: 'refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})

export class RefundComponent implements OnInit {

  id: string;
  user: User;
  selectedRefunds: any[] = [];
  storeOrder: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private storeOrderService: StoreOrderService,
    private storeRefundService: StoreRefundService) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    this.user = await this.auth.authUser();

    (await this.storeOrderService.get(this.id))
      .take(1)
      .subscribe( res => {
        this.storeOrder = res;
      });

    this.selectedRefunds = await this.storeRefundService.getSelectedItems();
  }

  submitRefundRequest() {

    this.storeRefundService.placeRefundOrder(this.user._id, this.storeOrder.storeId, this.id);
  }

}
