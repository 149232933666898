import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { ShoppingCartService } from 'shared/services/shopping-cart.service';
import { ShoppingCartItem } from 'shared/models/shopping-cart-item';
import { Product } from 'shared/models/product';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { FETCH_CART_ITEMS_SUCCESS, FETCH_CART_ITEMS_REQUEST, FETCH_CART_ITEMS_COUNT_SUCCESS, FETCH_CART_ITEMS_AMOUNT_SUCCESS, FETCH_STORE_CARTS_SUCCESS } from 'app/actions';

import 'rxjs/add/operator/take';

import { map } from 'rxjs/operators';
import { Paginated } from '@feathersjs/feathers';

@Injectable()
export class ShoppingCartItemService {
  servicePath: string = 'shopping-cart-items';

  // fetching cart items
  cartItems: ShoppingCartItem[];
  cartItemsCount: number;
  
  // populating store carts
  stores: any[] = [];
  storeCarts: any[] = [];
  storeCartItems: any[];

  storeTotalQuantity: number;
  storeTotalPrice: number;

  // Checking inventory
  messages: string[] = [];  
  item: ShoppingCartItem;
  stock: number;
  quantity: number;

  amount: number;

  constructor(
    private feathers: Feathers,
    private cartService: ShoppingCartService,
    private ngRedux: NgRedux<IAppState>) {}


  async loadResources() {
    this.ngRedux.dispatch({ type: FETCH_CART_ITEMS_REQUEST });
    
    (await this.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.cartItems = res;
        console.log('cartItems[]', this.cartItems);
        
        this.checkInventory();
        this.getItemsCount();
        this.populateStores();
        this.populateStoreCarts();


        this.ngRedux.dispatch({ type: FETCH_CART_ITEMS_SUCCESS,
          cartItems: this.cartItems
        });
      });
  }



  private checkInventory() {
    for (let index in this.cartItems) {
      this.item = this.cartItems[index];
      this.stock = this.item.product.stock;
      this.quantity = this.item.quantity;
      if (this.stock < this.quantity) {
        let result = this.patch(this.item._id, { quantity: this.stock });
        let message = 'Not enough product in stock. Item quantity reduced in your shopping cart';
        this.messages.push(message);
      }
    }
  }

  private getItemsCount() {

    this.cartItemsCount = 0;
    this.amount = 0;

    for (let index in this.cartItems) {
      let item = this.cartItems[index];
      this.cartItemsCount += item.quantity;
      this.amount += (item.quantity*item.product.price);

    }

    this.ngRedux.dispatch({ type: FETCH_CART_ITEMS_COUNT_SUCCESS,
      cartItemsCount: this.cartItemsCount
    });
    this.ngRedux.dispatch({ type: FETCH_CART_ITEMS_AMOUNT_SUCCESS,
      amount: this.amount
    });
  }

  private populateStores() {

    this.stores = [];

    for (let i in this.cartItems) {

      let index: number = this.storeFilter(this.cartItems[i].storeId);
      if (index < 0) {
        this.stores.push(this.cartItems[i].store);
      }
    }
  }

  private populateStoreCarts() {

    this.storeCarts = [];

    for (let i in this.stores) {

      this.storeCartItemsFilter(this.stores[i]._id);

      this.storeTotalQuantity = 0;
      this.storeTotalPrice = 0;

      for (let i in this.storeCartItems) {

        this.storeTotalQuantity += this.storeCartItems[i].quantity;
        console.log('storeTotalQuantity', this.storeTotalQuantity);
        
        this.storeTotalPrice += (this.storeCartItems[i].quantity)*(this.storeCartItems[i].product.price);
        console.log('storeTotalPrice', this.storeTotalPrice);
      }
      let storeCart = {
        store: this.stores[i],
        items: this.storeCartItems,
        totalQuantity: this.storeTotalQuantity,
        totalPrice: this.storeTotalPrice

      }
      this.storeCarts.push(storeCart);
    }
    console.log('store carts', this.storeCarts);


    this.ngRedux.dispatch({ type: FETCH_STORE_CARTS_SUCCESS,
      storeCarts: this.storeCarts
    });
  }

  private storeFilter(query: string) {

    let filteredItem = (query) ?
      this.stores.filter(s => s._id.includes(query)) : 
      null;
    return filteredItem ? this.stores.indexOf(filteredItem[0]) : -1;
  }

  storeCartItemsFilter(query: string) {
    this.storeCartItems = (query) ? 
    this.cartItems.filter(i => i.storeId === query) :
    this.cartItems;

    console.log('store cart items', this.storeCartItems);
  }


  async clearCart() {

    for (let index in this.cartItems) {
      let item = this.cartItems[index];
      this.remove(item._id);
    }
    return;
  }

  async addToCart(cartItem: ShoppingCartItem) {

    if (cartItem && cartItem.quantity < cartItem.product.stock) {

      let newQuantity = {
        quantity: cartItem.quantity + 1
      }
      console.log('product in stock, increasing quantity.', newQuantity);
      return await this.patch(cartItem._id, newQuantity);
    }
    if (cartItem && cartItem.quantity >= cartItem.product.stock) {

      let newQuantity = {
        quantity: cartItem.product.stock
      }
      console.log('max items in cart, fixing quantity = stock', newQuantity);
      return await this.patch(cartItem._id, newQuantity);
    }
    else return;
  }

  async removeFromCart(cartItem: ShoppingCartItem) {

    if (cartItem && cartItem.quantity > 1) {

      let newQuantity = {
        quantity: cartItem.quantity - 1
      };
      return await this.patch(cartItem._id, newQuantity);
    }
    if (cartItem && cartItem.quantity <= 1) {
      return await this.remove(cartItem._id);
    }
    else return;
  }

  async findCartItem(productId: string) {

    let cartId = await this.cartService.getOrCreateCartId();

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $limit: 1,          
          cartId: cartId,
          productId: productId
        }
      });
  }

  async find() {

    let cartId = await this.cartService.getOrCreateCartId();

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          cartId: cartId
        }
      });
  }

  
  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  async create(resource: Product) {

    let cartId = await this.cartService.getOrCreateCartId();
    
    return this.feathers
      .service(this.servicePath)
      .create({
        cartId: cartId,
        storeId: resource.storeId,
        productId: resource._id,
        quantity: 1
      });
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
