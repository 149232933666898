import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared/shared.module';

import { PortfolioComponent } from 'store/portfolio/components/portfolio/portfolio.component';
import { PortfolioItemComponent } from 'store/portfolio/components/portfolio-item/portfolio-item.component';
import { PortfolioFilterComponent } from './components/portfolio-filter/portfolio-filter.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // store portfolio pages
      { path: 'store/:store/portfolio/:id', component: PortfolioItemComponent, data: { animation: 'PortfolioPage' } },
      { path: 'portfolio/:id', component: PortfolioItemComponent, data: { animation: 'PortfolioPage' } },

      { path: 'store/:store/portfolio', component: PortfolioComponent, data: { animation: 'PortfoliosPage' } },
      { path: 'portfolio', component: PortfolioComponent, data: { animation: 'PortfoliosPage' } }
    ])
  ],
  declarations: [
    PortfolioComponent,
    PortfolioItemComponent,
    PortfolioFilterComponent
  ]
})
export class PortfolioModule { }
