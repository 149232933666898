
<upload-button></upload-button>

<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">
  
  <mat-form-field>
    <mat-select
      #productId="ngModel"
      [(ngModel)]="plan.productId"
      name="productId"
      id="productId"
      placeholder="productId"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let p of products" [value]="p._id">
        {{ p.title }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="productId.touched && productId.invalid">
      Please associate a product to this plan.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      #name="ngModel"
      [(ngModel)]="plan.name"
      name="name"
      id="name"
      type="text"
      placeholder="Name"
      required
      autofocus>
    <mat-error *ngIf="name.touched && name.invalid">
      Name is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      #currency="ngModel"
      [(ngModel)]="plan.currency"
      name="currency"
      id="currency"
      placeholder="Currency"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let c of currencyTypes" [value]="c.text">
        {{ c.text }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="currency.touched && currency.invalid">
      Currency is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input 
      matInput
      #amount="ngModel"
      [(ngModel)]="plan.amount"
      name="amount"
      id="amount"
      type="number"
      placeholder="Amount"
      required>
    <mat-error *ngIf="amount.touched && amount.invalid">
      Amount is required.
    </mat-error>
  </mat-form-field>
  
 
  <mat-form-field>
    <mat-select
      #interval="ngModel"
      [(ngModel)]="plan.interval"
      name="interval"
      id="interval"
      placeholder="Interval"
      required>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let i of intervals" [value]="i.text">
        {{ i.text }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="interval.touched && interval.invalid">
      Interval is required.
    </mat-error>
  </mat-form-field>


  
  <mat-form-field>
    <input 
      matInput
      #trial_period_days="ngModel"
      [(ngModel)]="plan.trial_period_days"
      name="trial_period_days"
      id="trial_period_days"
      type="number"
      placeholder="Trial period days"
      required
      autofocus>
    <mat-error *ngIf="trial_period_days.touched && trial_period_days.invalid">
      Please specify the number of days for trial period. Enter 0 for no trial period.
    </mat-error>
  </mat-form-field>


  <button mat-raised-button color="primary" type="submit">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="delete()">Delete</button>    
</form>
