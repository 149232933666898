
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';

import { Feathers } from 'shared/services/feathers.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCart } from 'shared/models/shopping-cart';
import { Address } from 'shared/models/address';

@Injectable()
export class OrderService {
  
  servicePath: string = 'orders';

  constructor(
    private router: Router,    
    private feathers: Feathers,
    private shoppingCartItemService: ShoppingCartItemService) { }

  async placeOrder(
    amount: number,
    address: Address,
    cart: ShoppingCart[]) {

    await this.create(amount, address, cart)
      .then( result => {
        console.log('order result: ', result);
        this.router.navigate(['/order-success', result._id]);
        
        return result;
      })
      .then( () => {
        return this.shoppingCartItemService.clearCart();
      });

  }


  findByUserId(userId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          userId: userId,
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          $sort: {
            createdAt: -1
          }
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(
    amount: number,
    address: Address,    
    cart: ShoppingCart[]): Promise<any> {
    
    return this.feathers
      .service(this.servicePath)
      .create({
        amount: amount,
        address: address,       
        cart: cart
      });
  }
  
  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
    
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
