

<mat-card *ngIf="store">
  <img
    mat-card-image
    id="mat-card-image"
    *ngIf="store.imageUrl"
    [routerLink]="store && store.path ? ['/store', store.path] : ['/']"
    [src]="'https://dabfare.com/images/'+store.imageUrl"
    alt="{{ store.title }}">
  
  <mat-card-content>

    <mat-card-title
      *ngIf="store.title"
      [routerLink]="store && store.path ? ['/store', store.path] : ['/']">
      {{ store.title }}
    </mat-card-title>

    <mat-card-subtitle *ngIf="store.subtitle">{{ store.subtitle }}</mat-card-subtitle> 

    <p>dabfare.com/store/{{ store.path }}</p>
  </mat-card-content>
</mat-card>

