
<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div
    fxFlex="40"
    fxLayoutAlign="left center">

    <my-todo-dashboard></my-todo-dashboard>
  </div>

  <div
    fxFlex="40"
    fxLayoutAlign="right center">

    <my-todo-list></my-todo-list>
  </div>
</div>