
<upload-button></upload-button>

<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">
  
  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Service Card Data
        </mat-panel-title>
        <mat-panel-description>
          Basic information about your service.
        </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-form-field>
          <input 
            matInput
            #title="ngModel"
            [(ngModel)]="service.title"
            name="title"
            id="title"
            type="text"
            placeholder="Title"
            required
            autofocus>
          <mat-error *ngIf="title.touched && title.invalid">
            Title is required.
          </mat-error>
        </mat-form-field>
      
        <mat-form-field>
          <input 
            matInput
            #subtitle="ngModel"
            [(ngModel)]="service.subtitle"
            name="subtitle"
            id="subtitle"
            type="text"
            placeholder="Subtitle">
          <mat-error *ngIf="subtitle.touched && subtitle.invalid">
            Subtitle is required.
          </mat-error>
        </mat-form-field>
           
        <mat-form-field>
          <mat-select
            #imageUrl="ngModel"
            [(ngModel)]="service.imageUrl"
            name="imageUrl"
            id="imageUrl"
            placeholder="ImageUrl"
            required>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl">
              {{ upload.originalname }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
            ImageUrl is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <textarea 
            matInput
            matTextareaAutosize
            matAutosizeMinRows="2"
            matAutosizeMaxRows="20"
            #abstract="ngModel"
            [(ngModel)]="service.abstract"
            name="abstract"
            id="abstract"
            type="text"
            placeholder="Abstract"></textarea>
          <mat-error *ngIf="abstract.touched && abstract.invalid">
            Abstract is required.
          </mat-error>
        </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Description
        </mat-panel-title>
        <mat-panel-description>
          Add a description of your service or a message to the readers
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <textarea 
          matInput
          matTextareaAutosize
          matAutosizeMinRows="5"
          matAutosizeMaxRows="50"
          #description="ngModel"
          [(ngModel)]="service.description"
          name="description"
          id="description"
          type="text"
          placeholder="Description"></textarea>
        <mat-error *ngIf="description.touched && description.invalid">
          Description is required.
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>



    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Additional Images
        </mat-panel-title>
        <mat-panel-description>
          Add or subtract Images
        </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-form-field>
        <mat-select
          name="image"
          id="image"
          placeholder="Add additional images">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl" (click)="pushImage(upload.imageUrl)">
            {{ upload.originalname }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field>
        <mat-select
          name="images"
          id="images"
          placeholder="Click to remove image">
          <mat-option *ngFor="let image of service.images" [value]="image" (click)="spliceImage(image)">
            {{ image }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>




    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Display Preferences
        </mat-panel-title>
        <mat-panel-description>
          Choose how to display your portfolio
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-checkbox
        #featured="ngModel"
        [(ngModel)]="service.featured"
        name="featured"
        id="featured"
        placeholder="Featured">Featured
      </mat-checkbox>

      <mat-checkbox
        #live="ngModel"
        [(ngModel)]="service.live"
        name="live"
        id="live"
        placeholder="Publish Live">Live
      </mat-checkbox>
    </mat-expansion-panel>


  </mat-accordion>


  <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="delete()">Delete</button>
</form>