import { Component, Input } from '@angular/core';
import { StoreShoppingCart } from 'shared/models/store-shopping-cart';

@Component({
  selector: 'store-cart-summary',
  templateUrl: './store-cart-summary.component.html',
  styleUrls: ['./store-cart-summary.component.css']
})

export class StoreCartSummaryComponent {
  @Input('store-shopping-cart') storeCart: StoreShoppingCart;
  
}
