import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs/Subscription';
import { Source } from 'shared/models/source';
import { AuthService } from 'shared/services/auth.service';
import { SourceService } from 'shared/services/source.service';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'my-sources',
  templateUrl: './my-sources.component.html',
  styleUrls: ['./my-sources.component.css']
})

export class MySourcesComponent implements OnInit, OnDestroy, AfterViewInit {

  subscription: Subscription;

  displayedColumns = ['type', 'funding', 'brand', 'last4', 'view'];
  dataSource = new MatTableDataSource<Source>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private auth: AuthService,
    private service: SourceService) {}


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    let authenticated = await this.auth.authUser();
    let userId = authenticated._id;

    this.subscription = await this.service.find(userId).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( sources => {

        this.dataSource = new MatTableDataSource<Source>(sources);        
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
