import { Component } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { CLEAR_TODOS } from 'app/actions';

@Component({
  selector: 'my-todo-dashboard',
  templateUrl: './my-todo-dashboard.component.html',
  styleUrls: ['./my-todo-dashboard.component.css']
})

export class MyTodoDashboardComponent {

  @select() todos;
  @select() lastUpdate;

  constructor(private ngRedux: NgRedux<IAppState>) {}

  clearTodos() {
    this.ngRedux.dispatch({ type: CLEAR_TODOS });
  }

}
