import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MessageService {

  servicePath: string = 'messages';

  constructor(private feathers: Feathers) { }

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  findByUserId(userId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          userId: userId
        }
      });
  }

  findByStoreId(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }

  findByStoreThread(storeId: string, userId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          userId: userId
        }
      });
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
    
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
