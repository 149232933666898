
<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async), 'services'] : ['/services']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px"
  *ngIf="service">

  <div @titleAnimation fxFlex="nogrow">
    <h1>Service</h1>
  </div>


  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="service">
  
    <div
      fxFlex.xl="58"
      fxFlex.lg="68"
      fxFlex.md="78"
      fxFlex.sm="88"
      fxFlex.xs="98"
      *ngIf="service.imageUrl">

      <image-card [image]="service.imageUrl"></image-card>
    </div>
  </div>


  <div @slide fxFlex="nogrow">

    <h1 *ngIf="service.title">{{ service.title }}</h1>
  </div>
  <div @slide fxFlex="nogrow">

    <h3 *ngIf="service.subtitle">{{ service.subtitle }}</h3>

  </div>
  <div @slide fxFlex="nogrow">

    <p>{{ service.description }}</p>
  </div>


  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="20px"
    fxLayoutGap.xs="10px"
    *ngIf="service && service.images">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let image of service.images">

      <image-card [image]="image"></image-card>

    </div>
  </div>
</div>