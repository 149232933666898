import { Component, Input } from '@angular/core';
import { select } from '@angular-redux/store';

@Component({
  selector: 'stripe-connect-standard',
  templateUrl: './stripe-connect-standard.component.html',
  styleUrls: ['./stripe-connect-standard.component.css']
})
export class StripeConnectStandardComponent {

  @Input('clientId') clientId: string;
  @Input('redirectUrl') redirectUrl: string;

  @select() myStore;
  @select() user;
}

