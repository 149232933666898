  <button
    mat-icon-button
    *ngIf="user && user.admin && myStore"
    matTooltip="Admin Menu"
    [matMenuTriggerFor]="adminMenu">

    <mat-icon>supervisor_account</mat-icon>
  </button>

  <mat-menu #adminMenu="matMenu">
    <ng-template matMenuContent>

      <a mat-menu-item [routerLink]="['/admin/dashboard']">
        <mat-icon>dashboard</mat-icon>
        Manage Dashboard
      </a>
      <a mat-menu-item [routerLink]="['/admin/orders']">
        <mat-icon>receipt</mat-icon>
        Manage Orders
      </a>
      <a mat-menu-item [routerLink]="['/admin/refunds']">
        <mat-icon>receipt</mat-icon>
        Manage Refunds
      </a>
      <a mat-menu-item [routerLink]="['/admin/messages']">
        <mat-icon>message</mat-icon>
        Manage Messages
      </a>
    
      <hr>
        
      <a mat-menu-item [routerLink]="['/admin/blog']">
        <mat-icon>speaker_notes</mat-icon>
        Manage Blog
      </a>
      <a mat-menu-item [routerLink]="['/admin/portfolio']">
        <mat-icon>photo_library</mat-icon>
        Manage Portfolio
      </a>
      <a mat-menu-item [routerLink]="['/admin/products']">
        <mat-icon>store</mat-icon>
        Manage Products
      </a>
      <a mat-menu-item [routerLink]="['/admin/plans']">
        <mat-icon>store</mat-icon>
        Manage Plans
      </a>
      <a mat-menu-item [routerLink]="['/admin/services']">
        <mat-icon>work</mat-icon>
        Manage Services
      </a>
    
      <hr>
    
      <a mat-menu-item [routerLink]="['/admin/account']">
        <mat-icon>account_balance</mat-icon>
        Manage Account
      </a>
      <a mat-menu-item [routerLink]="['/admin/links']">
        <mat-icon>link</mat-icon>
        Manage Links
      </a>
      <a mat-menu-item [routerLink]="['/admin/categories']">
        <mat-icon>store</mat-icon>
        Manage Categories
      </a>
      <a mat-menu-item [routerLink]="['/admin/uploads']">
        <mat-icon>cloud_upload</mat-icon>
        Manage Uploads
      </a>
    </ng-template>
  </mat-menu>