import { Component, Input } from '@angular/core';
import { ShoppingCart } from 'shared/models/shopping-cart';
import { select } from '@angular-redux/store';

@Component({
  selector: 'checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.css']
})

export class CheckoutSummaryComponent {

  @Input('cart-items-count') cartItemsCount: number;
  @Input('shopping-cart') cart: ShoppingCart;  
  @Input('amount') amount: number;
  
  @select() storeCarts;
}
