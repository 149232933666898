

<form class="standard-form" #f="ngForm" (ngSubmit)="save(f.value)">
  


  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Product Card Data
        </mat-panel-title>
        <mat-panel-description>
          Basic information about your product.
        </mat-panel-description>
      </mat-expansion-panel-header>
  


      <mat-form-field>
        <input 
          matInput
          #title="ngModel"
          [(ngModel)]="product.title"
          name="title"
          id="title"
          type="text"
          placeholder="Title"
          required>
        <mat-error *ngIf="title.touched && title.invalid">
          Title is required.
        </mat-error>
      </mat-form-field>
    
    
      <mat-form-field>
        <input 
          matInput
          #subtitle="ngModel"
          [(ngModel)]="product.subtitle"
          name="subtitle"
          id="subtitle"
          type="text"
          placeholder="Subtitle">
      </mat-form-field>

      <mat-form-field>
        <mat-select
          #category="ngModel"
          [(ngModel)]="product.category"
          name="category"
          id="category"
          placeholder="Category"
          required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let c of categories$ | async" [value]="c.text">
            {{ c.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="category.touched && category.invalid">
          Category is required.
        </mat-error>
      </mat-form-field>

    
      <mat-form-field>
        <mat-select
          #imageUrl="ngModel"
          [(ngModel)]="product.imageUrl"
          name="imageUrl"
          id="imageUrl"
          placeholder="ImageUrl"
          required>
          <mat-option>None</mat-option>
          <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl">
            <upload-card [upload]="upload"></upload-card>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="imageUrl.touched && imageUrl.invalid">
          ImageUrl is required.
        </mat-error>
      </mat-form-field>

    
        <mat-form-field>
          <input
            matInput
            #price="ngModel"
            [(ngModel)]="product.price"
            name="price"
            id="price"
            type="number"
            placeholder="Price"
            required
            [min]="3">
          <span matPrefix>$</span>
          <mat-error *ngIf="price.touched && price.invalid">
            <mat-error *ngIf="price.errors.required">Price is required.</mat-error>
            <mat-error *ngIf="price.errors.min">Price should be at least $3.</mat-error>
          </mat-error>
        </mat-form-field>
        
        <mat-form-field>
          <input
            matInput
            #shipping="ngModel"
            [(ngModel)]="product.shipping"
            name="shipping"
            id="shipping"
            type="number"
            placeholder="shipping"
            required>
          <span matPrefix>$</span>
          <mat-error *ngIf="shipping.touched && shipping.invalid">
            <mat-error *ngIf="shipping.errors.required">Shipping cost is required.</mat-error>
          </mat-error>
        </mat-form-field>
        
        <mat-form-field>
          <input
            matInput
            #stock="ngModel"
            [(ngModel)]="product.stock"
            name="stock"
            id="stock"
            type="number"
            placeholder="stock"
            required
            [min]="0">
          <mat-error *ngIf="stock.touched && stock.invalid">
            <mat-error *ngIf="stock.errors.required">Stock is required.</mat-error>
            <mat-error *ngIf="stock.errors.min">Stock can't be less than 0.</mat-error>
          </mat-error>
        </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Description
        </mat-panel-title>
        <mat-panel-description>
          Add your product description or message to the readers
        </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-form-field>
        <textarea
          matInput
          matTextareaAutosize
          matAutosizeMinRows="2"
          matAutosizeMaxRows="20"
          #description="ngModel"
          [(ngModel)]="product.description"
          name="description"
          id="description"
          type="text"
          placeholder="Description"
          required></textarea>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Additional Images
        </mat-panel-title>
        <mat-panel-description>
          Add and subtract images.
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-select
          name="image"
          id="image"
          placeholder="Add additional images">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let upload of uploads$ | async" [value]="upload.imageUrl" (click)="pushImage(upload.imageUrl)">
            <image-card [image]="upload.imageUrl"></image-card>
          </mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field>
        <mat-select
          name="images"
          id="images"
          placeholder="Click to remove image">
          <mat-option *ngFor="let image of product.images" [value]="image" (click)="spliceImage(image)">
            <upload-card [upload]="image"></upload-card>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>



    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Technical Details
        </mat-panel-title>
        <mat-panel-description>
          Technical details about your product.
        </mat-panel-description>
      </mat-expansion-panel-header>


      <mat-form-field>
        <input
          matInput
          #brand="ngModel"
          [(ngModel)]="product.brand"
          name="brand"
          id="brand"
          type="text"
          placeholder="Brand Name">
        <mat-error *ngIf="brand.touched && brand.invalid">
          <mat-error *ngIf="brand.errors.required">Brand name is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #series="ngModel"
          [(ngModel)]="product.series"
          name="series"
          id="series"
          type="text"
          placeholder="Series">
        <mat-error *ngIf="series.touched && series.invalid">
          <mat-error *ngIf="series.errors.required">Product series is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #model="ngModel"
          [(ngModel)]="product.model"
          name="model"
          id="model"
          type="text"
          placeholder="Model version or number">
        <mat-error *ngIf="model.touched && model.invalid">
          <mat-error *ngIf="model.errors.required">Model version or number is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #weight="ngModel"
          [(ngModel)]="product.weight"
          name="weight"
          id="weight"
          type="text"
          placeholder="Item weight">
        <mat-error *ngIf="weight.touched && weight.invalid">
          <mat-error *ngIf="weight.errors.required">Item weight is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #length="ngModel"
          [(ngModel)]="product.length"
          name="length"
          id="length"
          type="text"
          placeholder="Item length">
        <mat-error *ngIf="length.touched && length.invalid">
          <mat-error *ngIf="length.errors.required">Item length is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #width="ngModel"
          [(ngModel)]="product.width"
          name="width"
          id="width"
          type="text"
          placeholder="Item width">
        <mat-error *ngIf="width.touched && width.invalid">
          <mat-error *ngIf="width.errors.required">Item width is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #height="ngModel"
          [(ngModel)]="product.height"
          name="height"
          id="height"
          type="text"
          placeholder="Item height">
        <mat-error *ngIf="height.touched && height.invalid">
          <mat-error *ngIf="height.errors.required">Item height is required.</mat-error>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <mat-select
          #dimensional_unit="ngModel"
          [(ngModel)]="product.dimensional_unit"
          name="unit"
          id="imageUrl"
          placeholder="Dimensional unit">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let dimensional_unit of dimensional_units" [value]="unit">
            {{ unit }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="dimensional_unit.touched && dimensional_unit.invalid">
          Dimensional unit is required.
        </mat-error>
      </mat-form-field>
    
      <mat-form-field>
        <input
          matInput
          #color="ngModel"
          [(ngModel)]="product.color"
          name="color"
          id="color"
          type="text"
          placeholder="Item color">
        <mat-error *ngIf="color.touched && color.invalid">
          <mat-error *ngIf="color.errors.required">Item color is required.</mat-error>
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Display Preferences
        </mat-panel-title>
        <mat-panel-description>
          Choose how to display your portfolio
        </mat-panel-description>
      </mat-expansion-panel-header>

    
      <mat-checkbox
        #featured="ngModel"
        [(ngModel)]="product.featured"
        name="featured"
        id="featured"
        placeholder="Featured">Featured on Homepage
      </mat-checkbox>

      <mat-checkbox
        #live="ngModel"
        [(ngModel)]="product.live"
        name="live"
        id="live"
        placeholder="Publish">Publish Live Product
      </mat-checkbox>
    </mat-expansion-panel>


  </mat-accordion>


  <button mat-raised-button color="primary" type="submit">Save</button>
  <button mat-raised-button color="warn" type="button" (click)="delete()">Delete</button>
</form>