
<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <div fxFlex="nogrow">
    <h1>Store Account</h1>
  </div>

  <div fxFlex="nogrow">
    <store-card [store]="myStore"></store-card>
  </div>

  <div fxFlex="nogrow">

    <mat-slide-toggle
      color="accent"
      [(ngModel)]="editStore"
      name="editStore"
      id="editStore">
      Edit Store
    </mat-slide-toggle>
  
    <div *ngIf="editStore">
      <store-form [store]="myStore"></store-form>
    </div>
  </div>

  <div
    fxFlex="nogrow"
    *ngIf="myStore && !myStore.stripeId">

    <p>Connect Stripe Payment Processor if you would like to sell products on DAB Fare, LLC.</p>
    
    <button
      mat-raised-button
      color="primary"
      type="button"
      [routerLink]="['/admin/account/connect']">
      Connect Stripe Account
    </button>

  </div>
</div>