import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';
import { Product } from 'shared/models/product';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable({
  providedIn: 'root'
})

export class PlanService {

  servicePath: string = 'plans';

  storeId: any = '';
  myStore: any = {};

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) {
    
    (this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
      });

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
    }

  // find resources for my store
  findByMyStore(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: this.storeId
        }
    });
  }
  
  findNum(storeId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $limit: 0
        }
      });
  }

  find(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: this.storeId
        }
      });
  }

  findFeatured(storeId) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          featured: true
        }
      });
  }

  get(resourceId) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId, resource: Product) {

    resource['storeId'] = this.storeId;

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {

    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {

    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
