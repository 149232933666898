<mat-card *ngIf="message" [style.background-color]="getColor()">
  <mat-card-title>
    <div>
      <h3>{{ getSentBy() }}</h3>
    </div>

  </mat-card-title>
  <mat-card-content>
    <p>{{ message.text }}</p>
  </mat-card-content>
</mat-card>