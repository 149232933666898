import { Component, OnInit } from '@angular/core';
import { User } from 'shared/models/user';

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})

export class MyAccountComponent implements OnInit {

  user: User = <User>{};

  constructor() { }

  ngOnInit() {
  }

}
