

<mat-card *ngIf="product">

  <card-store-header *ngIf="product.store && (showStore | async)" [store]="product.store"></card-store-header>

  <img
    #img
    mat-card-image
    *ngIf="product.imageUrl"
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [routerLink]="(path | async) ? ['/store', (path | async), 'products', product._id] : ['/products', product._id]"
    [src]="'https://dabfare.com/images/'+product.imageUrl"
    alt="{{ product.title }}">

  <mat-card-content>
    <mat-card-title
      *ngIf="product.title"
      [routerLink]="(path | async) ? ['/store', (path | async), 'products', product._id] : ['/products', product._id]">
      {{ product.title }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="product.subtitle">{{ product.subtitle }}</mat-card-subtitle>

    <p *ngIf="product.category">{{ product.category }}</p>


    <h2 *ngIf="product.price">{{ product.price | currency:'USD':'symbol' }}</h2>

    <h3 *ngIf="product.shipping && product.shipping > 0; else freeShipping">Shipping: {{ product.shipping | currency:'USD':'symbol' }}</h3>
    
    <ng-template #freeShipping>
      <h3>
        <mat-icon>check</mat-icon>  Free Shipping
      </h3>
    </ng-template>
    

    <p *ngIf="product.stock && product.stock < 10; else inStock">
      {{ product.stock }} unit(s) in stock
    </p>
  
    <ng-template #inStock>
      <p>
        <mat-icon>check</mat-icon>  In stock
      </p>
    </ng-template>


  </mat-card-content>

  <mat-card-actions *ngIf="showActions">
    <div
      fxLayout="row"
      fxLayout.xs="row"
      fxLayoutGap="0px"
      fxLayoutGap.xs="0px"
      fxLayoutAlign="center center"
      *ngIf="!cartItem || cartItem.quantity == 0; else updateQuantity">

      <div
        fxFlex=100
        fxFill
        fxLayoutAlign="center center">
        
        <button
          mat-raised-button
          color="accent"
          (click)="addToCart()">
          Add to Cart
        </button>
      </div>
    </div>
    
    <ng-template  #updateQuantity>
      <product-quantity [shopping-cart-item]="cartItem"></product-quantity>
    </ng-template>
  </mat-card-actions>
</mat-card>
