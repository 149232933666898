
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Shopping Cart Summary</h2>
    </mat-card-title>
      <mat-card-subtitle *ngIf="cartItemsCount | async as itemsCount">
        <div *ngIf="itemsCount == 1; else pluralItems"> 
          You have {{ itemsCount }} item in your collective shopping cart,
        </div>
        <ng-template #pluralItems>
          You have {{ itemsCount }} items in your collective shopping cart,
        </ng-template>
      </mat-card-subtitle>

    <mat-card-subtitle *ngIf="storeCarts | async as cart">
      <div *ngIf="cart.length == 1; else pluralStores">
        from {{ storeCarts.length }} unique store.
      </div>
      <ng-template #pluralStores>
        from {{ storeCarts.length }} unique stores.
      </ng-template>
    </mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <h3 matLine>Total: </h3>
        <h2 matLine>{{ ( amount | async ) | currency:'USD':'symbol' }}</h2>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>