import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs/Subscription';
import { Link } from 'shared/models/link';
import { LinkService } from 'shared/services/link.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';




@Component({
  selector: 'admin-links',
  templateUrl: './admin-links.component.html',
  styleUrls: ['./admin-links.component.css']
})

export class AdminLinksComponent implements OnInit, OnDestroy, AfterViewInit {

  links: Link[];

  myStore: any = {};

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = ['icon', 'name', 'link', 'edit'];
  dataSource = new MatTableDataSource<Link>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private service: LinkService,
    private ngRedux: NgRedux<IAppState>) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {
    this.subscription = (await this.service.find(this.myStore._id)).pipe(
      map( (res: Paginated<any>) => res.data ) )
      .subscribe( links => {
        this.dataSource = new MatTableDataSource<Link>(links);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();
  }
}
