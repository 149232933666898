import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Order } from 'shared/models/order';
import { Subscription } from 'rxjs/Subscription';
import { OrderService } from 'shared/services/order.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';
    
    

@Component({
  selector: 'supreme-orders',
  templateUrl: './supreme-orders.component.html',
  styleUrls: ['./supreme-orders.component.css']
})

export class SupremeOrdersComponent implements OnInit, OnDestroy, AfterViewInit{
  orders: any[] = [];

  subscription: Subscription;

  displayedColumns = ['date', 'address', 'amount', 'view'];
  dataSource = new MatTableDataSource<Order>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private service: OrderService) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    await this.findResources();
    
  }

  private async findResources() {
    this.subscription = (await this.service.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( orders => {
        this.orders = orders;
        this.dataSource = new MatTableDataSource<Order>(orders);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
