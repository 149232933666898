import { Injectable } from '@angular/core';
import { Link } from 'shared/models/link';
import { Feathers } from 'shared/services/feathers.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  servicePath = 'links';

  store: any = {};
  myStore: any = {};

  constructor(
    private feathers: Feathers,
    private ngRedux: NgRedux<IAppState>) { 

    (this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;
      });

    (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }

  findByMyStore(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }

  find(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
      });
  }
  
  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId, resource: Link) {

    resource['storeId'] = storeId;
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
