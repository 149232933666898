

    <p>
      The fastest and easiest way to get paid using Stripe is by creating an Express Account. 
      This will allow you to get paid for the products you sell.
      Extra functionality such as subscriptions may be limited.
      Create a Custom account if you are planning to utilize subscription portal (coming soon).
    </p>

    <p>Stripe express accounts are currently limited to residents of the United States.</p>
     
    <mat-list>
      <h2 mat-header>Stripe Payment Requirements: </h2>
      <mat-list-item> 1.  Phone number with SMS </mat-list-item>
      <mat-list-item> 2.  Name and birthday </mat-list-item>
      <mat-list-item> 3.  Debit card or bank account </mat-list-item>
    </mat-list>

    <p>Please note, a $5 minimum monthly fee will apply.</p>

    <a 
      mat-raised-button
      color="primary"
      *ngIf="clientId && myStore && user"
      href="https://connect.stripe.com/express/oauth/authorize?response_type=code&redirect_uri={{ redirectUrl }}&client_id={{ clientId }}&state={{ ( myStore | async)._id }}&stripe_user[email]={{ (user | async).email }}">
      Connect Stripe Express Account
    </a>