import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { AuthService } from 'shared/services/auth.service';
import { ShoppingCartItem } from 'shared/models/shopping-cart-item';

import { select } from '@angular-redux/store';
import { Address } from 'shared/models/address';
import { OrderService } from 'shared/services/order.service';
import { SourceService } from 'shared/services/source.service';
import { Source } from 'shared/models/source';
import { AddressService } from 'shared/services/address.service';



import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})

export class CheckOutComponent implements OnInit {

  addresses$: Observable<any[]>;
  sources$: Observable<any[]>;

  paySource: Source;
  shippingAddress: Address;

  userId: string;
  
  @select() cartItems;
  @select() cartItemsCount;
  @select() amount;
  @select() storeCarts;
  
  
  stores: any[] = [];
  storeCartItems: any[];

  // Checking inventory
  messages: string[] = [];
  item: ShoppingCartItem;
  stock: number;
  quantity: number;

  storeTotalQuantity: number;
  storeTotalPrice: number;


  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    private addressService: AddressService,
    private sourceService: SourceService) {}

  async ngOnInit() {

    let authenticated = await this.authService.authUser();
    this.userId = authenticated._id;

    await this.findResources();
  }

  private async findResources() {
    this.addresses$ = await this.addressService.find().pipe(
      map( ( res: Paginated<any>) => res.data ) )

    this.sources$ = await this.sourceService.find(this.userId).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  async placeOrder(amount, shippingAddress, storeCarts) {

    
    await this.orderService.placeOrder(amount, shippingAddress, storeCarts);
  }

}
