  
<mat-card *ngIf="service">

  <card-store-header *ngIf="service.store && (showStore | async)" [store]="service.store"></card-store-header>

  <img
    #img
    mat-card-image
    *ngIf="service.imageUrl && service.title"
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [routerLink]="(path | async) ? ['/store', (path | async), 'services', service.title] : ['/services', service.title]"
    [src]="'https://dabfare.com/images/'+service.imageUrl"
    alt="{{ service.title }}">

  <mat-card-content>

    <mat-card-title
      *ngIf="service.title"
      [routerLink]="(path | async) ? ['/store', (path | async), 'services', service.title] : ['/services', service.title]">
      {{ service.title }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="service.subtitle">{{ service.subtitle }}</mat-card-subtitle>

    <p *ngIf="service.abstract">{{ service.abstract }}</p>
  </mat-card-content>

</mat-card>
