import { Component, OnInit } from '@angular/core';
import { Plan } from 'shared/models/plan';
import { ActivatedRoute } from '@angular/router';
import { PlanService } from 'shared/services/plan.service';

import 'rxjs/add/operator/take';

@Component({
  selector: 'admin-plan',
  templateUrl: './admin-plan.component.html',
  styleUrls: ['./admin-plan.component.css']
})
export class AdminPlanComponent implements OnInit {
  
  id: string;
  plan: Plan = <Plan>{};

  constructor(
    private route: ActivatedRoute,
    private service: PlanService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => this.plan = res );
  }
}
