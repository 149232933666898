
<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <div
    fxFlex.xl="58"
    fxFlex.lg="68"
    fxFlex.md="78"
    fxFlex.sm="88"
    fxFlex.xs="98">

    <mat-card *ngIf="order">
      <mat-card-header>
        <mat-card-title *ngIf="order">
          Order Success
        </mat-card-title>
        <mat-card-subtitle *ngIf="order._id">
          Order ID: {{ order._id }}
        </mat-card-subtitle>
      
      </mat-card-header>
      
      <mat-card-content *ngIf="order.amount">
        <p>
          Your order has been placed successfully. Thank you!
        </p>
        <p>
          Total: {{ order.amount | currency:'USD':'symbol' }}
        </p>
        <p *ngIf="order.status">
          Status: {{ order.status }}
        </p>
      
      </mat-card-content>
    </mat-card>
  </div>
</div>

