import { Component, Input } from '@angular/core';
import { SubscriptionPlan } from 'shared/models/subscription-plan';

@Component({
  selector: 'subscription-plan-card',
  templateUrl: './subscription-plan-card.component.html',
  styleUrls: ['./subscription-plan-card.component.css']
})

export class SubscriptionPlanCardComponent {
  @Input('plan') plan: SubscriptionPlan;
}
