import { Component, OnInit } from '@angular/core';
import { Product } from 'shared/models/product';
import { ProductService } from 'shared/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { select } from '@angular-redux/store';
import { titleAnimation, slide } from 'app/animations';
import 'rxjs/add/operator/take';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  animations: [ titleAnimation, slide ]
})

export class ProductComponent implements OnInit {

  @select() path;
  id: string;
  product: Product = <Product>{};

  constructor(
    private route: ActivatedRoute,
    private service: ProductService) {}

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        if (res) {
          this.product = res; 
        }
      });
  }

}
