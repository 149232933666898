import { Injectable } from '@angular/core';
import { Service } from 'shared/models/service';
import { Feathers } from 'shared/services/feathers.service';

@Injectable()
export class ServiceService {

  servicePath: string = 'services';

  constructor(
    private feathers: Feathers) {}


  private getQuery(storeId) {

    let query = {};
  
    if(storeId == '') {
      query = {
        query: {
          $sort: { createdAt: -1 }
        }
      }
    }
    else {
      query = {
        query: {
          storeId: storeId,
          $sort: { createdAt: 1 }
        }
      }
    }
    return query;
  }

  // find resources for my store
  findByMyStore(storeId: string) {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId
        }
    });
  }

  findNum(storeId) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          $limit: 0
        }
      });
  }

  find(storeId: string) {

    let query = this.getQuery(storeId);

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  findByTitle(storeId: string, resource: string) {
    
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find({
        query: {
          storeId: storeId,
          title: resource
        }
      });
  }
  
  findFeatured(storeId: string) {

    let query = this.getQuery(storeId);
    query['query']['$limit'] = 5;
    if(storeId != '') query['query']['featured'] = true;

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find(query);
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(storeId: string, resource: Service) {

    resource['storeId'] = storeId;
    
    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }
  
  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }

  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }

  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
