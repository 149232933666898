
<store-nav></store-nav>

<blog-filter></blog-filter>

<button mat-raised-button matTooltip="Back" aria-label="Back arrow icon link" [routerLink]="(path | async) ? ['/store', (path | async)] : ['/']">
  <mat-icon color="accent">arrow_back</mat-icon>
</button>

<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="10px">

  <div @titleAnimation fxFlex="nogrow">
    <h1>Blog</h1>
  </div>
</div>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">  

  <div
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let blog of filteredBlogs">

    <blog-card [blog]="blog"></blog-card>
      
  </div>
</div>
