import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';



import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { StoreRefundService } from 'shared/services/store-refund.service';
import { Subscription } from 'rxjs/Subscription';
import { StoreRefund } from 'shared/models/store-refund';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';
  
    

@Component({
  selector: 'supreme-store-refunds',
  templateUrl: './supreme-store-refunds.component.html',
  styleUrls: ['./supreme-store-refunds.component.css']
})

export class SupremeStoreRefundsComponent implements OnInit, OnDestroy, AfterViewInit {

  subscription: Subscription;

  displayedColumns = ['date', 'user', 'order', 'view'];
  dataSource = new MatTableDataSource<StoreRefund>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: StoreRefundService) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    await this.findResources();
  }

  private async findResources() {
    this.subscription = (await this.service.findAll()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( refunds => {
        this.dataSource = new MatTableDataSource<StoreRefund>(refunds);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
