import { Component } from '@angular/core';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})

export class LoginFormComponent {

  messages: string[] = [];

  constructor(private auth: AuthService) { }

  login(credentials) {

    if (!credentials.username || !credentials.password) {
      this.messages.push('Incomplete credentials!');
      return;
    }
    // request logIn from AuthService
    this.auth.logIn(credentials);
  }
}
