import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BlogCardComponent } from 'shared/components/blog-card/blog-card.component';
import { PortfolioCardComponent } from 'shared/components/portfolio-card/portfolio-card.component';
import { ProductCardComponent } from 'shared/components/product-card/product-card.component';
import { ProductQuantityComponent } from 'shared/components/product-quantity/product-quantity.component';
import { MaterialModule } from 'shared/material/material.module';

import { AuthGuard } from 'shared/services/auth-guard.service';
import { AuthService } from 'shared/services/auth.service';
import { BlogService } from 'shared/services/blog.service';
import { CategoryService } from 'shared/services/category.service';
import { Feathers } from 'shared/services/feathers.service';
import { MessageService } from 'shared/services/message.service';
import { OrderService } from 'shared/services/order.service';
import { PortfolioService } from 'shared/services/portfolio.service';
import { ProductService } from 'shared/services/product.service';
import { ShoppingCartService } from 'shared/services/shopping-cart.service';
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { TodoService } from 'shared/services/todo.service';
import { UserService } from 'shared/services/user.service';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';

import { ServiceCardComponent } from 'shared/components/service-card/service-card.component';
import { ServiceService } from 'shared/services/service.service';
import { StoreService } from 'shared/services/store.service';
import { StoreCardComponent } from 'shared/components/store-card/store-card.component';
import { SubscriptionPlanCardComponent } from 'shared/components/subscription-plan-card/subscription-plan-card.component';
import { StoreOrderService } from 'shared/services/store-order.service';
import { ConfirmStoreUnsubscribeComponent } from 'shared/dialogs/confirm-store-unsubscribe/confirm-store-unsubscribe.component';
import { UserCardComponent } from 'shared/components/user-card/user-card.component';
import { StoreRefundService } from 'shared/services/store-refund.service';

import { SubscriptionProductCardComponent } from 'shared/components/subscription-product-card/subscription-product-card.component';

import { SourceCardComponent } from 'shared/components/source-card/source-card.component';
import { PlanCardComponent } from 'shared/components/plan-card/plan-card.component';
import { AccountService } from 'shared/services/account.service';
import { PageService } from 'shared/services/page.service';
import { LinkService } from 'shared/services/link.service';

import { UploadModule } from 'shared/upload/upload.module';
import { FileUploadComponent } from 'shared/components/file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadService } from 'shared/services/upload.service';
import { UploadCardComponent } from 'shared/components/upload-card/upload-card.component';
import { ThemeCardComponent } from 'shared/components/theme-card/theme-card.component';
import { StoreOrderSummaryComponent } from 'shared/components/store-order-summary/store-order-summary.component';
import { OrderSummaryComponent } from 'shared/components/order-summary/order-summary.component';
import { UserFormComponent } from 'shared/components/user-form/user-form.component';
import { SiteService } from 'shared/services/site.service';
import { StoreFormComponent } from 'shared/components/store-form/store-form.component';
import { AdminService } from 'shared/services/admin.service';
import { CardStoreHeaderComponent } from 'shared/components/card-store-header/card-store-header.component';
import { StoreNavComponent } from 'shared/components/store-nav/store-nav.component';
import { ImageCardComponent } from 'shared/components/image-card/image-card.component';
import { CartItemCardComponent } from './components/cart-item-card/cart-item-card.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { AddressService } from 'shared/services/address.service';
import { MessageCardComponent } from './components/message-card/message-card.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { Angular2ImageGalleryModule } from 'angular2-image-gallery';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    UploadModule,
    FileUploadModule,
    FlexLayoutModule,
    Angular2ImageGalleryModule,
    RouterModule.forChild([])
  ],
  declarations: [
    ProductCardComponent,
    ProductQuantityComponent,
    PortfolioCardComponent,
    BlogCardComponent,
    ConfirmRemoveResourceComponent,
    ConfirmStoreUnsubscribeComponent,
    ServiceCardComponent,
    AddressCardComponent,
    StoreCardComponent,
    SubscriptionPlanCardComponent,
    UserCardComponent,
    SubscriptionProductCardComponent,
    SourceCardComponent,
    PlanCardComponent,
    FileUploadComponent,
    UploadCardComponent,
    ThemeCardComponent,
    OrderSummaryComponent,
    StoreOrderSummaryComponent,
    UserFormComponent,
    StoreFormComponent,
    CardStoreHeaderComponent,
    StoreNavComponent,
    ImageCardComponent,
    CartItemCardComponent,
    MessageCardComponent,
    ImageGalleryComponent    
  ],
  entryComponents: [
    ConfirmRemoveResourceComponent,
    ConfirmStoreUnsubscribeComponent
  ],
  exports: [
    BlogCardComponent,
    PortfolioCardComponent,
    ProductCardComponent,
    ServiceCardComponent,
    AddressCardComponent,
    StoreCardComponent,
    SubscriptionPlanCardComponent,
    SubscriptionProductCardComponent,
    UserCardComponent,
    ProductQuantityComponent,
    ConfirmRemoveResourceComponent,
    ConfirmStoreUnsubscribeComponent,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    UploadModule,
    FlexLayoutModule,
    SourceCardComponent,
    PlanCardComponent,
    FileUploadModule,
    UploadCardComponent,
    ThemeCardComponent,
    OrderSummaryComponent,
    StoreOrderSummaryComponent,
    UserFormComponent,
    StoreFormComponent,
    CardStoreHeaderComponent,
    StoreNavComponent,
    ImageCardComponent,
    MessageCardComponent
  ],
  providers: [
    Title,
    Feathers,
    AuthGuard,
    AuthService,
    BlogService,
    CategoryService,
    UserService,
    PortfolioService,
    ProductService,
    ShoppingCartService,
    ShoppingCartItemService,
    OrderService,
    TodoService,
    MessageService,
    ServiceService,
    StoreService,
    SiteService,
    StoreOrderService,
    PageService,
    StoreRefundService,
    AccountService,
    LinkService,
    UploadService,
    AdminService,
    AddressService
  ]
})

export class SharedModule {}
