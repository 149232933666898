import { Component } from '@angular/core';
import { AuthService } from 'shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent {

  messages: string[] = [];
  
  readPolicy: boolean = false;
  readTerms: boolean = false;

  
  
  constructor(private auth: AuthService) { }

  haveReadPolicy() {
    this.readPolicy = true;
  }

  haveReadTerms() {
    this.readTerms = true;
  }


  async signup(credentials) {

    this.messages = [];

    this.messages.push('Creating new user account...');

    await this.auth.signUp(credentials)
      .then(() => this.messages.push('Successfully created new user!'))
      .catch(err => {
        this.messages.unshift('Failed to create new user!'); 
        console.log('signup error', err);
      });

    this.messages.push('Requesting login for new user...');
    
    await this.auth.logIn(credentials);
  }
}
