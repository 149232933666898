
<upload-button></upload-button>

<div
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="5px"
  fxLayoutGap.xs="2px">

  <div 
    fxFlex.xl="18"
    fxFlex.lg="23"
    fxFlex.md="31"
    fxFlex.sm="48"
    fxFlex.xs="98"
    *ngFor="let upload of uploads">

    <upload-card [upload]="upload"></upload-card>
    
  </div>
</div>

<mat-paginator
  [length]="100"
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>