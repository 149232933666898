import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';
import { Portfolio } from 'shared/models/portfolio';
import { CategoryService } from 'shared/services/category.service';
import { PortfolioService } from 'shared/services/portfolio.service';
import { UploadService } from 'shared/services/upload.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'portfolio-form',
  templateUrl: './portfolio-form.component.html',
  styleUrls: ['./portfolio-form.component.css']
})

export class PortfolioFormComponent implements OnInit, OnDestroy {

  @Input('portfolio') portfolio: Portfolio;
  categories$: Observable<any>;
  uploads$: Observable<any>;

  myStore: any= {};
  myStoreSubscription: Subscription;

  id: string;  

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private service: PortfolioService,
    private uploadService: UploadService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {
    // get categories from category service
    this.categories$ = (await this.categoryService.find(this.myStore._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )

    this.uploads$ = (await this.uploadService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
  }

  pushImage(image) {

    if (!this.portfolio.images) this.portfolio.images = [];
    this.portfolio.images.push(image);
  }

  spliceImage(image) {

    let index: number = this.portfolio.images.indexOf(image);
    this.portfolio.images.splice(index, 1);
  }

  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.service.remove(this.id);
        this.router.navigate(['/admin/portfolio']);
      });
  }

  save(resource) {

    if (this.portfolio.images) resource['images'] = this.portfolio.images;
    if (this.id) this.service.patch(this.id, resource);
    else this.service.create(this.myStore._id, resource);

    this.router.navigate(['/admin/portfolio']);
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
