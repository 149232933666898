import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CategoryService } from 'shared/services/category.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


  
@Component({
  selector: 'admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.css']
})
export class AdminCategoriesComponent implements OnInit, OnDestroy {

  categories$: Observable<any[]>;

  myStore: any = {};
  myStoreSubscription: Subscription;

  constructor(
    private service: CategoryService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {

    // get categories from category service
    this.categories$ = await this.service.find(this.myStore._id).pipe(
      map( (res: Paginated<any>) => res.data ) )
  }

  async create(input) {
    await this.service.create(this.myStore._id, input.value);
    input.value = "";    
  }

  async delete(category) {
    await this.service.remove(category._id);
  }

  ngOnDestroy() {
    this.myStoreSubscription.unsubscribe();
  }
}
