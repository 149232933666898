import 'rxjs/add/operator/take';

import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { StoreService } from 'shared/services/store.service';


@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})

export class AboutComponent implements OnInit, OnDestroy {

  store: any = {};
  showEdit: boolean = false;
  edit: boolean = false;
  editMission: boolean = false;
  editImageUrl: boolean = false;  
  
  
  user: any = {};

  storeSubscription: Subscription;
  userSubscription: Subscription;

  constructor(
    private service: StoreService,
    private ngRedux: NgRedux<IAppState>){}

  async ngOnInit() {

    await this.populateStore();    

    await this.populateUser();
  }

  patch(resource) {

    this.service.patch(this.store._id, resource);
  }

  private async populateStore() {
    this.storeSubscription = (await this.ngRedux.select('store'))
    .subscribe( res => {
      this.store = res;
    });
  }


  private async populateUser() {

    this.userSubscription = (await this.ngRedux.select('store'))
      .subscribe( res => {
        this.user = res;
        this.checkStoreAdmin();
      });
    this.checkStoreAdmin();

  }

  private checkStoreAdmin() {
    if (this.store && this.user && this.user.admin && this.user._id == this.store.userId) this.showEdit = true;
    else this.showEdit = false;
    return;
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
}
