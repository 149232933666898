import { Component, Input } from '@angular/core';
import { Plan } from 'shared/models/plan';
import { ShoppingCartItemService } from 'shared/services/shopping-cart-item.service';
import { select } from '@angular-redux/store';

@Component({
  selector: 'plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.css']
})
export class PlanCardComponent {

  @Input('plan') plan: Plan;
  @Input('show-plan-actions') showPlanActions = true;
  @select() showStore;

  constructor(private cartItemService: ShoppingCartItemService) {}

  addToCart() {

    //this.cartItemService.addToCart(this.plan);
  }

}
