import { Injectable } from '@angular/core';
import { Feathers } from 'shared/services/feathers.service';

@Injectable()
export class StripeAccountEventService {
  servicePath: string = 'stripe-account-events';

  constructor(private feathers: Feathers) {}

  find() {
    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  get(resourceId: string) {
    return this.feathers 
      .service(this.servicePath)
      .watch()
      .get(resourceId);
  }

  create(resource: any) {
    
    return this.feathers
      .service(this.servicePath)
      .create({});
  }

  patch(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .patch(resourceId, resource);
  }
       
  update(resourceId, resource) {
    
    return this.feathers
      .service(this.servicePath)
      .update(resourceId, resource);
  }
    
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
