import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'shared/models/store';
import { select } from '@angular-redux/store';

@Component({
  selector: 'store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.css']
})

export class StoreCardComponent implements OnInit {
  @Input('store') store: Store;
  @select() path;
  @select() site;

  ngOnInit() {
    let img = document.getElementById("mat-card-image");
    img.hidden = true;

    img.onload = function() {

      img.hidden = false;

    };
  }
}
