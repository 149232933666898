import { Component, OnInit } from '@angular/core';
import { Link } from 'shared/models/link';
import { ActivatedRoute } from '@angular/router';
import { LinkService } from 'shared/services/link.service';

import 'rxjs/add/operator/take';

@Component({
  selector: 'admin-link',
  templateUrl: './admin-link.component.html',
  styleUrls: ['./admin-link.component.css']
})
export class AdminLinkComponent implements OnInit {

  link: Link = <Link>{};
  id: string;

  constructor(
    private route: ActivatedRoute,
    private service: LinkService) {}

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        this.link = res;
      });
  }
}
