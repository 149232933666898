import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { fadeImg } from 'app/animations';

@Component({
  selector: 'image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css'],
  animations: [ fadeImg ]
})
export class ImageCardComponent {

  @Input('image') image: any;

  constructor(){}

}
