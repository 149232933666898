import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { User } from 'shared/models/user';
import { Subscription } from 'rxjs/Subscription';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UserService } from 'shared/services/user.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'supreme-users',
  templateUrl: './supreme-users.component.html',
  styleUrls: ['./supreme-users.component.css']
})

export class SupremeUsersComponent implements OnInit, OnDestroy, AfterViewInit {

  users: User[] = [];
  subscription: Subscription;
  
  displayedColumns = ['username', 'email', 'edit'];
  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: UserService) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  async ngOnInit() {

    await this.findResources();
  }


  private async findResources() {
    this.subscription = (await this.service.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( users => {
        this.dataSource = new MatTableDataSource<User>(users);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
