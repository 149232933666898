

<p>test mode. webapp is under development.</p>
<p> Pay with credit card</p>


<p>Total amount of {{ amount | currency:'USD':'symbol' }}</p>
<p>from  {{ storeCarts.length }}store(s)</p>


<h2>Shipping to: </h2>
<address-card [address]="shippingAddress"></address-card>


<button mat-raised-button color="primary" type="button" (click)="onClickBuy()">Card Payment Portal</button>