import { NgModule } from '@angular/core';
import { SharedModule } from 'shared/shared.module';
import { RouterModule } from '@angular/router';
import { BlogComponent } from 'store/blog/components/blog/blog.component';
import { BlogPostComponent } from 'store/blog/components/blog-post/blog-post.component';
import { BlogFilterComponent } from './components/blog-filter/blog-filter.component';


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([

      // Blog pages
      { path: 'store/:store/blog/:id', component: BlogPostComponent, data: { animation: 'BlogPage' } },
      { path: 'blog/:id', component: BlogPostComponent, data: { animation: 'BlogPage' } },

      { path: 'store/:store/blog', component: BlogComponent, data: { animation: 'BlogsPage' } },
      { path: 'blog', component: BlogComponent, data: { animation: 'BlogsPage' } }
    ])
  ],
  declarations: [
    BlogComponent,
    BlogPostComponent,
    BlogFilterComponent
  ]
})
export class BlogModule { }
