<mat-card *ngIf="product" class="subscription-product-card">
  
  <mat-card-title>Subscription Product</mat-card-title>

  <mat-card-content>
    
    <p>ID: {{ product._id }}</p>
    
    <p>Name: {{ product.name }}</p>

    <p>Currency: {{ product.currency }}</p>

    <p>Amount: {{ product.amount | currency:'USD':symbol }}</p>

    <p>Interval: {{ product.interval }}</p>

    <p>Trial Period Days: {{ product.trialPeriodDays }}</p>

    <p>Statement desc: {{ product.statementDesc }}</p>

  </mat-card-content>
</mat-card>