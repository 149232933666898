import 'rxjs/add/operator/take';

import { NgRedux, select } from '@angular-redux/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAppState } from 'app/store';
import { Subscription } from 'rxjs/Subscription';
import { Link } from 'shared/models/link';
import { LinkService } from 'shared/services/link.service';


import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'ng-footer',
  templateUrl: './ng-footer.component.html',
  styleUrls: ['./ng-footer.component.scss']
})

export class NgFooterComponent implements OnInit, OnDestroy {

  store: any = {};
  links: Link[];

  subscription: Subscription;
  storeSubscription: Subscription;
  
  constructor(
    private service: LinkService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (this.ngRedux.select('store'))
      .subscribe( res => {
        this.store = res;
        this.findResources();
      });
  }
  
  private async findResources() {
    this.subscription = (await this.service.find(this.store._id)).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.links = res;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.storeSubscription.unsubscribe();
  }
}