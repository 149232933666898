import { Component, Input } from '@angular/core';
import { Message } from 'shared/models/message';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.css']
})
export class MessageCardComponent {

  @Input('message') message: Message;
  @Input('readerId') readerId: string;

  getSentBy() {
    if(this.message && this.message.fromId === this.message.userId) {
      return this.message.user.username;
    }
    else return this.message.store.title;
  }

  getColor() {
    if(this.message && this.message.fromId === this.readerId) {
      return '#9FA8DA';
    }
    else return '#E0E0E0';
  }
}
