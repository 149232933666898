import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlogService } from 'shared/services/blog.service';
import { Blog } from 'shared/models/blog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { select, NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { slide, titleAnimation } from 'app/animations';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  animations: [ titleAnimation, slide ]
})

export class BlogComponent implements OnInit, OnDestroy {

  @select() path;

  storeId: any = '';

  blogs: Blog[] = [];
  filteredBlogs: Blog[] = [];

  category: string;

  subscription: Subscription;
  storeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private service: BlogService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    await this.populateStore();
  }

  private async populateStore() {

    this.storeSubscription = (await this.ngRedux.select('storeId'))
      .subscribe( res => {
        this.storeId = res;
        this.findResources();
      });
  }

  private async findResources() {

    this.subscription = (await this.service).find(this.storeId).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe(items => {
        this.blogs = items;

      this.route.queryParamMap
        .subscribe(params => {
          this.category = params.get('category');
          this.applyFilter();
        });
      });
  }

  private applyFilter() {
    this.filteredBlogs = (this.category) ? 
    this.blogs.filter(b => b.category === this.category) :
    this.blogs;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.storeSubscription.unsubscribe();
  }
}
