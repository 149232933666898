
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared/shared.module';


import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import { AdminPortfolioComponent } from './components/admin-portfolio/admin-portfolio.component';
import { AdminPortfoliosComponent } from './components/admin-portfolios/admin-portfolios.component';
import { AdminProductComponent } from './components/admin-product/admin-product.component';
import { AdminProductsComponent } from './components/admin-products/admin-products.component';
import { PortfolioFormComponent } from './components/portfolio-form/portfolio-form.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { AdminBlogsComponent } from './components/admin-blogs/admin-blogs.component';
import { AdminBlogComponent } from './components/admin-blog/admin-blog.component';
import { BlogFormComponent } from './components/blog-form/blog-form.component';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { AdminServiceComponent } from './components/admin-service/admin-service.component';
import { AdminServicesComponent } from './components/admin-services/admin-services.component';
import { AdminStoreComponent } from './components/admin-store/admin-store.component';
import { AdminAccountComponent } from './components/admin-account/admin-account.component';
import { AdminOrderComponent } from './components/admin-order/admin-order.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminMessagesComponent } from './components/admin-messages/admin-messages.component';
import { AdminMessageComponent } from './components/admin-message/admin-message.component';


import { AdminRefundComponent } from './components/admin-refund/admin-refund.component';
import { AdminRefundsComponent } from './components/admin-refunds/admin-refunds.component';


import { PlanFormComponent } from './components/plan-form/plan-form.component';
import { AdminPlanComponent } from './components/admin-plan/admin-plan.component';
import { AdminPlansComponent } from './components/admin-plans/admin-plans.component';
import { AdminSubscriptionsComponent } from './components/admin-subscriptions/admin-subscriptions.component';
import { AdminSubscriptionComponent } from './components/admin-subscription/admin-subscription.component';
import { AdminLinkComponent } from './components/admin-link/admin-link.component';
import { AdminLinksComponent } from './components/admin-links/admin-links.component';
import { LinkFormComponent } from './components/link-form/link-form.component';
import { AdminPagesComponent } from './components/admin-pages/admin-pages.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { AdminUploadsComponent } from './components/admin-uploads/admin-uploads.component';
import { AdminThemeComponent } from './components/admin-theme/admin-theme.component';
import { ThemeFormComponent } from './components/theme-form/theme-form.component';
import { AdminCategoriesComponent } from './components/admin-categories/admin-categories.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forChild([
      // admin section
      // manage categories
      { 
        path: 'admin/categories',
        component: AdminCategoriesComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Manage Dashboard
      {
        path: 'admin/dashboard',
        component: AdminDashboardComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // manage orders

      {
        path: 'admin/orders/:id',
        component: AdminOrderComponent,
        canActivate: [ AdminAuthGuard ]
      },

      {
        path: 'admin/orders',
        component: AdminOrdersComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Manage Refunds

      {
        path: 'admin/refunds/:id',
        component: AdminRefundComponent,
        canActivate: [ AdminAuthGuard ]
      },

      {
        path: 'admin/refunds',
        component: AdminRefundsComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Manage Messages
      {
        path: 'admin/messages/:id',
        component: AdminMessageComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/messages',
        component: AdminMessagesComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // manage blog
      { 
        path: 'admin/blog/new',
        component: AdminBlogComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/blog/:id',
        component: AdminBlogComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/blog',
        component: AdminBlogsComponent,
        canActivate: [ AdminAuthGuard ]
      },
      // manage portfolios
      { 
        path: 'admin/portfolio/new',
        component: AdminPortfolioComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/portfolio/:id',
        component: AdminPortfolioComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/portfolio',
        component: AdminPortfoliosComponent,
        canActivate: [ AdminAuthGuard ]
      },
      // manage products

      { 
        path: 'admin/products/new',
        component: AdminProductComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/products/:id',
        component: AdminProductComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/products',
        component: AdminProductsComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // manage plans

      { 
        path: 'admin/plans/new',
        component: AdminPlanComponent,
        canActivate: [ AdminAuthGuard ]
      },
      { 
        path: 'admin/plans/:id',
        component: AdminPlanComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/plans',
        component: AdminPlansComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // manage services
      { 
        path: 'admin/services/new',
        component: AdminServiceComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/services/:id',
        component: AdminServiceComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/services',
        component: AdminServicesComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Admin Links
      {
        path: 'admin/links/new',
        component: AdminLinkComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/links/:id',
        component: AdminLinkComponent,
        canActivate: [ AdminAuthGuard ]
      },
      {
        path: 'admin/links',
        component: AdminLinksComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Admin Uploads
      {
        path: 'admin/uploads',
        component: AdminUploadsComponent,
        canActivate: [ AdminAuthGuard ]
      },

      // Admin account
      {
        path: 'admin/account',
        component: AdminAccountComponent,
        canActivate: [ AdminAuthGuard ]
      },


    ])
  ],

  declarations: [
    AdminProductsComponent,
    AdminOrderComponent,
    AdminOrdersComponent,
    BlogFormComponent,
    PortfolioFormComponent,    
    ProductFormComponent,
    ServiceFormComponent,    
    AdminProductComponent,
    AdminPortfolioComponent,
    AdminPortfoliosComponent,
    AdminBlogsComponent,
    AdminBlogComponent,
    AdminServiceComponent,
    AdminServicesComponent,
    AdminStoreComponent,
    AdminAccountComponent,
    AdminDashboardComponent,
    AdminMessagesComponent,
    AdminMessageComponent,
    AdminRefundComponent,
    AdminRefundsComponent,
    PlanFormComponent,
    AdminPlanComponent,
    AdminPlansComponent,
    AdminSubscriptionsComponent,
    AdminSubscriptionComponent,
    AdminLinkComponent,
    AdminLinksComponent,
    LinkFormComponent,
    AdminPagesComponent,
    AdminPageComponent,
    AdminUploadsComponent,
    AdminThemeComponent,
    ThemeFormComponent,
    AdminCategoriesComponent
  ],

  providers: [
    AdminAuthGuard
  ]
})

export class AdminModule { }
