import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Portfolio } from 'shared/models/portfolio';
import { select } from '@angular-redux/store';
import { fadeImg } from 'app/animations';

@Component({
  selector: 'portfolio-card',
  templateUrl: './portfolio-card.component.html',
  styleUrls: ['./portfolio-card.component.css'],
  animations: [ fadeImg ]
})
export class PortfolioCardComponent implements AfterViewInit {
  @Input('portfolio') portfolio: Portfolio;
  @select() path;
  @select() showStore;

  @ViewChild('img') img: any;

  ngAfterViewInit() {

    if(this.img) {
      
      this.img.height = '600px';
      this.img.width = '600px';
      this.img.hidden = true;

      this.img.onload = function() {

        this.img.height='100%'
        this.img.width='100%'
        this.img.hidden = false;
      };
    }

  }
}
