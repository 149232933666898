import { Component, Input } from '@angular/core';
import { Store } from 'shared/models/store';
import { User } from 'shared/models/user';

@Component({
  selector: 'admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent {

  @Input('user') user: User;
  @Input('myStore') myStore: Store;

}
