import 'rxjs/add/operator/take';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmRemoveResourceComponent } from 'shared/dialogs/confirm-remove-resource/confirm-remove-resource.component';

import { Link } from 'shared/models/link';
import { LinkService } from 'shared/services/link.service';
import { Website } from 'shared/models/website';
import { Subscription } from 'rxjs/Subscription';
import { IAppState } from 'app/store';
import { NgRedux } from '@angular-redux/store';

@Component({
  selector: 'link-form',
  templateUrl: './link-form.component.html',
  styleUrls: ['./link-form.component.css']
})

export class LinkFormComponent implements OnInit {

  @Input('link') link: Link;
  myStore: any = {};

  myStoreSubscription: Subscription;

  id: string;

  types: any[] = [
    { value: 'social', name: 'Social Media' },
  ]

  sites: Website[] = [
    { name: 'facebook', baseUrl: 'https://www.facebook.com/' },
    { name: 'github', baseUrl: 'https://github.com/' },
    { name: 'instagram', baseUrl: 'https://www.instagram.com/' },
    { name: 'patreon', baseUrl: 'https://www.patreon.com/' },
    { name: 'pinterest', baseUrl: 'https://www.pinterest.com/' },
    { name: 'twitter', baseUrl: 'https://www.twitter.com/' },
    { name: 'youtube', baseUrl: 'https://www.youtube.com/channel/' }
  ];


  constructor(
    private dialog: MatDialog,
    private router: Router,    
    private route: ActivatedRoute,
    private service: LinkService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    this.id = await this.route.snapshot.paramMap.get('id');

    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
      });
  }
  delete() {
    this.dialog.open(ConfirmRemoveResourceComponent, {
      data: this.id
    })
      .afterClosed()
      .subscribe(result => {
        if (result !== true) return;
        this.service.remove(this.id);
        this.router.navigate(['/admin/links']);
      });
  }

  save(link) {
    if (this.id) this.service.patch(this.id, link);
    else this.service.create(this.myStore._id, link);

    this.router.navigate(['/admin/links']);
  }
}
