import { Component, Input } from '@angular/core';
import { StoreShoppingCart } from 'shared/models/store-shopping-cart';

@Component({
  selector: 'store-order-summary',
  templateUrl: './store-order-summary.component.html',
  styleUrls: ['./store-order-summary.component.css']
})
export class StoreOrderSummaryComponent {
  @Input('store-shopping-cart') storeCart: StoreShoppingCart;


}
