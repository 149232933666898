import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs/Subscription';
import { StoreOrder } from 'shared/models/store-order';
import { StoreOrderService } from 'shared/services/store-order.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.css']
})

export class AdminOrdersComponent implements OnInit, OnDestroy, AfterViewInit {

  orders: any[] = [];

  myStore: any = {};

  subscription: Subscription;
  myStoreSubscription: Subscription;

  displayedColumns = ['date', 'status', 'address', 'totalQuantity', 'totalPrice', 'transferAmount', 'view'];
  dataSource = new MatTableDataSource<StoreOrder>();
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private service: StoreOrderService,
    private ngRedux: NgRedux<IAppState>) {}

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    await this.populateMyStore();
  }

  private async populateMyStore() {

    this.myStoreSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        this.myStore = res;
        this.findResources();
      });
  }

  private async findResources() {
    this.subscription = (await this.service.find(this.myStore._id)).pipe(
    map( (res: Paginated<any>) => res.data ) )
    .subscribe( orders => {
      this.orders = orders;
      this.dataSource = new MatTableDataSource<StoreOrder>(orders);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.myStoreSubscription.unsubscribe();

  }
}
