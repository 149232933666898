
<mat-card *ngIf="plan">

  <card-store-header *ngIf="plan.store && (showStore | async)" [store]="plan.store"></card-store-header>

  <mat-card-title>{{ plan.name }}</mat-card-title>
  <mat-card-subtitle>{{ plan.product.title }}</mat-card-subtitle>

  <mat-card-content>

    <p>Currency: {{ plan.currency }}</p>
    <p>Amount: {{ plan.amount | currency:'USD':'symbol' }}</p>
    <p>Interval: {{ plan.interval }}</p>

    <p>Trial Period Days: {{ plan.trial_period_days }}</p>

  </mat-card-content>

  <mat-card-actions *ngIf="showActions">
    <div
      fxLayout="row"
      fxLayout.xs="row"
      fxLayoutGap="0px"
      fxLayoutGap.xs="0px"
      fxLayoutAlign="center center"
      *ngIf="!plan || plan.quantity == 0; else updateQuantity">

      <div
        fxFlex=100
        fxFill
        fxLayoutAlign="center center">
        
        <button
          mat-raised-button
          color="accent"
          (click)="addToCart()">
          Add to Cart
        </button>
      </div>
    </div>
    
    <ng-template  #updateQuantity>
      <product-quantity [shopping-cart-item]="plan"></product-quantity>
    </ng-template>
    
  </mat-card-actions>
</mat-card>