import 'rxjs/add/operator/take';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Blog } from 'shared/models/blog';
import { BlogService } from 'shared/services/blog.service';
import { titleAnimation, slide } from 'app/animations';

@Component({
  selector: 'admin-blog',
  templateUrl: './admin-blog.component.html',
  styleUrls: ['./admin-blog.component.css'],
  animations: [ titleAnimation, slide ]
})

export class AdminBlogComponent implements OnInit {
  blog: Blog = <Blog>{};
  id: string;

  constructor(
    private route: ActivatedRoute,
    private service: BlogService) {}

  async ngOnInit(){
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id) return;

    (await this.service.get(this.id))
      .take(1)
      .subscribe( res => {
        this.blog = res;
      });
  }
}
