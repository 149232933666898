
<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- View Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Date Placed </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.createdAt }}
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
        <mat-cell matSort *matCellDef="let row"> {{ row.status }} </mat-cell>
    </ng-container>
   
    <!-- Shipping Column -->
    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Shipping Address </mat-header-cell>
      <mat-cell matSort *matCellDef="let row">
        <address-card [address]="row.address"></address-card>
      </mat-cell>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="totalQuantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Total Quantity </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.cart.totalQuantity }} </mat-cell>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="totalPrice">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.cart.totalPrice | currency:'USD':symbol }} </mat-cell>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="transferAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Transfer Amount </mat-header-cell>
      <mat-cell matSort *matCellDef="let row"> {{ row.amount | currency:'USD':symbol }} </mat-cell>
    </ng-container>

    <!-- View Column -->
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View Order </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a [routerLink]="storePath ? ['/', storePath, 'admin', 'orders', row._id] : ['/admin/orders', row._id]">View</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let order; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>