import { Component, OnInit } from '@angular/core';
import { User } from 'shared/models/user';
import { Store } from 'shared/models/store';
import { Subscription } from 'rxjs/Subscription';
import { OrderService } from 'shared/services/order.service';
import { StoreService } from 'shared/services/store.service';
import { UserService } from 'shared/services/user.service';
import { Order } from 'shared/models/order';
import { StoreOrder } from 'shared/models/store-order';
import { StripeConnectEventService } from 'supreme/services/stripe-connect-event.service';
import { StripeAccountEventService } from 'supreme/services/stripe-account-event.service';

import { Paginated } from '@feathersjs/feathers';
import { map } from 'rxjs/operators';


@Component({
  selector: 'supreme-dasboard',
  templateUrl: './supreme-dasboard.component.html',
  styleUrls: ['./supreme-dasboard.component.css']
})

export class SupremeDasboardComponent implements OnInit {

  orders: Order[] = [];
  storeOrders: StoreOrder[] = [];
  stores: Store[] = [];
  users: User[] = [];

  stripeAccountEvents: any[] = [];
  stripeConnectEvents: any[] = [];

  orderSubscription: Subscription;
  storeOrderSubscription: Subscription;
  storeSubscription: Subscription;
  userSubscription: Subscription;

  stripeAccountEventSubscription: Subscription;
  stripeConnectEventSubscription: Subscription;
  
  constructor(
    private orderService: OrderService,
    private storeService: StoreService,
    private userService: UserService,
    private stripeAccountEventService: StripeAccountEventService,
    private stripeConnectEventService: StripeConnectEventService) { }

  async ngOnInit() {

    await this.populateResources();
  }

  async populateResources() {


    this.stripeAccountEventSubscription = (await this.stripeAccountEventService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.stripeAccountEvents = res;
      });

    this.stripeConnectEventSubscription = (await this.stripeConnectEventService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.stripeConnectEvents = res;
      });    

    this.orderSubscription = (await this.orderService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.orders = res;
      });

    this.storeOrderSubscription = (await this.orderService.find())
      .map( res => res.data )
      .subscribe( res => {
        this.storeOrders = res;
      });
    
    this.storeSubscription = (await this.storeService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.stores = res;
      });
    
    this.userSubscription = (await this.userService.find()).pipe(
      map( ( res: Paginated<any>) => res.data ) )
      .subscribe( res => {
        this.users = res;
      });

  }

  ngOnDestroy() {
    this.stripeAccountEventSubscription.unsubscribe();
    this.stripeConnectEventSubscription.unsubscribe();

    this.orderSubscription.unsubscribe();
    this.storeOrderSubscription.unsubscribe();    
    this.storeSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

}
