import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from 'app/store';
import { Observable } from 'rxjs/Observable';
import { User } from 'shared/models/user';
import { AuthService } from 'shared/services/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { MessageService } from 'shared/services/message.service';

@Component({
  selector: 'admin-message',
  templateUrl: './admin-message.component.html',
  styleUrls: ['./admin-message.component.css']
})

export class AdminMessageComponent implements OnInit {

  messages$: Observable<any>;
  user: User;
  userId: string;

  myStore: any = {}
  storeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: MessageService,
    private ngRedux: NgRedux<IAppState>) {}

  async ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    if (!this.userId) return;

    this.user = await this.authService.authUser();

    await this.populateMyStore();
  }

  private async populateMyStore() {
    this.storeSubscription = (this.ngRedux.select('myStore'))
      .subscribe( res => {
        if(res){
          this.myStore = res;
          console.log('Adming Message myStore', this.myStore)
          this.findResources();
        }
      });
  }

  findResources() {
    this.messages$ = this.service.findByStoreThread(this.myStore._id, this.userId)
      .map( res => res.data);
  }

  send(text) {

    let message = {
      storeId: this.myStore._id,
      userId: this.userId,
      toId: this.userId,
      fromId: this.myStore._id,
      text: text
    }
    this.service.create(message);
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
