<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
  *ngIf="storeOrder && storeOrder.store">

  <div fxFlex="nogrow">
    <store-order-summary [store-shopping-cart]="storeOrder.cart"></store-order-summary>
  </div>

  <div fxFlex="nogrow">

    <p>Status: {{ storeOrder.status }}</p>

    <p *ngIf="storeOrder.status == 'Shipped'">
      Tracking Number: {{ storeOrder.tracking }}
    </p>

    <mat-slide-toggle
      [(ngModel)]="showStoreItems"
      name="showStoreItems"
      id="showStoreItems">
        Show items purchased from {{ storeOrder.store.title }}
    </mat-slide-toggle>

    <mat-slide-toggle
      [(ngModel)]="selectedRefunds"
      name="selectedRefunds"
      id="selectedRefunds">
        Show items selectable for refund
    </mat-slide-toggle>
  </div>

  <div
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="5px"
    fxLayoutGap.xs="2px"
    *ngIf="showStoreItems || selectedRefunds">

    <div
      fxFlex.xl="18"
      fxFlex.lg="23"
      fxFlex.md="31"
      fxFlex.sm="48"
      fxFlex.xs="98"
      *ngFor="let item of storeOrder.cart.items">

      <mat-checkbox *ngIf="selectedRefunds; else orderItem" #refundItems [value]="item" (click)="changeSelectedItems(refundItems)">
        <product-card [product]="item.product" [show-actions]="false"></product-card>
      </mat-checkbox>

      <ng-template #orderItem>
        <product-card [product]="item.product" [show-actions]="false"></product-card>
      </ng-template>
    </div>

    <div fxFlex="nogrow">
      <button mat-raised-button color="primary" [routerLink]="['/my/orders/refund', storeOrder._id]">Refund Request Form</button>
    </div>
  </div>
</div>