import { Feathers } from './feathers.service';
import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
  servicePath: string = 'users';

  constructor(private feathers: Feathers) { }

  get(userId) {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .get(userId);
  }

  find() {

    return this.feathers
      .service(this.servicePath)
      .watch()
      .find();
  }

  patch(userId: string, resource: any) {

    return this.feathers
      .service(this.servicePath)
      .patch(userId, resource);
  }


  create(resource: string) {

    return this.feathers
      .service(this.servicePath)
      .create(resource);
  }

      
  remove(resourceId: string) {
    
    return this.feathers
      .service(this.servicePath)
      .remove({ 
        _id: resourceId
      });
  }
}
