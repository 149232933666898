
<nav mat-tab-nav-bar>
         
  <a
    mat-tab-link
    [routerLink]="(path | async) ? ['/', (path | async), 'products'] : ['/products']"
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    All Categories
  </a>

  <a
    *ngFor="let c of categories$ | async"
    mat-tab-link
    [queryParams]="{ category: c.text }"
    [routerLink]="(path | async) ? ['/', (path | async), 'products'] : ['/products']" 
    routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    {{ c.text }}
  </a>
</nav>