
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>{{ storeCart.store.title }}</h2>
      <h3>Store Cart Summary</h3>
    </mat-card-title>
    <mat-card-subtitle> 
      <div *ngIf="storeCart.totalQuantity == 1; else pluralItems">
        {{ storeCart.totalQuantity }} item in your store shopping cart.
      </div>
      <ng-template #pluralItems>
        {{ storeCart.totalQuantity }} items in your store shopping cart.
      </ng-template>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let item of storeCart.items">
        <p matLine>{{ item.quantity }} x {{ item.product.title }}</p>
        <p matLine>{{ item.totalPrice | currency:'USD':'symbol' }}</p>
      </mat-list-item>
      <mat-list-item>
        <h3 matLine>Store Total</h3>
        <h2 matLine>{{ storeCart.totalPrice | currency:'USD':'symbol' }}</h2>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>