
<h1>My Payment Sources</h1>

<p>
  <a mat-raised-button color="primary" [routerLink]="['/my/sources/new']">New Payment Source</a>
</p>

<div class="data-table-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="data-table-container mat-elevation-z8">
        
  <mat-table [dataSource]="dataSource" matSort>
    
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Source Type </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.type }} </mat-cell>
    </ng-container>

    <!-- Funding Column -->
    <ng-container matColumnDef="funding">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Funding Type </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.funding }} </mat-cell>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Brand </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.brand }} </mat-cell>
    </ng-container>

    <!-- Last4 Column -->
    <ng-container matColumnDef="last4">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Last 4 Digits</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.last4 }} </mat-cell>
    </ng-container>

    <!-- View Column -->
    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef mat-sort-header> View </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <a [routerLink]="['/my/sources', row._id]">View</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>
