<p>
  <a mat-raised-button color="primary" [routerLink]="['/admin/blog/new']">New Blog Post</a>
  <a mat-raised-button color="primary" [routerLink]="['/admin/categories']">Manage Categories</a>
</p>

<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
    
<div class="data-table-container mat-elevation-z8">

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
      <td mat-cell *matCellDef="let row"> {{ row.title }} </td>
    </ng-container>

    <!-- Abstract Column -->
    <ng-container matColumnDef="abstract">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Entry </th>
      <td mat-cell *matCellDef="let row"> {{ row.entry }} </td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let row"> 
        <a [routerLink]="['/admin/blog', row._id]">Edit</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    #paginator
    [pageSize]="3"
    [pageSizeOptions]="[1, 3, 25, 50, 100]">
  </mat-paginator>
</div>