
<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="40px"
  fxLayoutGap.xs="20px">

  <h1>Shopping Cart</h1>

  <p>Site is under development. All data is for testing and demonstration.</p>
  <p>We plan to open out doors soon. Please stay tuned for updates. Thank you.</p>


  <p>
    You have {{ cartItemsCount | async }} items in your collective shopping cart.
    <button
      mat-button
      *ngIf="cartItemsCount"
      (click)="clearCart()">Clear Shopping Cart</button>

  </p>

   
  <div 
    fxFlex.xl="80"
    fxFlex.lg="85"
    fxFlex.md="90"
    fxFlex.sm="95"
    fxFlex.xs="100"
    *ngFor="let cart of storeCarts | async">

  <table class="table">
    <thead>
      <tr>
        <th>{{ cart.store.title }}</th>
      </tr>
      <tr>
        <th></th>
        <th>Product</th>
        <th class="text-center">Quantity</th>
        <th class="text-right">Price</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of cart.items">
        <td>
          <div [style.backgroundImage]="'url(' + 'https://uploads.dablabllc.com/' + item.product.imageUrl + ')'" class="thumbnail"></div>
        </td>
        <td>
          {{ item.product.title }}
        </td>
        <td>
          <product-quantity [shopping-cart-item]="item"></product-quantity>
        </td>
        <td class="text-right">
          {{ item.totalPrice | currency:'USD':symbol }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
          <th>Store Cart Totals</th>
          <th></th>
          <th>{{ cart.totalQuantity }} items</th>
          <th class="text-right">{{ cart.totalPrice | currency:'USD':symbol }}</th>
      </tr>
    </tfoot>
  </table>
  </div>

  <button
    mat-raised-button
    color="primary"
    *ngIf="cartItemsCount | async"
    routerLink="/check-out">Check Out</button>

</div>
