import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'shared/services/store.service';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/store';
import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from 'app/actions';
import { UploadService } from 'shared/services/upload.service';


@Injectable()
export class AuthService {

  constructor(
    private feathers: Feathers,
    private storeService: StoreService,
    private route: ActivatedRoute,    
    private router: Router,
    private ngRedux: NgRedux<IAppState>,
    private uploadService: UploadService) {}


  public loadResource() {
    this.ngRedux.dispatch({ type: FETCH_USER_REQUEST });

    console.log('auth service loadResourceRequest');

    this.authUser();
  }

  public getToken() {
    let token = this.feathers.client.passport.getJWT();
    if (!token) return;
    return token;
  }

  public async authUser() {

    let token: string = await this.feathers.client.passport.getJWT();
    if (!token) return;

    return this.feathers.authenticate({
      strategy: 'jwt',
      accessToken: token
    })
    .then( response => {
      console.log('Authenticated!', response);
      
      return this.feathers.client.passport.verifyJWT(response.accessToken);
    })
    .then(payload => {
      console.log('JWT Payload', payload);
      return this.feathers.client.service('users').get(payload.sub);
    })
    .then(user => {
      this.feathers.client.set('user', user);
      console.log('User', this.feathers.client.get('user'));

      this.ngRedux.dispatch({ type: FETCH_USER_SUCCESS,
        user: user
      });

      this.storeService.loadMyResource(user._id);

      this.uploadService.loadMyResources(user._id);

      return this.feathers.client.get('user');
    })
    .catch(function(error) {
      console.error('Error authenticating!', error);
    });
  }


  public async authenticateJwt() {

    let token: string = await this.feathers.client.passport.getJWT();
    if (!token) return false;

    return this.feathers.authenticate({
      strategy: 'jwt',
      accessToken: token
    })
    .then( response => {
      return true;
    })
    .catch(function(error) {
      return false;
    });
  }


  public signUp(credentials): Promise<any> {

    return this.feathers
      .service('users')
      .create(credentials);
  }

  public logIn(credentials?) {
    
    // Extract logIn credentials
    let username: string = credentials.username;
    let password: string = credentials.password;

    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';    

    // authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      username: username,
      password: password
    })
    .then( response => {
      console.log('Authenticated!', response);
      return this.feathers.client.passport.verifyJWT(response.accessToken);
    })
    .then(payload => {
      console.log('JWT Payload', payload);
      return this.feathers.client.service('users').get(payload.sub);
    })
    .then(user => {
      this.feathers.client.set('user', user);
      console.log('User', this.feathers.client.get('user'));

      this.ngRedux.dispatch({ type: FETCH_USER_SUCCESS,
        user: user
      });

      this.storeService.loadMyResource(user._id);

      this.uploadService.loadMyResources(user._id);

    })
    .then( () => {
      this.router.navigateByUrl(returnUrl);       
    })
    .catch(function(error) {
      console.error('Error authenticating!', error);
    });
  }

  public logOut() {
    this.feathers.logout();

    this.router.navigate(['/']);
    window.location.reload();
  };
}
