
<mat-card *ngIf="blog">

  <card-store-header *ngIf="blog.store && (showStore | async)" [store]="blog.store"></card-store-header>

  <img
    #img
    mat-card-image
    *ngIf="blog.imageUrl"
    (hidden)="!img.load"
    [@fadeImg]="img.load"
    [routerLink]="(path | async) ? ['/store', (path | async), 'blog', blog._id] : ['/blog', blog._id]"
    [src]="'https://dabfare.com/images/'+blog.imageUrl"
    alt="{{ blog.title }}">

  <mat-card-content>

    <mat-card-title
      *ngIf="blog.title"
      [routerLink]="(path | async) ? ['/store', (path | async), 'blog', blog._id] : ['/blog', blog._id]">
      {{ blog.title }}
    </mat-card-title>

    <mat-card-subtitle *ngIf="blog.subtitle">{{ blog.category }}</mat-card-subtitle>
    <p *ngIf="blog.entry">{{ blog.entry }}</p>
    
  </mat-card-content>
  <mat-card-footer>

      <p fxFlex *ngIf="blog.createdAt">
          Posted on {{ blog.createdAt | date:medium:UTC:en-US }}
      </p>
      <p fxFlex *ngIf="blog.store && blog.store.path && blog.store.title">
        by {{ blog.store.title }}

      </p>
  </mat-card-footer>
</mat-card>
